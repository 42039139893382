<template>
	<div>
		<v-row no-gutters>
			<v-col :class="!showChat ? 'd-flex' : 'd-none'" cols="12" sm="4" class="full-height">
				<v-list width="100%" style="height: calc(100vh - 114px); overflow-y: auto" subheader dense>
					<v-list-item-group>
						<template v-if="$store.state.principal.activity && $store.state.principal.activity.public_chat">
							<v-subheader>{{ $t("activity_chat") }}</v-subheader>
							<v-list-item @click.stop="selectedPlayer({ name: 'Public room', id: 'public' })">
								<v-list-item-avatar size="25">
									<v-img v-if="$store.state.principal.activity.image" :src="$store.state.principal.activity.image"></v-img>
									<v-img v-else src="ic_app.png"></v-img>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ $store.state.principal.activity.name ? $store.state.principal.activity.name : "Public Chat" }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon>
									<i id="public" aria-hidden="true" class="v-icon notranslate mdi theme--light primary--text" style="font-size: 16px"></i>
									<v-icon>mdi-chevron-right</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</template>
						<v-subheader>{{ $t("controller_chat") }}</v-subheader>
						<v-list-item @click.stop="selectedPlayer({ name: 'Controller', id: player_id })">
							<v-list-item-avatar color="secondary" size="25">
								<v-icon color="white">mdi-account-child</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{ $t("controller") }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon>
								<i :id="player_id" aria-hidden="true" class="v-icon notranslate mdi theme--light primary--text" style="font-size: 16px"></i>
								<v-icon>mdi-chevron-right</v-icon>
							</v-list-item-icon>
						</v-list-item>
						<template v-if="$store.state.principal.activity && ($store.state.principal.activity.team == 1 || $store.state.principal.activity.team == 2)">
							<v-subheader>{{ $t("team_chat") }}</v-subheader>
							<v-list-item @click.stop="selectedPlayer({ name: $store.state.player.team_name, id: $store.state.player.team_id })">
								<v-list-item-avatar color="secondary" size="25">
									<v-icon color="white">mdi-account-group</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ $store.state.player.team_name }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon>
									<i :id="this.$store.state.player.team_id" aria-hidden="true" class="v-icon notranslate mdi theme--light primary--text" style="font-size: 16px"></i>
									<v-icon>mdi-chevron-right</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</template>
						<template v-if="$store.state.principal.activity && $store.state.principal.activity.chat_one_to_one">
							<v-subheader>{{ $t("one_to_one_team_chat") }}</v-subheader>
							<v-list-item v-for="(item, i) in listPlayer" :key="i" @click.stop="selectedPlayer(item)" class="d-flex align-center">
								<v-list-item-avatar color="secondary" size="25">
									<v-img v-if="item.avatar" :src="item.avatar"></v-img>
									<v-icon v-else color="white">mdi-account</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title v-text="item.name"></v-list-item-title>
								</v-list-item-content>
								<v-list-item-icon>
									<i :id="item.id" aria-hidden="true" class="v-icon notranslate mdi theme--light primary--text" style="font-size: 16px"></i>
									<v-icon>mdi-chevron-right</v-icon>
								</v-list-item-icon>
							</v-list-item>
						</template>
					</v-list-item-group>
				</v-list>
				<v-divider vertical></v-divider>
			</v-col>
			<v-col cols="12" sm="8" class="full-height">
				<div id="chatRoom" style="border-radius: 0px !important" :style="conversationId == null ? 'display: none;' : ''">
					<div style="border-radius: 0px !important; margin: 0px; border-color: #222d32">
						<div class="panel-heading text-bold d-flex align-center px-2" style="background-color: slategray; border-radius: 0px !important; color: whitesmoke; height: 50px">
							<v-btn v-if="$vuetify.breakpoint.xsOnly" @click="(showChat = !showChat), (conversationId = null)" class="mr-2" icon dark><v-icon>mdi-arrow-left</v-icon></v-btn>
							<h3 style="display: inline" id="playerName">{{ conversationName }}</h3>
							<v-spacer></v-spacer>
							<v-menu offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon dark v-bind="attrs" v-on="on"><v-icon>mdi-account-group</v-icon></v-btn>
								</template>
								<v-list dense>
									<v-list-item v-for="(item, index) in teamMembers" :key="index">
										<v-list-item-avatar size="25">
											<v-img v-if="item.avatar" :src="item.avatar"></v-img>
											<v-icon v-else color="white">mdi-account</v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-text="item.name + (item.is_captain ? ' (Captain)' : '')"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
						<div id="listPlayer" style="display: none"></div>
						<div class="panel-body body-custom px-2" id="chat_body">
							<div id="temp_row"></div>
						</div>
						<div class="panel-footer px-2">
							<v-form ref="chatForm">
								<input type="hidden" id="playerId" />
								<v-textarea id="txtContent" :label="$t('enter_message')" rows="2" outlined no-resize hide-details>
									<template slot="append-outer">
										<v-btn height="62" dark color="primarydark" id="btnSend" class="mt-n4" @click="sendMessage()">{{ $t("send") }}</v-btn>
									</template>
								</v-textarea>
							</v-form>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-dialog v-model="isEmptyMsg" v-if="isEmptyMsg" max-width="500">
			<v-card>
				<v-card-title></v-card-title>
				<v-card-text class="text-center">
					<h4>{{ errorMsg }}</h4>
				</v-card-text>
				<v-card-actions class="d-flex justify-center">
					<v-btn color="primary" @click="isEmptyMsg = false" min-width="100">{{ $t("close") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import firebase from "firebase";
import $ from "jquery";
import md5 from "md5";

export default {
	data() {
		return {
			//try convert php to vue
			chatRoom: true,
			listPlayer: [],
			isEmptyMsg: false,
			errorMsg: "",
			content: "",
			curConversationId: 0,
			list_user: {},
			lastConversationID: null,
			total_unread_message: 0,
			unread_messages: {},
			curConversationID: 0,
			player_id: this.$store.state.principal.player_id,
			cId: this.$store.state.principal.activityId,
			activityId: this.$store.state.principal.activityId,
			lastConversation: null,
			totalUnreadMessage: 0,
			countLoopChildChanged: 0,
			standardizedhostname: this.$store.state.principal.activity.licensee_id,
			avatar: this.$store.state.player.avatar,
			playerName: this.$store.state.player.name,
			activityName: this.$store.state.principal.activityName,
			conversationId: null,
			conversationName: null,
			dbRoot: firebase.database().ref().child("messaging").child(this.$store.state.principal.activity.licensee_id).child(this.$store.state.principal.activityId),
			showChat: false,
			playerSelected: null,
			teamMembers: []
		};
	},
	watch: {
		"$root.messages": {
			handler() {
				this.addNotificationBadges();
			},
			deep: true
		}
	},
	mounted() {
		// create player
		this.loadPlayers();

		let vm = this;

		// players listener
		let users = this.dbRoot.child("users").orderByChild("name");
		users.on("value", function (snapshot) {
			vm.listPlayer = [];
			snapshot.forEach(function (childSnapshot) {
				if (childSnapshot.val().name != "Controller" && childSnapshot.val().name != "Public" && childSnapshot.key != vm.$store.state.principal.player_id && childSnapshot.val().name != null) {
					let player = childSnapshot.val();
					player.id = childSnapshot.key;
					vm.listPlayer.push(player);
				}
			});
		});

		var dbLobby = firebase.database().ref().child("lobby").child(this.$store.state.principal.activity.licensee_id).child(this.$store.state.principal.activityId);
		var dbTeam = dbLobby.child("team");
		dbTeam.on("value", function (snapshot) {
			snapshot.forEach(function (childSnapshot) {
				if (childSnapshot.val().name == vm.$store.state.player.team_name) {
					vm.teamMembers = [];
					childSnapshot.forEach(function (membersSnapshot) {
						membersSnapshot.forEach(function (memberSnapshot) {
							let tmp = memberSnapshot.val();
							tmp.id = memberSnapshot.key;
							vm.teamMembers.push(tmp);
						});
					});
				}
			});
			vm.teamMembers.sort(function(a, b) {
				return (a === b) ? 0 : b.is_captain ? 1 : -1;
			});
		});

		// add notification badges
		this.$nextTick(() => {
			this.addNotificationBadges();
		});
	},
	beforeRouteLeave(from, to, next) {
		if (this.lastConversation != null) {
			this.lastConversation.off();
		}
		next();
	},
	methods: {
		addNotificationBadges() {
			for (let message of this.$root.messages) {
				if (message.unreadMessages != null) {
					let el = document.getElementById(message.sender_id);
					if (message.key == "public" || message.key == this.player_id || message.key == this.$store.state.player.team_id) {
						el = document.getElementById(message.key);
					}

					if (el != null && message.unreadMessages > 0) {
						if (message.unreadMessages > this.totalUnreadMessage) {
							this.totalUnreadMessage += message.unreadMessages;
						}
						el.classList.add("mdi-numeric-" + (message.unreadMessages != null && message.unreadMessages < 10 ? message.unreadMessages : "9-plus") + "-circle");
					} else if (el != null) {
						for (let i = el.classList.length - 1; i >= 0; i--) {
							const className = el.classList[i];
							if (className.startsWith("mdi-numeric")) {
								let arr = className.split("-");
								this.totalUnreadMessage -= arr[2];
								el.classList.remove(className);
							}
						}
					}
				}
			}
		},
		selectedPlayer(item) {
			// hide chat list user in mobile version
			if (this.$vuetify.breakpoint.xsOnly) {
				this.showChat = true;
			}

			// initiate conversation id
			this.conversationId = null;

			// set selected player
			this.playerSelected = item.id;

			if (item.id != "public" && item.id != this.player_id && item.id != this.$store.state.player.team_id) {
				let vm = this;
				var con = this.dbRoot.child("conversations");
				con.on("value", function (snapshot) {
					snapshot.forEach(function (data) {
						if (data.key != "public" && data.key != vm.$store.state.player.team_id) {
							let concatId = "";
							let count = 0;
							data.child("name").forEach(function (id) {
								concatId = concatId + id.key;
								count++;
							});
							if (count == 2 && vm.conversationId == null && (concatId == vm.player_id + item.id || concatId == item.id + vm.player_id)) {
								vm.conversationId = data.key;
							}
						}
					});
				});

				if (this.conversationId == null) {
					this.conversationId = md5(item.id + this.player_id);
				}
			} else {
				this.conversationId = item.id;
			}

			this.conversationName = item.name;
			this.addConversation(this.conversationName, this.conversationId);
		},
		loadPlayers() {
			// listen for new team added
			let vm = this;
			var users = this.dbRoot.child("users");
			users.on("child_added", function (childsnapshot) {
				if (childsnapshot.key != vm.player_id) {
					vm.dbRoot.child("users").child("controller").child("conversations").child(childsnapshot.key).set(true);
					vm.dbRoot.child("users").child(childsnapshot.key).child("conversations").child(childsnapshot.key).set(true);
				}
				vm.list_user[childsnapshot.key] = childsnapshot.val();

			});
		},
		addConversation(name, key) {
			var conversationId = this.conversationId;
			this.curConversationID = this.conversationId;
			this.loadMessageByConversation(conversationId);
			this.setReadMessage(this);

			// reset unread message to 0
			let vm = this;
			var con = this.dbRoot.child("conversations").child(key).child("unread").child(this.player_id);
			con.on("value", function (snapshot) {
				vm.$root.totalUnreadMessages = Math.max(0, vm.$root.totalUnreadMessages - snapshot.val());
			});
			this.dbRoot.child("conversations").child(key).child("unread").child(this.player_id).set(0);
			$("#chatRoom").show();
			$("#chat_body")[0].scrollTop = $("#chat_body")[0].scrollHeight;
		},
		sendMessage() {
			let vm = this;
			var content = document.getElementById("txtContent").value;
			if (content.trim() == "") {
				this.isEmptyMsg = true;
				this.errorMsg = "Oops... Please enter an message";
				return;
			}

			var time = firebase.database.ServerValue.TIMESTAMP;
			var conversationId = this.conversationId;

			var seenBy = [];
			seenBy[this.cId] = true;
			var message = { content: content, sender_id: this.player_id, sent_at: time };

			// var dbRoot = firebase.database().ref().child("messaging").child(this.standardizedhostname).child(this.activityId);
			var messages = this.dbRoot.child("messages").child(conversationId);

			var res = messages.push(message, function () {
				var unread = vm.dbRoot.child("conversations").child(conversationId).child("unread");

				vm.dbRoot.child("conversations").child(conversationId).child("last_message").set(content);
				vm.dbRoot.child("conversations").child(conversationId).child("last_message_id").set(res.key);
				vm.dbRoot.child("conversations").child(conversationId).child("sent_at").set(time);
				vm.dbRoot.child("conversations").child(conversationId).child("sender_id").set(vm.player_id);

				unread.once("value").then(function (snapshot) {
					snapshot.forEach(function (messagesSnapshot) {
						if (messagesSnapshot.key != vm.player_id)
							vm.dbRoot
								.child("conversations")
								.child(conversationId)
								.child("unread")
								.child(messagesSnapshot.key)
								.set((messagesSnapshot.val() == null ? 0 : messagesSnapshot.val()) + 1);
					});
				});
			});

			$(function () {
				var wtf = $("#chat_body");
				var height = wtf[0].scrollHeight;
				wtf.scrollTop(height);
			});

			document.getElementById("txtContent").value = "";
			this.$refs.chatForm.reset();
			$(function () {
				var wtf = $("#chat_body");
				var height = wtf[0].scrollHeight;
				wtf.scrollTop(height);
			});

			this.setReadMessage(document.getElementById(this.curConversationID));
		},
		loadMessageByConversation(conversationId) {
			let vm = this;
			if (vm.lastConversationID != conversationId) {
				vm.lastConversationID = conversationId;
				$("#temp_row").empty();

				var messages = this.dbRoot.child("messages").child(conversationId);
				messages.on("child_added", function (message) {
					var msg = message.val();
					vm.addMessage(msg.sender_id, msg.content, msg.sent_at);
				});
				if (this.lastConversation === null) {
					this.lastConversation = messages;
				} else {
					this.lastConversation.off();
					this.lastConversation = messages;
				}
			}
		},
		setReadMessage() {
			var dbMessages = firebase.database().ref().child("messages");

			var messages = dbMessages.child(this.standardizedhostname).child(this.cId).child(this.conversationId);
			messages.once("value").then(function (snapshot) {
				snapshot.forEach(function (messagesSnapshot) {
					dbMessages.child(this.standardizedhostname).child(this.cId).child(this.conversationId).child(messagesSnapshot.key).child("seenBy").child(this.cId).set(true);
				});
			});
		},
		addMessage(senderId, messageContent, _sentAt) {
			var sentAt = new Date(_sentAt).toLocaleString("en-GB", {
				day: "2-digit",
				month: "2-digit",
				year: "numeric",
				hour: "2-digit",
				minute: "2-digit"
			});
			var dom = "";
			if (senderId === this.cId || senderId === this.player_id) {
				dom =
					'<div class="row">' +
					'<div class="col-md-12">' +
					'<div class="pull-right">' +
					'<table align="right">' +
					"<tbody>" +
					"<tr>" +
					'<td class="time" colspan="4" style="text-align: right; color:gray;font-size:10">' +
					'<b><i>You' + (this.listPlayer.find((x) => x.id == senderId && x.is_captain) ? ' (Captain)' : '') + '</i></b>, ' + 
					sentAt +
					"</td>" +
					"</tr>" +
					"<tr>" +
					'<td><p class="triangle-border right">' +
					messageContent.replace(/\n/g, "<br/>") +
					"</p></td>" +
					'<td width="50px" valign="top">' +
					'<img src="' +
					(this.$store.state.player.avatar ? this.$store.state.player.avatar : "user2-160x160.jpg") +
					'" width="50px" height="50px" class="img img-circle pull-right">' +
					"</td>" +
					"</tr>" +
					"</tbody>" +
					"</table>" +
					"</div>" +
					"</div>" +
					"</div>";
			} else {
				//Player or another controller sent
				dom = '<div class="row">' + '<div class="col-md-12">' + '<div class="pull-left">' + '<table align="left">' + "<tbody>" + "<tr>";
				dom += '<td class="time" colspan="4" id="playerNameInChat" style="text-align: right; color:gray;font-size:10"><b><i>' + this.list_user[senderId]["name"] + (this.listPlayer.find((x) => x.id == senderId && x.is_captain) ? ' (Captain)' : '') + "</i></b>, " + sentAt + "</td>";
				dom +=
					"</tr>" +
					"<tr>" +
					'<td valign="top" width="50px;">' +
					'<img src="' + (this.listPlayer.find((x) => x.id == senderId) ? this.listPlayer.find((x) => x.id == senderId).avatar : 'user2-160x160.jpg') + '" width="50px" height="50px" class="img img-circle">' +
					"</td>" +
					'<td class="message-content">' +
					'<p class="triangle-border left">' +
					messageContent.replace(/\n/g, "<br/>") +
					"</p>" +
					"</td>" +
					"</tr>" +
					"</tbody>" +
					"</table>" +
					"</div>" +
					"</div>" +
					"</div>";
			}
			var temp_row = document.getElementById("temp_row");
			if (dom != "" && dom != null && temp_row != null) {
				temp_row.insertAdjacentHTML("beforeend", dom);
			}

			//Scroll to bottom
			if ($("#chat_body")[0].scrollHeight) {
				$("#chat_body")[0].scrollTop = $("#chat_body")[0].scrollHeight;
			}
		}
	}
};
</script>


<style>
.time {
	font-style: italic;
	font-size: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.body-custom {
	/*height: 400px;*/
	/*overflow-y: auto;*/
}

#chat_body {
	height: calc(100vh - 238px);
	overflow-y: auto;
	overflow-x: hidden;
}

.img-circle {
	border-radius: 50%;
}

.triangle-border {
	position: relative;
	padding: 15px;
	/*border:5px solid #f2f2f2;*/
	color: #333;
	background: #f2f2f2;
	/* css3 */
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}

/* Variant : for left positioned triangle
	------------------------------------------ */

.triangle-border.left {
	margin-left: 5px;
}

/* Variant : for right positioned triangle
	------------------------------------------ */

.triangle-border.right {
	margin-right: 5px;
}

/* THE TRIANGLE
	------------------------------------------------------------------------------------------------------------------------------- */

.triangle-border:before {
	content: "";
	position: absolute;
	bottom: -20px; /* value = - border-top-width - border-bottom-width */
	left: 40px; /* controls horizontal position */
	border-width: 20px 20px 0;
	border-style: solid;
	border-color: #f2f2f2 transparent;
	/* reduce the damage in FF3.0 */
	display: block;
	width: 0;
}

/* creates the smaller  triangle */
.triangle-border:after {
	content: "";
	position: absolute;
	bottom: -13px; /* value = - border-top-width - border-bottom-width */
	left: 47px; /* value = (:before left) + (:before border-left) - (:after border-left) */
	border-width: 13px 13px 0;
	border-style: solid;
	border-color: #f2f2f2 transparent;
	/* reduce the damage in FF3.0 */
	display: block;
	width: 0;
}

/* Variant : top
	------------------------------------------ */

/* creates the larger triangle */
.triangle-border.top:before {
	top: -20px; /* value = - border-top-width - border-bottom-width */
	bottom: auto;
	left: auto;
	right: 40px; /* controls horizontal position */
	border-width: 0 20px 20px;
}

/* creates the smaller  triangle */
.triangle-border.top:after {
	top: -13px; /* value = - border-top-width - border-bottom-width */
	bottom: auto;
	left: auto;
	right: 47px; /* value = (:before right) + (:before border-right) - (:after border-right) */
	border-width: 0 13px 13px;
}

/* Variant : left
	------------------------------------------ */

/* creates the larger triangle */
.triangle-border.left:before {
	top: 0px; /* controls vertical position */
	bottom: auto;
	left: -15px; /* value = - border-left-width - border-right-width */
	border-width: 0px 25px 25px 0;
	border-color: transparent #f2f2f2;
}

/* creates the smaller  triangle */
.triangle-border.left:after {
	top: 1px; /* value = (:before top) + (:before border-top) - (:after border-top) */
	bottom: auto;
	left: 0px; /* value = - border-left-width - border-right-width */
	border-width: 9px 21px 9px 0;
	border-color: transparent #f2f2f2;
}

/* Variant : right
	------------------------------------------ */

/* creates the larger triangle */
.triangle-border.right:before {
	top: 0px; /* controls vertical position */
	bottom: auto;
	left: auto;
	right: -15px; /* value = - border-left-width - border-right-width */
	border-width: 0px 0px 25px 25px;
	border-color: transparent #f2f2f2;
}

/* creates the smaller  triangle */
.triangle-border.right:after {
	top: 0px; /* value = (:before top) + (:before border-top) - (:after border-top) */
	bottom: auto;
	left: auto;
	right: 0px; /* value = - border-left-width - border-right-width */
	border-width: 9px 0 9px 21px;
	border-color: transparent #f2f2f2;
}

/* Let's get this party started */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: grey;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(12, 0, 0, 0.4);
}
</style>