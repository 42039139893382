<template>
	<v-main>
		<v-container>
			<v-card max-width="600" class="mt-4 mx-auto">
				<v-card-title><v-img class="mx-auto" src="logo.png" max-width="300"></v-img></v-card-title>
				<v-card-title class="justify-center">{{ $t('waiting_players') }}</v-card-title>
				<v-card-text>
					<v-list dense>
						<v-subheader>{{ $store.state.player.team_name }}</v-subheader>
						<v-divider></v-divider>
						<template v-for="(player, index) in players">
							<v-list-item :key="index">
								<v-list-item-avatar>
									<v-img :alt="`${player.name} avatar`" :src="player.avatar"></v-img>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										<span>{{ player.name }}</span>
										<span v-if="player.is_captain"> ({{ $t('captain') }})</span>
									</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>
									<span class="font-weight-black">{{ $t('ready') }}</span>
								</v-list-item-action>
							</v-list-item>
							<v-divider :key="(index + 1) * 333"></v-divider>
						</template>
					</v-list>
				</v-card-text>
			</v-card>
		</v-container>
	</v-main>
</template>

<script>
import firebase from "firebase";
export default {
	data() {
		return {
			players: [],
			teamId: null,
			status: true,
			isStarted: null
		};
	},
	mounted() {
		var playerId = this.$store.state.principal.player_id;
		let vueInstance = this;
		var standardizedhostname = this.$store.state.principal.activity.licensee_id;
		var dbLobby = firebase.database().ref().child("lobby").child(standardizedhostname).child(this.$store.state.principal.activityId);
		var dbTeam = dbLobby.child("team");
		dbTeam.on("value", function (snapshot) {
			snapshot.forEach(function (childSnapshot) {
				if (childSnapshot.val().name == vueInstance.$store.state.player.team_name) {
					vueInstance.players = [];
					childSnapshot.forEach(function (membersSnapshot) {
						membersSnapshot.forEach(function (memberSnapshot) {
							vueInstance.players.push(memberSnapshot.val());
						});
					});
				}
			});
		});
		var dbPlayer = dbLobby.child("player");
		dbPlayer.on("value", function (snapshot) {
			snapshot.forEach(function (childSnapshot) {
				var childKey = childSnapshot.key;
				var childData = childSnapshot.val();
				if (childKey == playerId) {
					let player = vueInstance.$root.clone(vueInstance.$store.state.player);
					player.is_started = childData.is_started;
					vueInstance.$store.commit("setPlayer", player);
					if (childData.is_started == 1 && vueInstance.status) {
						vueInstance.$store.commit("setStarted", true);
						vueInstance.navigateTo();
						vueInstance.status = false;
					}
				}
			});
		});

		if (this.$store.state.principal.activity.team == 0) {
			this.players.push(this.$store.state.player);
		}
	},
	methods: {
		navigateTo() {
			this.$router.push("/games/map/" + this.$store.state.principal.activityId);
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.$store.state.player.is_started == 1) {
			next()
		} else {
			next(false);
		}
	}
};
</script>
