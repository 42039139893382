import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#2196F3',
				primarydark: '#1976D2',
				secondary: '#636363',
				accent: '#FF4081',
				gtheader: '#0499FF',
				success: '#669933',
				red: "#f33",
				orange: '#FF9933'
			}
		}
	}
});
