<template>
	<v-main>
		<v-container>
			<v-form ref="form" @submit.prevent="submit">
				<v-card max-width="600" class="mt-4 mx-auto">
					<v-card-title>
						<h4>{{ $t('team_name') }}</h4>
					</v-card-title>
					<v-card-text>
						<p>{{ $t('input_team_description') }}</p>
						<v-select v-model="teamName" :items="teamList" item-text="name" item-value="name" :rules="[(v) => !!v || $t('please_input_team_name')]" :label="$t('select_team_name')" hide-details outlined small></v-select>
						<div class="d-flex align-center">
							<p>{{ $t('are_you_captain') }}</p>
							<v-spacer></v-spacer>
							<v-switch v-model="isCaptain"></v-switch>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="secondary lighten-2" class="white--text" min-width="100">{{ $t('pass') }}</v-btn>
						<v-btn color="primary" type="submit" min-width="100">{{ $t('submit') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-container>
	</v-main>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
export default {
	data() {
		return {
			teamName: null,
			isCaptain: false,
			teamList: [],
			dbRoot: firebase.database().ref().child("messaging").child(this.$store.state.principal.activity.licensee_id).child(this.$store.state.principal.activityId)
		};
	},
	mounted() {
		let vueInstance = this;
		var standardizedhostname = this.$store.state.principal.activity.licensee_id;
		var dbLobby = firebase.database().ref().child("lobby").child(standardizedhostname).child(this.$store.state.principal.activityId);
		var dbTeam = dbLobby.child("team");
		dbTeam.on("value", function (snapshot) {
			snapshot.forEach(function (childSnapshot) {
				if (childSnapshot.key != "no-team") {
					vueInstance.teamList.push({id: childSnapshot.key, name: childSnapshot.val().name});
				}
			});
		});
	},
	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				axios
					.post("/api/v1/team-data", {
						activity_id: this.$store.state.principal.activityId,
						player_id: this.$store.state.principal.player_id,
						captain: this.isCaptain,
						team_name: this.teamName
					})
					.then(() => {
						let player = this.$root.clone(this.$store.state.player);
						player.team_name = this.teamName;
						player.is_captain = this.isCaptain;
						let team = this.teamList.find((team) => team.name == this.teamName);
						player.team_id = team ? team.id : null;
						this.$store.commit("setPlayer", player);

						// create player in firebase
						this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("name").set(player.team_name + " - " + player.name + (player.is_captain ? " (Captain)" : ""));
						this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("last_login").set(firebase.database.ServerValue.TIMESTAMP);
						this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("avatar").set(player.avatar);
						this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("is_online").set(true);

						var navigate = this.$router;
						if (this.$store.state.principal.activity.team == 1) {
							navigate.push("/games/start/lobby");
						} else if(this.$store.state.principal.activity.team == 2){
							this.$store.commit("setStarted", true);
							navigate.push("/games/map/" + this.$store.state.principal.activityId);
						} else {
							navigate.push("/games/map/" + this.$store.state.principal.activityId);
						}
					});
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.$store.state.player.team_name != null) {
			next()
		} else {
			next(false);
		}
	}
};
</script>