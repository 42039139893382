<template>
	<v-app>
		<v-main style="display:flex;align-items:center;">
			<center>
				<h1>Page not found</h1>
				<p>The page you are looking for does not exist.</p>
				<v-btn small @click="back" color="primary">Go to homepage</v-btn>
			</center>
		</v-main>
	</v-app>
</template>

<script>
export default {
	methods: {
		back() {
			if (this.$store.state.principal.activityId) {
				this.$router.push("/games/map/" + this.$store.state.principal.activityId);
			} else {
				this.$router.push("/");
			}
		}
	}
}
</script>