import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import vuetify from './vuetify';
import * as VueGoogleMaps from 'gmap-vue';
import firebase from "firebase";
import i18n from "@/plugins/i18n";
import FlagIcon from "vue-flag-icon";

/* eslint-disable no-unused-vars */

Vue.config.productionTip = false;

Vue.use(VueRouter)

Vue.use(FlagIcon)

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_KEY
	},
	installComponents: true
})
Vue.component('ground-overlay', VueGoogleMaps.MapElementFactory({
	mappedProps: {
		'opacity': {}
	},
	props: {
		'source': {type: String},
		'bounds': {type: Object},
	},
	events: ['click', 'dblclick'],
	name: 'groundOverlay',
	ctr: () => window.google.maps.GroundOverlay,
	ctrArgs: (options, {source, bounds}) => [source, bounds, options],
}));

var firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
	databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
	projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGESENDERID,
	appId: process.env.VUE_APP_FIREBASE_APPID
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

import axios from 'axios'

import application from './application.vue'

import index from './component/index'
import notFound from './component/notFound'
import unauthorized from './component/unauthorized'
import navigation from './component/navigation'

import gamesNavigation from "./component/games/navigation"
import gamesAddName from "./component/games/addName"
import gamesWaitingRoom from "./component/games/waitingRoom"
import gamesMap from "./component/games/map"
import gamesAddTeamRoom from "./component/games/addTeamRoom"
import gamesLobby from "./component/games/lobby"
import gamesChat from "./component/games/chat"
import gamesRanking from "./component/games/ranking"
import gamesOnDemandTask from "./component/games/onDemandTask"

const router = new VueRouter({
	routes: [
		// common
		{ path: '', component: navigation, props: true,
			children: [
				{ path: '/', component: index, props: true },
			] 
		},
		{ path: '/unauthorized', component: unauthorized, props: true },
		{ path: '/notfound', component: notFound, props: true },
		{ path: '*', redirect: '/notfound' },

		// tenant portal
		{
			path: '', component: gamesNavigation, props: true,
			children: [
				{ path: '/games/odt', name: "odt", component: gamesOnDemandTask, props: true, meta: { requiresAuth: true } },
				{ path: '/games/ranking', name: "ranking", component: gamesRanking, props: true, meta: { requiresAuth: true } },
				{ path: '/games/chat', name: "chat", component: gamesChat, props: true, meta: { requiresAuth: true } },
				{ path: '/games/start/addname', name: "addName", component: gamesAddName, props: true, meta: { requiresAuth: true } },
				{ path: '/games/start/waitingroom', name: "waitingRoom", component: gamesWaitingRoom, props: true, meta: { requiresAuth: true } },
				{ path: '/games/start/addteam', name: "addTeam", component: gamesAddTeamRoom, props: true, meta: { requiresAuth: true } },
				{ path: '/games/start/lobby', name: "lobby", component: gamesLobby, props: true, meta: { requiresAuth: true } },
				{ path: '/games/map/:activityId', name: "map", component: gamesMap, props: true, meta: { requiresAuth: true } }
			]
		},

	]
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (store.getters.isAuthenticated) {
			if (store.state.isFinished && to.matched.some(record => record.name == "odt")) {
				next("/games/map/"+store.state.principal.activityId);
				return;
			}
			if (store.state.isStarted && to.matched.some(record => record.path.startsWith("/games/start"))) {
				next("/games/map/"+store.state.principal.activityId);
				return;
			}
			next();
			return;
		}
		next('/');
	} else {
		next();
	}
})

axios.defaults.baseURL = store.state.principal.user_host && store.state.principal.user_host.host ? store.state.principal.user_host.host : process.env.VUE_APP_API_URL;

Vue.prototype.$window = window;

var monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

Vue.filter('formatDate', function (value) {
	if (value != null) {
		return (
			value.date.day +
			" " +
			monthList[value.date.month - 1] +
			" " +
			value.date.year
		);
	} else {
		return "";
	}
});

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: display => display(application),
	data: function() {
		return {
			loader: { count: 0, stroke: 7, diameter: 50, value: false, isShow: true },
			monthList: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December"
			],
			pause: false, // variable for pause time interval
			showFinish: false,
			totalUnreadMessages: 0,
			messages: [],
			snackbar: { show: false, sender: null, message: null }
		};
	},
	created: function() {
		axios.interceptors.request.use(
			request => {
				if(request.url.includes('/api/ranking/') || request.url.includes('/api/token/')) {
					this.unload();
				} else {
					this.load();
				}
				return request;
			},
			error => {
				this.unload();

				let notification = { title: 'Unknown error', content: '' };
				if (error.response) {
					if (error.response.status == 400) {
						notification.title = 'Bad request';
					} else if (error.response.status == 500) {
						notification.title = 'Server error';
					} else if (error.response.status == 401) {
						notification.title = 'Unauthorized';
					} else if (error.response.status == 403) {
						notification.title = 'Forbidden';
					} else if (error.response.status == 404) {
						notification.title = 'Not found';
					} else {
						notification.title = 'Notification (' + error.response.status + ')';
					}
					notification.content = error.response.data;
				}
				store.commit("showNotification", notification);

				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			response => {
				this.unload();

				if (response.data && response.data.type == 'NOTIFICATION') {
					store.commit("showNotification", { title: 'Notification', content: response.data });
				}

				return response;
			},
			error => {
				this.unload();

				let notification = { title: 'Unknown error', content: '' };
				if (error.response) {
					if (error.response.status == 400) {
						notification.title = 'Bad request';
					} else if (error.response.status == 500) {
						notification.title = 'Server error';
					} else if (error.response.status == 401) {
						notification.title = 'Unauthorized';
					} else if (error.response.status == 403) {
						notification.title = 'Forbidden';
					} else if (error.response.status == 404) {
						notification.title = 'Not found';
					} else {
						notification.title = 'Notification (' + error.response.status + ')';
					}
					notification.content = error.response.data;
				}
				store.commit("showNotification", notification);

				return Promise.reject(error);
			}
		);
	},
	methods: {
		load() {
			this.loader.count++;
			this.loader.value = true;
		},
		unload() {
			this.loader.count--;
			if (this.loader.count < 0) {
				this.loader.count = 0;
			}
			this.loader.value = this.loader.count > 0;
		},
		clone(object) {
			return JSON.parse(JSON.stringify(object));
		},
		ellipsify(text, limit) {
			if (text && text.length > limit) {
				return text.substring(0, limit) + '...';
			}
			return text;
		},
		textify(text, before, after) {
			if (text && text.trim().length > 0) {
				return (before ? before : '') + text + (after ? after : '');
			}
			return '';
		},
		enum(text) {
			if (text) {
				return text.replace(/_/g, ' ');
			}
			return text;
		},
		downloadAsset(name) {
			location.href = axios.defaults.baseURL + "/asset/file/" + name + "?download&random=" + (new Date().getTime());
		},
		isImage(text) {
			if (text) {
				let q = text.toLowerCase();
				return q.endsWith('.jpg') || q.endsWith('.jpeg') || q.endsWith('.png') || q.endsWith('.gif') || q.endsWith('.jfif');
			}
			return false;
		},
		isVideo(text) {
			if (text) {
				let q = text.toLowerCase();
				return q.endsWith('.mp4') || q.endsWith('.ogg');
			}
			return false;
		},
		isAudio(text) {
			if (text) {
				let q = text.toLowerCase();
				return q.endsWith('.mp3') || q.endsWith('.wav' || q.endsWith('.ogg'));
			}
			return false;
		},
		asset(text, resolution) {
			if (text) {
				if (text.toLowerCase().startsWith('http')) {
					return text; 
				} else {
					return axios.defaults.baseURL + '/asset/' + resolution + '/' + text;
				}
			}
			return null;
		},
		projection(list, array) {
			for (let item of list) {
				for (let property in item) {
					if (!array.includes(property)) {
						delete item[property];
					}
				}
			}
		},
		formatDateTime(instant) {
			if (instant != null) {
				return (
					instant.getDate() +
					" " +
					this.monthList[instant.getMonth()] +
					" " +
					instant.getFullYear() +
					" " +
					(instant.getHours() < 10 ? "0" : "") +
					instant.getHours() +
					":" +
					(instant.getMinutes() < 10 ? "0" : "") +
					instant.getMinutes()
				);
			} else {
				return "";
			}
		},
		capitalizeFirst(text) {
			return text && text[0].toUpperCase() + text.slice(1);
		},
		notificationSound() {
			var notificationAudio = new Audio('notification.mp3');
			notificationAudio.preload = 'auto';
			notificationAudio.play();
		},
		correctAnswerSound() {
			var correctAnswerAudio = new Audio('correct_answer.mp3');
			correctAnswerAudio.preload = 'auto';
			correctAnswerAudio.play();
		},
		wrongAnswerSound() {
			var wrongAnswerAudio = new Audio('wrong_answer.mp3');
			wrongAnswerAudio.preload = 'auto';
			wrongAnswerAudio.play();
		},
		//Handle task
		isAnswerElement(type) {
			switch (type) {
				case "text_answer":
				case "number_answer":
				case "photo_answer":
				case "video_answer":
				case "multiple_answer":
				case "multiple_choice":
				case "number_range":
				case "video_recording":
					return true;
				default:
					return false;
			}
		},
		hasFileAnswer(type) {
			switch (type) {
				case "photo_answer":
				case "video_answer":
				case "video_recording":
					return true;
				default:
					return false;
			}
		},
		hasAnswer(elements) {
			return elements && elements.length > 0 ? elements.filter((element) => this.isAnswerElement(element.type)).length > 0 : false;
		},
		setValue(str, value) {
			return str.replace("%s", value);
		},
		randomInt(max) {
			return Math.floor(Math.random() * max);
		}
	}
}).$mount('#application')
