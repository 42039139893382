<template>
	<div style="min-height:50px;padding:10px;text-align:center;">
		<iframe v-if="name && name.toLowerCase().endsWith('.pdf')" :src="$root.asset(name,'file')" frameborder="0" style="height:300px;overflow:scroll;width:100%;"></iframe>
		<img v-else-if="$root.isImage(name)" :src="name" @click="showDialogImageFullscreen(name)" style="max-width: 90vw; max-height: 400px;" />
		<video v-else-if="$root.isVideo(name)" controls preload="auto" :src="name" style="max-width: 800px; max-height: 400px;"></video>
		<audio v-else-if="$root.isAudio(name)" controls preload="auto" :src="name"></audio>
		<div v-else style="padding:10px 100px 10px 10px;">{{name}}</div>

		<!-- show image fullscreen -->
		<v-dialog v-model="showImageFullscreen" @click:outside="closeDialogImageFullscreen">
			<v-img :src="selectedImage">
				<v-btn @click="closeDialogImageFullscreen" width="" class="ma-4" color="secondary lighten-2" fixed outlined small fab>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-img>
		</v-dialog>
	</div>
	
</template>

<script>
export default {
	props: ["name"],
	data() {
		return {
			showImageFullscreen: false,
			selectedImage: null
		}
	},
	methods: {
		showDialogImageFullscreen(img) {
			this.selectedImage = img;
			this.showImageFullscreen = true;
		},
		closeDialogImageFullscreen() {
			this.showImageFullscreen = false;
			this.selectedImage = null;
		}
	}
};
</script>