<template>
	<v-main>
		<div class="d-flex justify-center align-center" style="background-color: #1c262b; background-size: cover; height: 100vh">
			<v-form ref="form" @submit.prevent="start">
				<v-card width="360" elevation="0" color="#1c262b">
					<v-card-title class="title d-flex justify-center align-center">
						<v-img max-width="250" src="logo.png"></v-img>
					</v-card-title>
					<v-card-text style="padding-top: 20px">
						<v-text-field v-model="qrCode" :rules="[(v) => !!v || 'QR Code is required']" placeholder="enter the code here" class="custom-label" small background-color="white" outlined append-icon="mdi-qrcode-scan" @click:append="showQrcodeScanner = !showQrcodeScanner"></v-text-field>
						<v-btn type="submit" color="primary" width="100%">Start Game</v-btn>
					</v-card-text>
				</v-card>
			</v-form>
		</div>

		<v-dialog v-model="showQrcodeScanner" max-width="500">
			<stream-barcode-reader v-if="showQrcodeScanner" @decode="onDecode"></stream-barcode-reader>
		</v-dialog>
	</v-main>
</template>

<style>
.custom-label > .v-input__control > .v-input__slot > .v-text-field__slot > .v-label--active {
	color: #ffffffb3 !important;
	top: 0;
}
</style>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
	components: {
		StreamBarcodeReader
	},
	data: function () {
		return {
			qrCode: null,
			showQrcodeScanner: false
		};
	},
	mounted() {
		if (this.$store.state.isStarted && !this.$store.state.isFinished && this.$store.state.principal.activity) {
			this.$router.push("/games/map/" + this.$store.state.principal.activityId);
		} else if (!this.$store.state.isStarted) {
			if (this.$store.state.player && this.$store.state.player.is_started == -1) {
				this.$router.push("/games/start/waitingroom");
			} else if (this.$store.state.player && (this.$store.state.principal.activity && this.$store.state.principal.activity.team == 1) && this.$store.state.player.team_name == null && this.$store.state.player.is_started == 0) {
				this.$router.push("/games/start/addteam");
			} else if (this.$store.state.player && (this.$store.state.player.team_name != null || (this.$store.state.principal.activity && this.$store.state.principal.activity.team != null)) && this.$store.state.player.is_started == 0) {
				this.$router.push("/games/start/lobby");
			}
		} else {
			this.$root.showFinish = false;
			this.$store.commit("logout");
		}
	},
	methods: {
		start() {
			if (this.$refs.form.validate()) {
				this.qrCode = this.qrCode ? this.qrCode.trim() : null;
				this.$store
					.dispatch("start", {
						qrCode: this.qrCode
					})
					.then((result) => {
						let res = result.response.data;
						if (res.activityId) {
							this.$router.push("/games/start/addname");
						} else {
							let notification = {};
							notification.title = this.$t('notification');
							notification.content = res.message;
							this.$store.commit("showNotification", notification);
						}
					});
			}
		},
		onDecode(decodedString) {
			if (decodedString != null) {
				this.qrCode = decodedString;
				this.$nextTick(() => {
					this.start();
				})
				this.showQrcodeScanner = false;
			}
		}
	}
};
</script>

