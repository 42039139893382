import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
	"ae": {
		go_team: "Go Team",
		north: "شمال",
		north_east: "شمال شرقي",
		east: "شرق",
		south_east: "جنوب شرقي",
		south: "جنوب",
		south_west: "جنوب غربي",
		west: "غرب",
		north_west: "شمال غربي",
		ongoing_activities: "الأنشطة الجارية",
		downloaded_activities: "الأنشطة التي تم تنزيلها",
		finished_activities: "الأنشطة المنتهية",
		download: "تحميل",
		play_now: "بدء",
		cont: "استمرار",
		cancel: "الغاء",
		done: "تم",
		later: "لاحقا",
		close: "إغلاق",
		ok: "حسناً",
		delete_activity: "حذف النشاط؟",
		do_you_want_to_delete: "هل ترغب بحذف؟",
		delete: "حذف",
		play: "تشغيل",
		error: "خطأ",
		loading: "يتم التحميل",
		confirm: "تأكيد",
		downloading: "جاري التحميل",
		launch: "إنطلاق",
		enter_you_name: "اختر اسم",
		enter_name_hint: "اسم المستخدم",
		ask_name_description: "سيتم استخدام اسم المستخدم الخاص بك للتعرف عليك خلال النشاط",
		password: "كلمة السر",
		enter_activity_password: "الرجاء ادخال كلمة السر لهذا النشاط",
		licensee_password: "الرجاء ادخال كلمة السر لهذا الخادم",
		incorrect_password: "كلمة السر غير صحيحة",
		enter_password_hint: "كلمة السر الخاصة بك",
		activity_search: "البحث عن نشاط",
		qr_code_not_recognised: "لم يتم التعرف على رمز QR",
		scan_qr_code: "مسح رمز QR",
		activity_not_found: "لم يتم العثور على النشاط",
		do_you_want_to_go_home: "هل أنت متأكد من رغبتك بمغادرة النشاط والعودة إلى الصفحة الرئيسية؟",
		you_score_point_key: "لقد حصلت على %s نقطةنقاط و %s قطعة رمزية لوصولك إلى هذه المحطة",
		award_point_arrival: "لقد حصلت على %s نقطةنقاط لوصولك إلى هذه المحطة",
		award_key_arrival: "لقد حصلت على %s قطعة رمزية لوصولك إلى هذه المحطة",
		the_start_area: "نقطة البداية",
		the_finish_area: "نقطة النهاية",
		forbidden_area: "منطقة محظورة",
		choose_a_way: "قم باختيار طريق",
		you_enter_forbidden_area: "لقد دخلت منطقة محظورة",
		please_move_to_activity_zone: "توجه إلى نقطة البداية",
		next_check_point_distance: "تبعد المحطة التالية %s من هنا",
		finish_zone_distance: "تقع نقطة النهاية على مسافة %s من هنا",
		settings: "الاعدادات",
		meters_feet: "Metric - Imperial",
		sounds: "الصوت",
		language: "اللغة",
		key_required: "لا تملك القطعة الرمزية المناسبة لتفعيل هذه المحطة، هذه المحطة تتطلب %s قطعة رمزية",
		set_destination: "تحديد الوجهة",
		home: "الصفحة الرئيسية",
		chat: "محادثة",
		you_score_point: "لقد أحرزت %s نقطةنقاط",
		you_score_point_token: "لقد أحرزت %s نقطةنقاط وحصلت على %s قطعة رمزية",
		controller_score: "لقد أحرزت %s نقطةنقاط من المشرف لوصولك إلى محطة %s",
		controller_key: "لقد حصلت على %s قطعة رمزية من مشرف النشاط",
		award_key_route: "لقد حصلت على %s قطعة رمزية لاختيار هذا الطريق",
		award_point_route: "لقد حصلت على %s نقطةنقاط لاختيار هذا الطريق",
		award_point_key_route: "لقد حصلت على %s نقطةنقاط و %s قطعة رمزية لاختيار هذا الطريق",
		task: "مهمة",
		back: "رجوع",
		text_answer: "إجابة كتابية",
		numeric_answer: "إجابة رقمية",
		pass: "تجاوز",
		submit: "إرسال",
		take_photo: "تحميل صورة",
		choose_photo: "اختيار صورة",
		record_video: "تسجيل الفيديو",
		play_video: "تشغيل الفيديو",
		choose_video: "رفع الفيديو",
		person_at_checkpoint: "قم بتسليم الجهاز لأحد أفراد إدارة النشاط",
		enter_password: "إدخال كلمة السر",
		enter_score: "إدخال النتيجة / %s",
		player_answer: "إجابة اللاعب",
		pass_task_confirm: "لن تحصل على أي نقاط إذا قمت بتجاوز السؤال. تأكيد رغبتك بالتجاوز.",
		invalid_score: "النتيجة غير صالحة",
		submit_answer_alert: "لقد تم إرسال إجابتك للمراجعة. سوف تصلك النتيجة قريباً",
		ranking: "المرتبة",
		name: "اسم",
		score: "وقت",
		time: "نتيجة",
		rank: "ترتيب",
		end_zone_distance: "نهاية منطقة %s",
		start_zone_distance: "بداية منطقة %s",
		next_checkpoint_distance: "المحطة التالية %s",
		send: "إرسال",
		enter_message: "رسالة جديدة",
		today: "اليوم",
		notification: "إشعار",
		finish_activity_alert: "مبروك، لقد أكملت هذا النشاط",
		finish_activity: "إنهاء النشاط",
		partial_correct_answer: "إجابة صحيحة جزئيا",
		correct_answer: "إجابة صحيحة",
		incorrect_answer: "إجابة خاطئة",
		qr_code_not_supported: "الجهاز لا يدعم ماسح رمز QR",
		camera_not_supported: "الجهاز لا يدعم الكاميرا",
		photo_not_supported: "الجهاز لا يدعم ألبوم الصور",
		gps_not_available: "هذه النشاط يتطلب تفعيل خدمة تحديد الموقع. يرجى تفعيلها والمحاولة مجدداً",
		unknown_error: "حصل خطأ، حاول مرة أخرى أو تواصل مع فريق المساندة",
		no_internet: "الرجاء تفقد اتصالك بالانترنت",
		no_available_route: "أنت لا تملك متطلبات أي من الطرق المؤدية إلى المحطة التالية، لذا فقد تمت إعادتك إلى الخريطة",
		scan: "مسح​",
		magnetic_sensor_not_found: "لا يمكن العثور على جهاز استشعار مغنطيسي على جهازك. لن تعمل البوصلة",
		on_demand_task: "مهام حسب الطلب",
		company_questions: "أسئلة الشركة",
		photo_challenges: "تحديات مصورة",
		general_challenges: "تحديات عامة",
		new_check_point_found: "لقد وجدت محطة %s للتو، هل ترغب بإطلاق محطة %s أو الإلغاء والمتابعة إلى محطة %s؟",
		activity_ended: "انتهى النشاط",
		ask_require_key_title: "طلاق هذه المحطة سوف يكلف",
		available: "مستمر",
		change_server: "تغيير الخادم",
		controller_push_score: "لقد كافأك المشرف بـ %s نقاطنقطة لقاء مهمة %s",
		finished: "انتهى",
		ongoing: "مستمر",
		return_to_task: "العودة إلى المهمة",
		warning_no_photo: "لم تقم بالتقاط صورة، هل أنت متأكد من رغبتك بالتسليم بدون الصورة؟",
		warning_no_video: "لم تقم بتسجيل مقطع فيديو، هل أنت متأكد من رغبتك بالتسليم دون مقطع فيديو؟",
		you_enter_start_area: "لم تقم بتسجيل مقطع فيديو، هل أنت متأكد من رغبتك بالتسليم دون مقطع فيديو؟",
		tokens: "قطع رمزية",
		activity_chat: "Activity messaging",
		message: "Messaging",
		controller_chat: "Controller messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "تم بلوغ حد التنزيل",
		timer_run_out: "الموقت نفد",
		controller: "Controller",
		search: "Search",
		welcome_to: "أهلا وسهلا في %s",
		your_name: "اسمك",
		please_input_your_name: "الرجاء ادخال الاسم",
		please_take_photo: "الرجاءالتقاط صورة للأفاتار لديكم",
		team_name: "اسم الفريق",
		input_team_description: "مع فريقك قرر على اسم للفريق واختر من سيكون رئيس الفريق",
		are_you_captain: "هل انت رئيس الفريق",
		please_input_team_name: "الرجاء ادخال اسم الفريق",
		waiting_players: "انتظار انضمام جميع اللاعبين",
		lobby: "الردهة - لوبي",
		ready: "مستعد",
		captain: "الرئيس",
		please_wait: "الرجاء الانتظار",
		refresh_page:"إعادة تحميل الصفحة",
		show_other_players: "أظهر لاعبين آخرين",
		warning: "تحذير",
		finish_activity_confirm: "لن تتمكن من العودة إلى النشاط. هل أنت متأكد أنك تريد إنهاء هذا النشاط؟",
		end: "نهاية",
		return_to_activity: "العودة إلى النشاط",
		paste_from_clipboard: "لصق لقطة الشاشة",
		team_chat: "مراسلة الفريق"
	},
	"bg": {
		go_team: "Go Team",
		north: "Север",
		north_east: "Североизток",
		east: "Изток",
		south_east: "Югоизток",
		south: "Юг",
		south_west: "Югозапад",
		west: "Запад",
		north_west: "Северозапад",
		ongoing_activities: "Започнати Игри",
		downloaded_activities: "изтеглени дейности",
		finished_activities: "Приключени игри",
		download: "Изтегли",
		play_now: "Започни",
		cont: "Продължи",
		cancel: "Откажи",
		done: "Готово",
		later: "По-късно",
		close: "Затвори",
		ok: "Добре",
		delete_activity: "Изтрий игра?",
		do_you_want_to_delete: "Желаете ли да изтриете?",
		delete: "Изтрий",
		play: "Играй",
		error: "Грешка",
		loading: "Зарежда",
		confirm: "Потвърди",
		downloading: "изтегля се",
		launch: "Задействай",
		enter_you_name: "Избери име",
		enter_name_hint: "Вашето име",
		ask_name_description: "Чрез вашето име ще ви разпознаваме в играта",
		password: "Парола",
		enter_activity_password: "Моля, въведете паролата за тази игра",
		licensee_password: "Моля, въведете паролата за този сървър",
		incorrect_password: "Паролата е грешна",
		enter_password_hint: "Вашата парола",
		activity_search: "Търсене на игри",
		qr_code_not_recognised: "Неразпознат QR код",
		scan_qr_code: "Сканирай QR кода",
		activity_not_found: "Играта не е намерена",
		do_you_want_to_go_home: "Сигурни ли сте, че желаете да напуснете играта, и да се върнете към началния екран?",
		you_score_point_key: "Получихте %s точки и %s токен за достигане до този чекпойнт",
		award_point_arrival: "Получихте %s точки за достигане на този чекпойнт",
		award_key_arrival: "Получихте %s токен за достигане до този чекпойнт",
		the_start_area: "зоната на старта",
		the_finish_area: "зоната на финала",
		forbidden_area: "Забранена зона",
		choose_a_way: "Изберете път",
		you_enter_forbidden_area: "Навлязохте в забранена зона",
		please_move_to_activity_zone: "Придвижете се до зоната на старта",
		next_check_point_distance: "Следващият чекпойнт е на %s от тук",
		finish_zone_distance: "Зоната на финала е на %s от тук",
		settings: "Настройки",
		meters_feet: "Метрични - Империал",
		sounds: "Звуци",
		language: "Език",
		key_required: "Не притежавате правилния токен, за да отключите този чекпойнт, за този чекпойнт ви е необходим %s токен",
		set_destination: "Задай посока",
		home: "Начало",
		chat: "Чат",
		you_score_point: "Отбелязахте %s точки",
		you_score_point_token: "Отбелязахте %s точки и получихте %s токен",
		controller_score: "Получихте %s точки от администратора за %s чекпойнт",
		controller_key: "Получихте %s токен от администратора",
		award_key_route: "Получихте %s токен, избирайки този маршрут",
		award_point_route: "Получихте %s точки, избирайки този маршрут",
		award_point_key_route: "Получихте %s точки и %s токен, избирайки този маршрут",
		task: "Задача",
		back: "Назад",
		text_answer: "Текстов отговор",
		numeric_answer: "Числов отговор",
		pass: "Откажи се",
		submit: "Изпрати",
		take_photo: "Качете изображение",
		choose_photo: "Избери снимка",
		record_video: "Снимай видео",
		play_video: "Пусни видео",
		choose_video: "Качи видео",
		person_at_checkpoint: "Предайте таблета на организаторите",
		enter_password: "Въведи парола",
		enter_score: "Въведи резултат / %s",
		player_answer: "Отговор на играча",
		pass_task_confirm: "Ако се откажете, няма да получите никакви точки. Потвърдете, че желаете да се откажете.",
		invalid_score: "Невалиден резултат",
		submit_answer_alert: "Вашият отговор е изпратен за оценяване. Скоро ще получите резултат.",
		ranking: "Класиране",
		name: "Име",
		score: "Време",
		time: "Резултат",
		rank: "Място в класирането",
		end_zone_distance: "Зона на финала %s",
		start_zone_distance: "Зона на старта %s",
		next_checkpoint_distance: "Следващият чекпойнт %s",
		send: "Изпрати",
		enter_message: "Ново съобщение",
		today: "днес",
		notification: "НОТИФИКАЦИЯ",
		finish_activity_alert: "Поздравления, приключихте тази игра",
		finish_activity: "Приключи игра",
		partial_correct_answer: "Частично верен отговор",
		correct_answer: "Верен отговор",
		incorrect_answer: "Грешен отговор",
		qr_code_not_supported: "QR код Скенерът не се поддържа от това устройство",
		camera_not_supported: "Камерата не се поддържа от това устройство",
		photo_not_supported: "Снимковата галерия не се поддържа от това устройство",
		gps_not_available: "Това е GPS-базирана игра. Разрешете GPS функцията и пробвайте отново.",
		unknown_error: "Нещо се обърка. Моля опитайте отново или се свържете с организаторите за помощ.",
		no_internet: "Моля проверете вашата интернет връзка",
		no_available_route: "Нямате зададени изисквания за достигане до следващата точка. Върнати сте на картата.",
		scan: "Сканиране",
		magnetic_sensor_not_found: "Не е открит магнитен сензор на устройството ви. Компасът няма да работи.",
		on_demand_task: "Задачи При поискване",
		company_questions: "Въпроси за Компанията",
		photo_challenges: "Фото предизвикателства",
		general_challenges: "Основни предизвикателства",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Намирате се в зоната за начало на играта!",
		ask_require_key_title: "Включването на задачата ще ви струва ",
		available: "Налична",
		change_server: "Променете сървъра",
		controller_push_score: "Получавате %s точки от Go Team център за задача %s",
		finished: "Завършена",
		ongoing: "В процес на изпълнение",
		return_to_task: "Return To Task",
		warning_no_photo: "Не сте направили снимка. Искате ли да потвърдите изпращане без снимка?",
		warning_no_video: "Не сте направили видео. Искате ли да потвърдите изпращане без видео?",
		you_enter_start_area: "Намирате се в зоната за начало на играта!",
		tokens: "Точки",
		activity_chat: "Съобщения",
		message: "Съобщения",
		controller_chat: "Съобщения от Go Team център",
		one_to_one_team_chat: "Съобщения между екипите",
		add_team: "DСъобщение за екип",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Браво на вас!",
		partial_correct_feedback: "Добре се справихте",
		feedback_wrong: "Можете по-добре",
		max_attempts_try_one: "Отговорът е грешен. Остават ви %s опита.",
		max_attempts_try_other: "Отговорът е грешен. Остават ви %s опита.",
		download_limit_reached: "Достигнат лимитът за изтегляне",
		timer_run_out: "Времето изтече",
		controller: "Controller",
		search: "Search",
		welcome_to: "Добре дошли в %s",
		your_name: "Вашето име",
		please_input_your_name: "Моля, въведете името си.",
		please_take_photo: "Моля, снимайте се за вашия Аватар.",
		team_name: "Име на екипа",
		input_team_description: "Изберете като екип какво да е името му и кой ще бъде капитан на екипа.",
		are_you_captain: "Вие ли сте капитанът на екипа?",
		please_input_team_name: "Моля, въведете името си.",
		waiting_players: "Изчакване на участниците да се включат.",
		lobby: "Лоби",
		ready: "Готови",
		captain: "Капитан",
		please_wait: "Моля, изчакайте…",
		refresh_page: "Опресняване на страницата",
		show_other_players: "Пакажыце іншым гульцам",
		warning: "Внимание",
		finish_activity_confirm: "Няма да можете да се върнете към дейността. Наистина ли искате да прекратите тази дейност?",
		end: "Край",
		return_to_activity: "Връщане към активност",
		paste_from_clipboard: "поставете екранна снимка",
		team_chat: "Екипни съобщения"
	},
	"cn": {
		go_team: "Go Team",
		north: "北",
		north_east: "东北",
		east: "东",
		south_east: "东南",
		south: "南",
		south_west: "西南",
		west: "西",
		north_west: "西北",
		ongoing_activities: "正在进行的活动",
		downloaded_activities: "下载的活动",
		finished_activities: "已完成的活动",
		download: "下载",
		play_now: "开始",
		cont: "继续",
		cancel: "取消",
		done: "完成",
		later: "稍后",
		close: "关闭",
		ok: "OK",
		delete_activity: "要删除活动吗?",
		do_you_want_to_delete: "你要删除吗?",
		delete: "删除",
		play: "开始",
		error: "错误",
		loading: "载入中",
		confirm: "确认",
		downloading: "下载",
		launch: "发动",
		enter_you_name: "选择名称",
		enter_name_hint: "你的用户名",
		ask_name_description: "在活动中用户名是用来识别你",
		password: "密码",
		enter_activity_password: "请输入此活动密码",
		licensee_password: "请输入此服务器密码",
		incorrect_password: "密码不正确",
		enter_password_hint: "你的密码",
		activity_search: "活动搜索",
		qr_code_not_recognised: "QR码无法识别",
		scan_qr_code: "扫描QR码",
		activity_not_found: "未能找到活动",
		do_you_want_to_go_home: "你是否确定要退出活动并返回主屏幕?",
		you_score_point_key: "到达此检查点，你已得到%s分及%s代币",
		award_point_arrival: "到达此检查点，你已得到%s分",
		award_key_arrival: "到达此检查点，你已得到%s代币",
		the_start_area: "启动区",
		the_finish_area: "终点区",
		forbidden_area: "禁区",
		choose_a_way: "选择一个方法",
		you_enter_forbidden_area: "你已经进入禁区",
		please_move_to_activity_zone: "移到启动区",
		next_check_point_distance: "下个检查点位于此处的%s",
		finish_zone_distance: "终点区位于此处的%s",
		settings: "设置",
		meters_feet: "公制 - 英制",
		sounds: "音效",
		language: "语言",
		key_required: "你未有发动这个检查站的正确钥匙。这个检查点需要 %s代币",
		set_destination: "设置目标",
		home: "主画面",
		chat: "聊天",
		you_score_point: "你得到%s分",
		you_score_point_token: "你得到%s分及%s代币",
		controller_score: "到達%s检查点从控制器获得%s分",
		controller_key: "你从控制器获得%s代币",
		award_key_route: "采取这条路线你获得%s代币",
		award_point_route: "采取这条路线你获得%s分",
		award_point_key_route: "采取这条路线你获得%s分及%s代币",
		task: "任务",
		back: "返回",
		text_answer: "文字答案",
		numeric_answer: "数值答案",
		pass: "通过",
		submit: "提交",
		take_photo: "上传图片",
		choose_photo: "选择照片",
		record_video: "视频绿影",
		play_video: "播放视频",
		choose_video: "视频上载",
		person_at_checkpoint: "把平板电脑交给活动工作人员",
		enter_password: "输入密码",
		enter_score: "输入分数 / %s",
		player_answer: "玩家回答",
		pass_task_confirm: "如果你要通过，这将不会得到任何积分。确认你要通过。",
		invalid_score: "分数无效",
		submit_answer_alert: "你提交的答案将被审核。你很快就会得到一个分数",
		ranking: "排名",
		name: "名称",
		score: "时间",
		time: "得分",
		rank: "排名",
		end_zone_distance: "终点区 %s",
		start_zone_distance: "启动区%s",
		next_checkpoint_distance: "下一个检查点%s",
		send: "发送",
		enter_message: "新消息",
		today: "今天",
		notification: "通知",
		finish_activity_alert: "恭喜，你已经完成此活动",
		finish_activity: "完成活动",
		partial_correct_answer: "部分答案正确",
		correct_answer: "答案正确！",
		incorrect_answer: "答案错误！",
		qr_code_not_supported: "现行设备并不支持QR码扫描器",
		camera_not_supported: "现行设备并不支持相机",
		photo_not_supported: "现行设备并不支持照片库",
		gps_not_available: "这是一项GPS活动。启用位置服务后，然后重试。",
		unknown_error: "出现错误。请重试，或联系活动小组寻求支持。",
		no_internet: "请检查你的互联网连接",
		no_available_route: "你没有达到要求，无法获得去下一个目标地点的线路。因此你回到了地图",
		scan: "扫描",
		magnetic_sensor_not_found: "你的设备上没有发现磁力感应器，指南针无法工作",
		on_demand_task: "主动要求的任务",
		company_questions: "企业问题",
		photo_challenges: "照片任务",
		general_challenges: "普通任务",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "活动结束",
		ask_require_key_title: "在检查点进行任务将消耗",
		available: "可用的",
		change_server: "更改服务器",
		controller_push_score: "你从任务r %s 赢取了 %s 分。",
		finished: "已完成的",
		ongoing: "进行中的",
		return_to_task: "回到任务",
		warning_no_photo: "你没有拍照，你确定要提交任务吗？",
		warning_no_video: "你没有拍摄视频，你确定要提交任务吗？",
		you_enter_start_area: "你已到达起始区！",
		tokens: "代币",
		activity_chat: "Activity messaging",
		message: "Messaging",
		controller_chat: "Controller messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "达到下载限制",
		timer_run_out: "计时器用完了",
		controller: "Controller",
		search: "Search",
		welcome_to: "歡迎來到 %s",
		your_name: "你的名字",
		please_input_your_name: "請輸入你的名字。",
		please_take_photo: "請拍攝一張你的照片。",
		team_name: "團隊名稱",
		input_team_description: "與你的隊友一起決定團隊名稱，然後選擇一人成為隊長。",
		are_you_captain: "你是隊長嗎？",
		please_input_team_name: "請輸入您的團隊名稱。",
		waiting_players: "等待所有玩家加入。",
		lobby: "大堂",
		ready: "準備",
		captain: "隊長",
		please_wait: "請稍候…",
		refresh_page: "刷新页面",
		show_other_players: "显示其他玩家",
		warning: "警告",
		finish_activity_confirm: "您将无法返回活动。 您确定要结束此活动吗？",
		end: "结尾",
		return_to_activity: "返回活动",
		paste_from_clipboard: "粘贴屏幕截图",
		team_chat: "团队消息"
	},
	"cnt": {
		go_team: "Go Team",
		north: "北",
		north_east: "東北",
		east: "東方",
		south_east: "東南",
		south: "南",
		south_west: "西南",
		west: "西方",
		north_west: "西北",
		ongoing_activities: "正在進行的活動",
		downloaded_activities: "下載活動",
		finished_activities: "完成的活動",
		download: "下載",
		play_now: "開始",
		cont: "繼續",
		cancel: "取消",
		done: "完畢",
		later: "之後",
		close: "關閉",
		ok: "好的",
		delete_activity: "刪除活動？",
		do_you_want_to_delete: "您要刪除嗎？",
		delete: "刪除",
		play: "玩",
		error: "錯誤",
		loading: "正在加載",
		confirm: "確認",
		downloading: "下載",
		launch: "發動",
		enter_you_name: "選擇一個名字",
		enter_name_hint: "您的用戶名",
		ask_name_description: "您的用戶名將用於在活動中識別您的身份。",
		password: "密碼",
		enter_activity_password: "請輸入此活動的密碼",
		licensee_password: "請輸入此服務器的密碼",
		incorrect_password: "密碼不正確",
		enter_password_hint: "你的密碼",
		activity_search: "活動搜索",
		qr_code_not_recognised: "無法識別二維碼",
		scan_qr_code: "掃描二維碼",
		activity_not_found: "未找到活動",
		do_you_want_to_go_home: "您確定要退出活動並返回主屏幕嗎？",
		you_score_point_key: "您因到達此檢查點而獲得 %s 積分和 %s 代幣",
		award_point_arrival: "您到達此檢查點獲得了 %s 分。",
		award_key_arrival: "您收到 %s 代幣用於到達此檢查點。",
		the_start_area: "起始區",
		the_finish_area: "終點區",
		forbidden_area: "限制區",
		choose_a_way: "選擇一種方式",
		you_enter_forbidden_area: "您進入了禁區",
		please_move_to_activity_zone: "移動到起始區域",
		next_check_point_distance: "下一個檢查點位於此處的 %s",
		finish_zone_distance: "終點區位於 %s 距離這裡",
		settings: "設置",
		meters_feet: "公制 - 英制",
		sounds: "聲音",
		language: "語言",
		key_required: "您沒有正確的令牌來啟動此檢查點，此檢查點需要 %s 令牌",
		set_destination: "設置目的地",
		home: "家",
		chat: "聊天",
		you_score_point: "你得了 %s 分",
		you_score_point_token: "您獲得了 %s 分並獲得了 %s 代幣",
		controller_score: "您在 %s 檢查點從控制器獲得 %s 分",
		controller_key: "您從控制器收到 %s 代幣",
		award_key_route: "您收到了 %s 代幣，用於走這條路線",
		award_point_route: "選擇這條路線，您獲得了 %s 分",
		award_point_key_route: "您通過這條路線獲得 %s 積分和 %s 代幣",
		task: "任務",
		back: "後退",
		text_answer: "文字回答",
		numeric_answer: "數字答案",
		pass: "通過",
		submit: "提交",
		take_photo: "拍照",
		choose_photo: "選擇照片",
		record_video: "錄視頻",
		play_video: "播放視頻",
		choose_video: "上傳視頻",
		person_at_checkpoint: "將您的平板電腦交給活動工作人員",
		enter_password: "輸入密碼",
		enter_score: "輸入分數 / %d",
		player_answer: "玩家回答",
		pass_task_confirm: "如果您通過，您將不會獲得任何積分。確認你想通過。",
		invalid_score: "分數無效",
		submit_answer_alert: "您的答案已提交以供審核。",
		ranking: "排行",
		name: "姓名",
		time: "時間",
		score: "分數",
		rank: "排行",
		end_zone_distance: "結束區 %s",
		start_zone_distance: "開始區域 %s",
		next_checkpoint_distance: "下一個檢查點 %s",
		send: "發送",
		enter_message: "新消息",
		today: "今天",
		notification: "通知",
		finish_activity_alert: "恭喜，您已完成此活動",
		finish_activity: "完成活動",
		partial_correct_answer: "部分正確答案",
		correct_answer: "正確答案",
		incorrect_answer: "錯誤的答案",
		qr_code_not_supported: "當前設備不支持二維碼掃描儀",
		camera_not_supported: "當前設備不支持相機",
		photo_not_supported: "當前設備不支持圖片庫",
		gps_not_available: "這是一項 GPS 活動。啟用定位服務並重試",
		unknown_error: "出問題了。請重試或聯繫活動團隊尋求支援。",
		no_internet: "請檢查您的互聯網連接",
		no_available_route: "您對下一個檢查點的任何路線都沒有要求，因此您已返回地圖。",
		scan: "掃描",
		magnetic_sensor_not_found: "在您的設備上找不到磁傳感器。指南針將無法工作。",
		on_demand_task: "按需任務",
		company_questions: "公司問題",
		photo_challenges: "照片挑戰",
		general_challenges: "一般挑戰",
		new_check_point_found: "您剛剛找到檢查點 %s，您要啟動檢查點 %s 還是取消並繼續檢查點 %s？",
		warning_no_photo: "您還沒有拍照，您確定要在沒有照片的情況下提交嗎？",
		warning_no_video: "您還沒有錄製視頻，您確定要在沒有視頻的情況下提交嗎？",
		return_to_task: "返回任務",
		you_enter_start_area: "您已到達起始區域！",
		activity_ended: "活動結束",
		controller_push_score: "您為 %s 任務從控制器獲得了 %s 分",
		ask_require_key_title: "啟動此檢查點將消耗",
		please_complete_task: "請完成當前任務",
		ongoing: "進行中",
		available: "可用的",
		finished: "完成的",
		change_server: "更換服務器",
		you_have_finished: "您已完成此活動",
		timer_run_out: "計時器已用完",
		tokens: "代币",
		correct_feedback: "出色的！",
		partial_correct_feedback: "好工作！",
		feedback_wrong: "下次運氣更好！",
		message: "消息傳遞",
		controller_chat: "控制器消息傳遞",
		activity_chat: "活動消息",
		one_to_one_team_chat: "直接消息傳遞",
		add_team: "直接留言",
		controller: "控制器",
		search: "搜索",
		messaging_loading: "消息正在加載。請稍候。",
		move_in_the_activity_boundary: "在活動邊界內移動",
		download_limit_reached: "已達到下載限制",
		welcome_to: "歡迎來到 %s",
		your_name: "你的名字",
		please_input_your_name: "請輸入您的姓名",
		please_take_photo: "請為您的頭像拍照",
		team_name: "隊名",
		input_team_description: "選擇您的團隊並與您的團隊一起決定誰將成為隊長",
		are_you_captain: "你是隊長嗎？",
		please_input_team_name: "請選擇團隊名稱",
		waiting_players: "等待所有玩家加入",
		lobby: "大堂",
		ready: "準備好",
		captain: "隊長",
		please_wait: "請稍等…",
		please_wait_controller: "請等待控制器繼續活動",
		select_team_name: "選擇團隊名稱",
		send_token_info: "將您的代幣發送給其他玩家",
		select_player: "選擇播放器",
		select_token: "選擇代幣",
		you_will_send_token: "您將\n%stoken 發送給 %s",
		you_received_token: "您收到了令牌！\n%s",
		show_all: "顯示所有",
		show_team_only: "僅顯示團隊",
		team_chat: "團隊消息傳遞",
		location_unknown: "位置未知",
		select_avatar: "選擇頭像",
		show_other_players: "顯示其他玩家",
		refresh_page: "刷新頁面",
	},
	"cz": {
		go_team: "Go Team",
		north: "Sever",
		north_east: "Severovýchod",
		east: "Východ",
		south_east: "Jihovýchod",
		south: "Jih",
		south_west: "Jihozápad",
		west: "Západ",
		north_west: "Severozápad",
		ongoing_activities: "Probíhající Aktivity",
		downloaded_activities: "Stažené Aktivity",
		finished_activities: "Dokončené Aktivity",
		download: "Stažení",
		play_now: "Start",
		cont: "Pokračuj",
		cancel: "Zrušit",
		done: "Hotovo",
		later: "Později",
		close: "Zavřít",
		ok: "OK",
		delete_activity: "Zrušit Aktivitu?",
		do_you_want_to_delete: "Chcete smazat?",
		delete: "Smazat",
		play: "Spustit",
		error: "Chyba",
		loading: "Načítám",
		confirm: "Potvrďit",
		downloading: "stahuji",
		launch: "Spustit",
		enter_you_name: "Zvolte si jméno",
		enter_name_hint: "Tvé uživ. Jméno",
		ask_name_description: "Vaše jméno bude použito k identifikaci během aktivity",
		password: "Heslo",
		enter_activity_password: "Prosím zadej heslo pro tuto aktivitu",
		licensee_password: "Prosím zadej heslo pro tento server",
		incorrect_password: "Heslo je nesprávné",
		enter_password_hint: "Vaše heslo",
		activity_search: "Hledání aktivity",
		qr_code_not_recognised: "QR kód nebyl rozpoznán",
		scan_qr_code: "Skenuj QR Kód",
		activity_not_found: "Aktivita nenalezena",
		do_you_want_to_go_home: "Jste si jistí, že chcete opustit tuto aktivitu a vrátit se na úvodní obrazovku?",
		you_score_point_key: "Obrželi jste %s bodů a %s známku za dosažení tohoto checkpointu",
		award_point_arrival: "Obrželi jste %s bodů za dosažení tohoto checkpointu",
		award_key_arrival: "Obdrželi jste %s známek za dosažení tohoto checkpointu",
		the_start_area: "Start",
		the_finish_area: "Cíl",
		forbidden_area: "Zakázaná zóna",
		choose_a_way: "Vyberte si cestu",
		you_enter_forbidden_area: "Vstoupili jste do zakázané zóny",
		please_move_to_activity_zone: "Přejdi na start",
		next_check_point_distance: "Další checkpoint je umístěn %s odtud",
		finish_zone_distance: "Cílová zóna je umístěna %s odtud",
		settings: "Nastavení",
		meters_feet: "Metrická soustava - Imperiální jednotky",
		sounds: "Zvuk",
		language: "Jazyk",
		key_required: "Nemáte správný žeton ke spustění tohoto checkpointu. Tento checkpoint vyžaduje %s žeton",
		set_destination: "Nastavit cíl",
		home: "Domů",
		chat: "Chat",
		you_score_point: "Získali jste %s bodů",
		you_score_point_token: "Získali jste %s bodů a %s žetonů",
		controller_score: "Získali jste %s bodů od rozhodčího za %s checkpointů",
		controller_key: "Získali jste %s žeton od rozhodčího",
		award_key_route: "Získali jste %s žeton za zvolení této trasy",
		award_point_route: "Obdrželi jste %s bodů za zvolení této trasy",
		award_point_key_route: "Obdrželi jste %s bodů a %s token za zvolení této trasy",
		task: "Úkol",
		back: "Zpět",
		text_answer: "Slovní odpověď",
		numeric_answer: "Číselná odpověď",
		pass: "Vynechat",
		submit: "Odevzdat",
		take_photo: "Nahrajte obrázek",
		choose_photo: "Vyberte fotku",
		record_video: "Natočte video",
		play_video: "Spustit video",
		choose_video: "Nahrát video",
		person_at_checkpoint: "Odevzdejte tablet organizátorovi",
		enter_password: "Vložte heslo",
		enter_score: "Vložte výsledek / %s",
		player_answer: "Odpověď hráče",
		pass_task_confirm: "Pokud nesplníte úkol, nedostanete žádné body. Potvrďte, zda souhlasíte.",
		invalid_score: "Výsledek je neplatný",
		submit_answer_alert: "Vaše odpověď byla zaznamenána k posouzení. Zanedlouho obdržíte výsledek.",
		ranking: "Hodnocení",
		name: "Jméno",
		score: "Čas",
		time: "Skóre",
		rank: "Pořadí",
		end_zone_distance: "Konečná zóna %s",
		start_zone_distance: "Startovní zóna %s",
		next_checkpoint_distance: "Další checkpoint %s",
		send: "Poslat",
		enter_message: "Nová zpráva",
		today: "dnes",
		notification: "OZNÁMENÍ",
		finish_activity_alert: "Gratulujeme, úspěšně jste dokončili aktivitu",
		finish_activity: "Dokončete aktivitu",
		partial_correct_answer: "Částečně správná odpověď",
		correct_answer: "Správná odpověď",
		incorrect_answer: "Nesprávná odpověď",
		qr_code_not_supported: "Scanner QR kódu není na tomto zařízení podporován",
		camera_not_supported: "Kamera není na tomto zařízení podporována",
		photo_not_supported: "Knihovna s fotografiemi není na tomto zařízení podporována",
		gps_not_available: "K této aktivitě je nutná GPS. Povolte služby zjišťování polohy a zkuste to znovu.",
		unknown_error: "Něco se pokazilo. Prosím, zkuste to znovu nebo kontaktujte organizátora akce o pomoc.",
		no_internet: "Prosím zkontrolojte internetové připojení",
		no_available_route: "sent",
		scan: "Skenovat",
		magnetic_sensor_not_found: "Magnetický snímač nebyl na zařízení nalezen. Kompas je nefunkční.",
		on_demand_task: "Úkoly na vyžádání",
		company_questions: "Firemní otázky",
		photo_challenges: "Foto úkoly",
		general_challenges: "Obecné úkoly",
		new_check_point_found: "Právě jste našli úkol %s. Chcete se pustit do jeho plnění %s, nebo raději pokračovat dál k jinému úkolu %s?",
		activity_ended: "Konec aktivity",
		ask_require_key_title: "Aby se tento úkol spustil, musíte použít",
		available: "Dostupné",
		change_server: "Změnit server",
		controller_push_score: "Vašemu týmu bylo uděleno %s bodů za splnění %s úkolu.",
		finished: "Ukončené",
		ongoing: "Probíhající",
		return_to_task: "Return To Task",
		warning_no_photo: "Nevyfotili jste žádnou fotku. Opravdu chcete tento úkol potvrdit, aniž byste fotku pořídili?",
		warning_no_video: "Video se vám nepovedlo natočit. Opravdu chcete potvrdit tento úkol, aniž byste video natočili?",
		you_enter_start_area: "Právě jste vstoupili do oblasti, kde aktivita začíná.",
		tokens: "Tokeny",
		activity_chat: "Odesílání zpráv k aktivitám",
		message: "Odesílání zpráv",
		controller_chat: "Zpráva od organizátora",
		one_to_one_team_chat: "Přímé odesílání zpráv",
		add_team: "Přímá zpráva",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Vaše odpověď je nesprávná. Zkuste to znovu, zbývá %s pokus.",
		max_attempts_try_other: "Vaše odpověď je nesprávná. Zkuste to znovu, zbývá %s pokus.",
		download_limit_reached: "Bylo dosaženo limitu stahování",
		timer_run_out: "Časovač se krátí",
		controller: "Controller",
		search: "Search",
		welcome_to: "Vítejte v %s",
		your_name: "Tvoje jméno",
		please_input_your_name: "Prosím zadejte své jméno.",
		please_take_photo: "Prosím vyfoťte se pro vytvoření svého avatara.",
		team_name: "Název týmu",
		input_team_description: "V týmu se domluvte na názvu týmu a vyberte si, kdo bude kapitánem týmu.",
		are_you_captain: "Jsi týmový kapitán?",
		please_input_team_name: "Zadejte prosím název svého týmu.",
		waiting_players: "Čekání na připojení všech hráčů.",
		lobby: "Čekací místnost",
		ready: "Připraveno",
		captain: "Kapitán",
		please_wait: "Prosím čekejte…",
		refresh_page: "Obnovit stránku",
		show_other_players: "Ukažte ostatním hráčům",
		warning: "Varování",
		finish_activity_confirm: "K aktivitě se nebudete moci vrátit. Opravdu chcete tuto aktivitu ukončit?",
		end: "Konec",
		return_to_activity: "Návrat k aktivitě",
		paste_from_clipboard: "Vložte snímek obrazovky",
		team_chat: "Týmové zprávy"
	},
	"dk": {
		go_team: "Go Team",
		north: "Nord",
		north_east: "Nordøst",
		east: "Øst",
		south_east: "Sydøst",
		south: "Syd",
		south_west: "Sydvest",
		west: "Vest",
		north_west: "Nordvest",
		ongoing_activities: "Nuværende Aktiviteter",
		downloaded_activities: "Downloadede Aktiviteter",
		finished_activities: "Fuldførte Aktiviteter",
		download: "Download",
		play_now: "Begynd",
		cont: "Fortsæt",
		cancel: "Annuller",
		done: "Færdig",
		later: "Senere",
		close: "Luk",
		ok: "OK",
		delete_activity: "Slet aktivitet?",
		do_you_want_to_delete: "Ønsker du at slette?",
		delete: "Slet aktivitet?",
		play: "Afspil",
		error: "Fejl",
		loading: "Loader",
		confirm: "Bekræft",
		downloading: "downloader",
		launch: "Kør",
		enter_you_name: "Vælg et navn",
		enter_name_hint: "Dit brugernavn",
		ask_name_description: "Dit brugernavn bruges til at identificere dig i aktiviteten",
		password: "Password",
		enter_activity_password: "Indtast venligst passwordet til denne aktivitet",
		licensee_password: "Indtast venligst passwordet til denne server",
		incorrect_password: "Dette password er ikke korrekt",
		enter_password_hint: "Dit password",
		activity_search: "Aktivitetssøgning",
		qr_code_not_recognised: "QR kode ikke genkendt",
		scan_qr_code: "Scan QR koden",
		activity_not_found: "Aktivitet ikke fundet",
		do_you_want_to_go_home: "Er du sikker på at du ønsker at afslutte aktiviteten, og vende tilbage til startskærmen?",
		you_score_point_key: "Du modtog %s points og %s token for at nå dette kontrolpunkt",
		award_point_arrival: "Du modtog %s points for at nå dette kontrolpunkt",
		award_key_arrival: "Du modtog %s token for at nå dette kontrolpunkt",
		the_start_area: "startzonen",
		the_finish_area: "slutzonen",
		forbidden_area: "Begrænset zone",
		choose_a_way: "Vælg en vej",
		you_enter_forbidden_area: "Du et gået ind i en begrænset zone",
		please_move_to_activity_zone: "Flyt til startzonen",
		next_check_point_distance: "Det næste kontrolpunkt er placeret %s herfra",
		finish_zone_distance: "Slutzonen er placeret %s herfra",
		settings: "Indstillinger",
		meters_feet: "Metrisk - Imperial",
		sounds: "Lyde",
		language: "Sprog",
		key_required: "Du har ikke den korrekte token til at lancere dette kontrolpunkt, dette kontrolpunkt kræver en %s token",
		set_destination: "Vælg destination",
		home: "Hjem",
		chat: "Chat",
		you_score_point: "Du scorede %s points",
		you_score_point_token: "Du scorede %s points og du modtog %s token",
		controller_score: "Du scorede %s points fra controlleren for %s kontrolpunktet",
		controller_key: "Du modtog %s token fra controlleren",
		award_key_route: "Du modtog %s token for at have taget denne rute",
		award_point_route: "Du modtog %s points for at have taget denne rute",
		award_point_key_route: "Du modtog %s points og %s token for at have taget denne rute",
		task: "Opgave",
		back: "Tilbage",
		text_answer: "SMS svaret",
		numeric_answer: "Numerisk svar",
		pass: "Spring over",
		submit: "Indsend",
		take_photo: "Upload et billede",
		choose_photo: "Vælg Billede",
		record_video: "Optag Video",
		play_video: "Afspil Video",
		choose_video: "Upload Video",
		person_at_checkpoint: "Giv tablet til begivenhedspersonalet",
		enter_password: "Indtast password",
		enter_score: "Indtast score / %s",
		player_answer: "Spiller Svar",
		pass_task_confirm: "Du modtager ikke nogle points hvis du springer over. Bekræft at du ønsker at springe over.",
		invalid_score: "Score ikke gyldig",
		submit_answer_alert: "Dit svar er blevet indsendt og skal gennemgås. Du modtager snart din score.",
		ranking: "Rangering",
		name: "Navn",
		score: "Tid",
		time: "Score",
		rank: "Rangering",
		end_zone_distance: "Slutzone %s",
		start_zone_distance: "Startzone %s",
		next_checkpoint_distance: "Det næste kontrolpunkt %s",
		send: "Send",
		enter_message: "Ny besked",
		today: "i dag",
		notification: "notifikation",
		finish_activity_alert: "Tillykke, du har fuldført denne aktivitet",
		finish_activity: "Fuldfør aktivitet",
		partial_correct_answer: "Delvist korrekt svar",
		correct_answer: "Korrekt svar",
		incorrect_answer: "Ukorrekt svar",
		qr_code_not_supported: "QR kodescanner er ikke understøttet af den nuværende enhed",
		camera_not_supported: "Kamera er ikke understøttet af den nuværende enhed",
		photo_not_supported: "Fotogalleriet er ikke understøttet af den nuværende enhed",
		gps_not_available: "Dette er en GPS aktivitet. Aktiver placeringstjenester og prøv igen",
		unknown_error: "Noget gik galt. Prøv venligst igen eller kontakt begivenhedsteamet for at få hjælp.",
		no_internet: "Tjek venligst din internetforbindelse",
		no_available_route: "You do not have the requirements for any of the routes to the next checkpoint, so you have been returned to the map",
		scan: "Scan",
		magnetic_sensor_not_found: "Magnetic sensor cannot be found on your device. Compass will not work.",
		on_demand_task: "On Demand Task",
		company_questions: "Company Questions",
		photo_challenges: "Photo Challenges",
		general_challenges: "General Challenges",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activity ended",
		ask_require_key_title: "Launching this checkpoint will consume",
		available: "Available",
		change_server: "Change server",
		controller_push_score: "You scored %s points from the controller for %s task",
		finished: "Finished",
		ongoing: "Ongoing",
		return_to_task: "Return To Task",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		you_enter_start_area: "You have reached the start area!",
		tokens: "Tokens",
		activity_chat: "Aktive beskeder",
		message: "Beskeder",
		controller_chat: "Kontroller beskeder",
		one_to_one_team_chat: "Direkte beskeder",
		add_team: "Direkte besked",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Dit svar er forkert. Du har %s forsøg tilbage.",
		max_attempts_try_other: "Dit svarer forkert. Du har %s forsøg tilbage",
		download_limit_reached: "Downloadgrænsen nået",
		timer_run_out: "Timeren løber tør",
		controller: "Controller",
		search: "Search",
		welcome_to: "Velkommen til %s",
		your_name: "Dit navn",
		please_input_your_name: "Indsæt venligst dit navn",
		please_take_photo: "Tag venligst et billede til din avatar",
		team_name: "Holdnavn",
		input_team_description: "Vælg sammen med dit hold et holdnavn og udvælg en holdkaptajn",
		are_you_captain: "Er du holdkaptajn?",
		please_input_team_name: "Indsæt venligst dit holdnavn",
		waiting_players: "Venter på at alle spillere deltager",
		lobby: "Lobby",
		ready: "Klar",
		captain: "Kaptajn",
		please_wait: "Vent venligst…",
		refresh_page: "Opdater side",
		show_other_players: "Vis andre spillere",
		warning: "Advarsel",
		finish_activity_confirm: "Du vil ikke være i stand til at vende tilbage til aktiviteten. Er du sikker på, at du vil afslutte denne aktivitet?",
		end: "Ende",
		return_to_activity: "Tilbage til aktivitet",
		paste_from_clipboard: "Indsæt skærmbillede",
		team_chat: "Team beskeder"
	},
	"be": {
		go_team: "Go Team",
		north: "Noord",
		north_east: "Noord-Oost",
		east: "Oosten",
		south_east: "Zuid-Oost",
		south: "Zuiden",
		south_west: "Zuid-West",
		west: "Westen",
		north_west: "Noord-West",
		ongoing_activities: "Lopende activiteiten",
		downloaded_activities: "Gedownloade Activiteiten",
		finished_activities: "Afgewerkte activiteiten",
		download: "Download",
		play_now: "Start",
		cont: "Ga verder",
		cancel: "Cancel",
		done: "Klaar",
		later: "Later",
		close: "Sluit",
		ok: "OK",
		delete_activity: "Activiteit verwijderen?",
		do_you_want_to_delete: "Wil je dit verwijderen?",
		delete: "Verwijder",
		play: "Speel",
		error: "Fout",
		loading: "Laden",
		confirm: "Bevestig",
		downloading: "Downloaden",
		launch: "Lanceren",
		enter_you_name: "Kies een naam",
		enter_name_hint: "Je gebruikersnaam",
		ask_name_description: "Je gebruikersnaam zal worden gebruikt om je te identificeren tijdens het spel.",
		password: "Paswoord",
		enter_activity_password: "Vul een paswoord in voor deze activiteit aub.",
		licensee_password: "Vul een paswoord in voor deze server aub.",
		incorrect_password: "Dit paswoord is foutief.",
		enter_password_hint: "Je paswoord",
		activity_search: "Activiteit zoeken",
		qr_code_not_recognised: "QR code wordt herkend",
		scan_qr_code: "Scan de QR code",
		activity_not_found: "Activiteit niet gevonden",
		do_you_want_to_go_home: "Bent u zeker dat u de activiteit wil verlaten en wil terugkeren naar het home-scherm?",
		you_score_point_key: "Je ontving %s punten en %s token voor het bereiken van dit oriëntatiepunt",
		award_point_arrival: "Je ontving %s punten voor het bereiken van dit oriëntatiepunt",
		award_key_arrival: "Je ontving %s token voor het bereiken van dit oriëntatiepunt.",
		the_start_area: "Startzone",
		the_finish_area: "Eindzone",
		forbidden_area: "Beperkingsgebied",
		choose_a_way: "Kies een route",
		you_enter_forbidden_area: "Je bereikte een beperkingszone",
		please_move_to_activity_zone: "Ga naar de startzone",
		next_check_point_distance: "Het volgende oriëntatiepunt is %s vanaf hier",
		finish_zone_distance: "De eindzone is %s vanaf hier",
		settings: "Instellingen",
		meters_feet: "Metrisch - imperiaal",
		sounds: "Geluiden",
		language: "Taal",
		key_required: "Je hebt niet de juiste token voor dit oriëntatiepunt, dit oriëntatiepunt heeft token %s nodig.",
		set_destination: "Plaats bestemming",
		home: "Home",
		chat: "Chat",
		you_score_point: "Je scoorde %s punten",
		you_score_point_token: "Je scoorde %s punten en ontving %s token",
		controller_score: "Je scoorde %s punten van het bedieningspaneel voor dit oriëntatiepunt %s",
		controller_key: "Je ontving %s token van het bedieningspaneel ",
		award_key_route: "Je ontving %s token voor het nemen van deze route",
		award_point_route: "Je ontving %s punten voor het nemen van deze route",
		award_point_key_route: "Je ontving %s punten en %s token voor het nemen van deze route",
		task: "Taak",
		back: "Terug",
		text_answer: "Antwoord met tekst",
		numeric_answer: "Antwoord met cijfer",
		pass: "Pas",
		submit: "Bevestig",
		take_photo: "Upload een afbeelding",
		choose_photo: "Kies foto",
		record_video: "Neem video op",
		play_video: "Speel video",
		choose_video: "Upload video",
		person_at_checkpoint: "Geef je tablet aan de eventorganisatoren",
		enter_password: "Geef paswoord in",
		enter_score: "Geef score in / %s",
		player_answer: "Spelersantwoord",
		pass_task_confirm: "Je ontvangt geen punten als je past. Bevestig dat je wil passen.",
		invalid_score: "Score is niet geldig",
		submit_answer_alert: "Je antwoord is verstuurd om nagekeken te worden. Je zal dadelijk een score ontvangen.",
		ranking: "Ranking",
		name: "Naam",
		score: "Tijd",
		time: "Score",
		rank: "Plaats",
		end_zone_distance: "Eindzone %s",
		start_zone_distance: "Startzone %s",
		next_checkpoint_distance: "Het volgende oriëntatiepunt %s",
		send: "Verstuur",
		enter_message: "Nieuw bericht",
		today: "Vandaag",
		notification: "MELDINGEN",
		finish_activity_alert: "Proficiat, je hebt de opdracht beëindigd.",
		finish_activity: "Beëindig activiteit",
		partial_correct_answer: "Gedeeltelijk juist antwoord",
		correct_answer: "Juist antwoord",
		incorrect_answer: "Fout antwoord",
		qr_code_not_supported: "QR code scanner wordt niet ondertsteund door dit apparaat",
		camera_not_supported: "Camera wordt niet ondersteund door dit apparaat",
		photo_not_supported: "Foto bibliotheek wordt niet ondersteund door dit apparaat",
		gps_not_available: "Dit is een GPS activiteit. Schakel de locatie herkenning in en probeer opnieuw.",
		unknown_error: "Er ging iets verkeerd. Probeer opnieuw of contacteer de evenementorganisator voor hulp.",
		no_internet: "Controleer je internetverbinding aub.",
		no_available_route: "Je hebt niet de juiste vereisten voor een van de routes tot aan het volgende oriëntatiepunt. Je werd teruggestuurd naar de kaart.",
		scan: "Scan",
		magnetic_sensor_not_found: "De magnetische sensor kan niet gevonden worden op je toestel. Het kompas zal niet werken.",
		on_demand_task: "Taken op aanvraag.",
		company_questions: "Bedrijfsvragen",
		photo_challenges: "Foto-uitdagingen",
		general_challenges: "Algemene uitdagingen",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activiteit beëindigd",
		ask_require_key_title: "Dit checkpoint lanceren kost",
		available: "Available",
		change_server: "Verander de server",
		controller_push_score: "Je bent beloond met %s punten door de game master voor taak %s",
		finished: "Beëindigd",
		ongoing: "Lopend",
		return_to_task: "Keer terug naar taak",
		warning_no_photo: "Je hebt nog geen foto genomen, ben je zeker dat je wil insturen zonder foto?",
		warning_no_video: "Je hebt nog geen video gemaakt, ben je zeker dat je wil insturen zonder video?",
		you_enter_start_area: "Je hebt de start zone bereikt!",
		tokens: "Tokens",
		activity_chat: "Activiteitsberichten",
		message: "Berichtmeldingen",
		controller_chat: "Moderatorberichten",
		one_to_one_team_chat: "Rechtstreekse berichten",
		add_team: "Rechtstreeks bericht",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Perfect",
		partial_correct_feedback: "Goed gedaan",
		feedback_wrong: "Helaas, volgende keer beter",
		max_attempts_try_one: "Je antwoord is fout. Je hebt nog %s poging over'.",
		max_attempts_try_other: "Je antwoord is fout. Je hebt nog %s pogingen over'.",
		download_limit_reached: "Downloadlimiet bereikt",
		timer_run_out: "De timer loopt af",
		controller: "Controller",
		search: "Search",
		welcome_to: "Welkom bij %s",
		your_name: "Naam",
		please_input_your_name: "Vul hier jouw naam in",
		please_take_photo: "Neem een avontuurlijke foto voor jouw avatar",
		team_name: "Team naam",
		input_team_description: "Bepaal met je team een teamnaam en kies wie de teamcaptain wordt.",
		are_you_captain: "Ben jij de teamcaptain?",
		please_input_team_name: "Wat is jullie teamnaam?",
		waiting_players: "Wacht tot alle spelers meedoen",
		lobby: "Lobby",
		ready: "Klaar",
		captain: "Captain",
		please_wait: "Nog even geduld…",
		refresh_page: "Pagina vernieuwen",
		show_other_players: "Toon andere spelers",
		warning: "Waarschuwing",
		finish_activity_confirm: "U kunt niet terugkeren naar de activiteit. Weet je zeker dat je deze activiteit wilt beëindigen?",
		end: "Einde",
		return_to_activity: "Terug naar activiteit",
		paste_from_clipboard: "Schermafbeelding plakken",
		team_chat: "Teamberichten"
	},
	"en": {
		go_team: "Go Team",
		north: "North",
		north_east: "North East",
		east: "East",
		south_east: "South East",
		south: "South",
		south_west: "South West",
		west: "West",
		north_west: "North West",
		ongoing_activities: "Ongoing Activities",
		downloaded_activities: "Downloaded Activities",
		finished_activities: "Finished Activities",
		download: "Download",
		play_now: "Start",
		cont: "Continue",
		cancel: "Cancel",
		done: "Done",
		later: "Later",
		close: "Close",
		ok: "OK",
		delete_activity: "Delete activity?",
		do_you_want_to_delete: "Do you want to delete?",
		delete: "Delete",
		play: "Play",
		error: "Error",
		loading: "Loading",
		confirm: "Confirm",
		downloading: "downloading",
		launch: "Launch",
		enter_you_name: "Choose a name",
		enter_name_hint: "Your username",
		ask_name_description: "Your username will be used to identify you in the activity.",
		password: "Password",
		enter_activity_password: "Please enter the password for this activity",
		licensee_password: "Please enter the password for this server",
		incorrect_password: "The password is incorrect",
		enter_password_hint: "Your password",
		activity_search: "Activity Search",
		qr_code_not_recognised: "QR code not recognised",
		scan_qr_code: "Scan the QR Code",
		activity_not_found: "Activity not found",
		do_you_want_to_go_home: "Are you sure you want to exit the activity and return to the home screen?",
		you_score_point_key: "You received %s points and %s token for reaching this checkpoint",
		award_point_arrival: "You received %s points for reaching this checkpoint.",
		award_key_arrival: "You received %s token for reaching this checkpoint.",
		the_start_area: "The start zone",
		the_finish_area: "The finish zone",
		forbidden_area: "Restricted Zone",
		choose_a_way: "Choose a way",
		you_enter_forbidden_area: "You entered a restricted zone",
		please_move_to_activity_zone: "Move to the start zone",
		next_check_point_distance: "The next checkpoint is located %s from here",
		finish_zone_distance: "The end zone is located %s from here",
		settings: "Settings",
		meters_feet: "Metric - Imperial",
		sounds: "Sounds",
		language: "Language",
		key_required: "You do not have the correct token to launch this checkpoint, this checkpoint requires the %s token",
		set_destination: "Set destination",
		home: "Main Screen",
		chat: "Chat",
		you_score_point: "You scored %s points",
		you_score_point_token: "You scored %s points and you received %s token",
		controller_score: "You scored %s points from controller for %s checkpoint",
		controller_key: "You received %s token from controller",
		award_key_route: "You received %s token for taking this route",
		award_point_route: "You received %s points for taking this route",
		award_point_key_route: "You received %s points and %s token for taking this route",
		task: "Task",
		back: "Back",
		text_answer: "Text answer",
		numeric_answer: "Numeric answer",
		pass: "Pass",
		submit: "Submit",
		take_photo: "Upload an image",
		choose_photo: "Choose Photo",
		record_video: "Record Video",
		play_video: "Play Video",
		choose_video: "Upload Video",
		person_at_checkpoint: "Give your tablet to the event staff",
		enter_password: "Enter Password",
		enter_score: "Enter score / %s",
		player_answer: "Player Answer",
		pass_task_confirm: "You will not receive any points if you pass. Confirm you want to pass.",
		invalid_score: "Score not valid",
		submit_answer_alert: "Your answer has been submitted to be reviewed.",
		ranking: "Ranking",
		name: "Name",
		time: "Time",
		score: "Score",
		rank: "Rank",
		end_zone_distance: "End zone %s",
		start_zone_distance: "Start zone %s",
		next_checkpoint_distance: "The next checkpoint %s",
		send: "Send",
		enter_message: "New message",
		today: "today",
		notification: "Notification",
		finish_activity_alert: "Congratulations, you have finished this activity",
		finish_activity: "Finish Activity",
		partial_correct_answer: "Partly Correct Answer",
		correct_answer: "Correct Answer",
		incorrect_answer: "Incorrect Answer",
		qr_code_not_supported: "QR Code Scanner not supported by the current device",
		camera_not_supported: "Camera not supported by the current device",
		photo_not_supported: "Photo library not supported by the current device",
		gps_not_available: "This is a GPS activity. Enable location services and try again",
		unknown_error: "Something went wrong. Please try again or contact the event team for support.",
		no_internet: "Please check your internet connection",
		no_available_route: "You do not have the requirements for any of the routes to the next checkpoint, so you have been returned to the map.",
		scan: "Scan",
		magnetic_sensor_not_found: "Magnetic sensor cannot be found on your device. Compass will not work.",
		on_demand_task: "On Demand Task",
		company_questions: "Company Questions",
		photo_challenges: "Photo Challenges",
		general_challenges: "General Challenges",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		return_to_task: "Return To Task",
		you_enter_start_area: "You have reached the start area!",
		activity_ended: "Activity ended",
		controller_push_score: "You scored %s points from the controller for %s task",
		ask_require_key_title: "Launching this checkpoint will consume",
		please_complete_task: "Please complete the current task",
		ongoing: "Ongoing",
		available: "Available",
		finished: "Finished",
		change_server: "Change server",
		you_have_finished: "You have finished this activity",
		timer_run_out: "The timer has run out",
		tokens: "Tokens",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		message: "Messaging",
		controller_chat: "Controller messaging",
		activity_chat: "Activity messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		controller: "Controller",
		search: "Search",
		messaging_loading: "Messaging is loading. Please wait a moment.",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		move_in_the_activity_boundary: "Move in the activity boundary",
		download_limit_reached: "Download limit reached",
		welcome_to: "Welcome to %s",
		your_name: "Your Name",
		please_input_your_name: "Please input your name",
		please_take_photo: "Please take a photo for your avatar",
		team_name: "Team Name",
		input_team_description: "With your team, decide on a team name and choose who will be the team captain",
		are_you_captain: "Are you the team captain?",
		please_input_team_name: "Please select team name",
		waiting_players: "Waiting for all players to join",
		lobby: "Lobby",
		ready: "Ready",
		captain: "Captain",
		please_wait: "Please Wait…",
		please_wait_controller: "Please wait controller to continue the activity",
		select_team_name: "Select Team Name",
		send_token_info: "Send your token to other player",
		select_player: "Select Player",
		select_token: "Select Token",
		you_will_send_token: "You will send %s token(s) to %s",
		you_received_token: "You received token(s)! %s",
		show_all: "Show All",
		show_team_only: "Show Team Only",
		refresh_page: "Refresh Page",
		show_other_players: "Show other players",
		warning: "Warning",
		finish_activity_confirm: "You will not be able to return to the activity.<br /> Are you sure you want to end this activity?",
		end: "End",
		return_to_activity: "Return To Activity",
		paste_from_clipboard: "Paste Screen Shot",
		team_chat: "Team messaging"
	},
	"ee": {
		go_team: "Go Team",
		north: "Põhi",
		north_east: "Kirre",
		east: "Ida",
		south_east: "Kagu",
		south: "Lõuna",
		south_west: "Edel",
		west: "Lääs",
		north_west: "Loe",
		ongoing_activities: "Käimasolevad tegevused",
		downloaded_activities: "Allalaaditud Aegevused",
		finished_activities: "Lõpetatud tegevused",
		download: "Allalaadimine",
		play_now: "Alusta",
		cont: "Jätka",
		cancel: "Lõpeta",
		done: "Valmis",
		later: "Hiljem",
		close: "Sulge",
		ok: "OK",
		delete_activity: "Kustutan tegevuse?",
		do_you_want_to_delete: "Kas soovid kustutada?",
		delete: "Kustuta",
		play: "Mängi",
		error: "Probleem",
		loading: "Laeb",
		confirm: "Kinnita",
		downloading: "allalaadimas",
		launch: "Käivita",
		enter_you_name: "Vali nimi",
		enter_name_hint: "Sinu kasutajanimi",
		ask_name_description: "Sinu kasutajanime kasutatakse sinu tuvastamiseks tegevuse käigus",
		password: "Salasõna",
		enter_activity_password: "Palun sisesta selle tegevuse salasõna",
		licensee_password: "Palun sisesta selle serveri salasõna",
		incorrect_password: "Sisestatud salasõna ei ole õige",
		enter_password_hint: "Sinu salasõna",
		activity_search: "Tegevuste otsing",
		qr_code_not_recognised: "QR-koodi ei tuvastatud",
		scan_qr_code: "Skanni QR-kood",
		activity_not_found: "Tegevust ei leitud",
		do_you_want_to_go_home: "Kas soovid tõesti tegevuse sulgeda ja naasta avakuvale?",
		you_score_point_key: "Teenisid %s punkti ja %s märki siia jõudmise eest",
		award_point_arrival: "Teenisid %s punkti siia jõudmise eest",
		award_key_arrival: "Teenisid %s märki siia jõudmise eest",
		the_start_area: "Algusala",
		the_finish_area: "Lõppala",
		forbidden_area: "Keelatud ala",
		choose_a_way: "Vali tee",
		you_enter_forbidden_area: "Sisenesid keelatud alale",
		please_move_to_activity_zone: "Liigu algusalale",
		next_check_point_distance: "Järgmine teabepunkt asub siit %s kaugusel",
		finish_zone_distance: "Lõppala asub siit %s kaugusel",
		settings: "Seaded",
		meters_feet: "Meeter - Imperial",
		sounds: "Heli",
		language: "Keel",
		key_required: "Sul ei ole selle teabepunkti käivitamiseks vajalikku märki. See teabepunkt vajab %s märki.",
		set_destination: "Sea sihtkohaks",
		home: "Kodu",
		chat: "Vestlus",
		you_score_point: "Said %s punkti",
		you_score_point_token: "Said %s punkti ja %s märki",
		controller_score: "Said %s teabepunkti kontrollijalt %s punkti",
		controller_key: "Said teabepunkti kontrollijalt %s märki",
		award_key_route: "Said selle tee valimise eest %s märki",
		award_point_route: "Said selle tee valimise eest %s punkti",
		award_point_key_route: "Said selle tee valimise eest %s punkti ja %s märki",
		task: "Ülesanne",
		back: "Tagasi",
		text_answer: "Tekstivastus",
		numeric_answer: "Numbrivastus",
		pass: "Jäta vahele",
		submit: "Esita",
		take_photo: "Laadige üles pilt",
		choose_photo: "Vali foto",
		record_video: "Filmi video",
		play_video: "Mängi videot",
		choose_video: "Lae video üles",
		person_at_checkpoint: "Anna tahvelarvuti mängujuhi kätte",
		enter_password: "Sisesta salasõna",
		enter_score: "Sisesta tulemus / %s",
		player_answer: "Mängija vastus",
		pass_task_confirm: "Vahele jättes ei saa sa siit punkte. Kinnita, et soovid vahele jätta.",
		invalid_score: "Tulemus ei kehti",
		submit_answer_alert: "Vastus hindamiseks esitatud. Näed peagi oma tulemust.",
		ranking: "Hinne",
		name: "Nimi",
		score: "Aeg",
		time: "Tulemus",
		rank: "Hinne",
		end_zone_distance: "Lõppala %s",
		start_zone_distance: "Algusala %s",
		next_checkpoint_distance: "Järgmine teabepunkt %s",
		send: "Saada",
		enter_message: "Uus sõnum",
		today: "täna",
		notification: "MÄRGUANNE",
		finish_activity_alert: "Palju õnne, oled tegevuse lõpetanud!",
		finish_activity: "Lõpeta tegevus",
		partial_correct_answer: "Osaliselt õige vastus",
		correct_answer: "Õige vastus",
		incorrect_answer: "Vale vastus",
		qr_code_not_supported: "See seade ei toeta QR-koodi skannerit",
		camera_not_supported: "See seade ei toeta kaamerat",
		photo_not_supported: "See seade ei toeta pildigaleriid",
		gps_not_available: "See on GPS-tegevus. Luba asukohatuvastus ja proovi uuesti",
		unknown_error: "Midagi läks valesti. Proovi uuesti või küsi mängujuhilt abi",
		no_internet: "Kontrolli internetiühendust",
		no_available_route: "Järgmisse teabepunkti jõudmiseks ei ole täidetud vastavad tingimused. Sind on suunatud tagasi kaardivaatesse.",
		scan: "Skanni",
		magnetic_sensor_not_found: "Seadmes ei ole magnetsensorit. Kompass ei tööta.",
		on_demand_task: "Tellitavad ülesanded",
		company_questions: "Ettevõttega seotud küsimused",
		photo_challenges: "Piltülesanded",
		general_challenges: "Üldised ülesanded",
		new_check_point_found: "Leidsid teabepunkti %s. Kas soovid teabepunkti %s KÄIVITADA või KATKESTADA ja liikuda teabepunkti %s?",
		activity_ended: "Tegevus lõpetatud",
		ask_require_key_title: "Selle teabepunkti käivitamine kasutab",
		available: "Saadaolev",
		change_server: "Muuda serverit",
		controller_push_score: "Said %s ülesande kontrollijalt %s punkti",
		finished: "Lõpetatud",
		ongoing: "Käimasolev",
		return_to_task: "Mine tagasi ülesande juurde",
		warning_no_photo: "Sa ei ole teinud pilti. Kas soovid vastuse esitada ilma pildita?",
		warning_no_video: "Sa ei ole salvestanud videot. Kas soovid vastuse esitada ilma videota?",
		you_enter_start_area: "Oled jõudnud mängu algusesse!",
		tokens: "Märk",
		activity_chat: "Mängu vestlus",
		message: "Vestlus",
		controller_chat: "Vestlus kontrollijaga",
		one_to_one_team_chat: "Otsesuhtlus",
		add_team: "Otsesõnum",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Suurepärane",
		partial_correct_feedback: "Päris hea",
		feedback_wrong: "Mitte päris",
		max_attempts_try_one: "Vale vastus. %s katse jäänud",
		max_attempts_try_other: "Vale vastus. %s katse jäänud",
		download_limit_reached: "Allalaadimise limiit on saavutatud",
		timer_run_out: "Taimer saab otsa",
		controller: "Controller",
		search: "Search",
		welcome_to: "Tere tulemast mängu %s",
		your_name: "Sinu nimi",
		please_input_your_name: "Kirjuta siia oma nimi",
		please_take_photo: "Palun tee endast pilt",
		team_name: "Meeskonna nimi",
		input_team_description: "Otsustage meeskonnaga, mis on teie meeskonna nimi ja kes on meeskonna kapten",
		are_you_captain: "Kas oled meeskonna kapten?",
		please_input_team_name: "Kirjuta siia oma meeskonna nimi",
		waiting_players: "Ootame, et kõik mängijad ühineksid",
		lobby: "Ooteruum",
		ready: "Valmis",
		captain: "Kapten",
		please_wait: "Palun oota…",
		refresh_page: "Värskenda lehte",
		show_other_players: "Näita teisi mängijaid",
		warning: "Hoiatus",
		finish_activity_confirm: "Te ei saa tegevuse juurde naasta. Kas olete kindel, et soovite selle tegevuse lõpetada?",
		end: "Lõpp",
		return_to_activity: "Tagasi tegevuse juurde",
		paste_from_clipboard: "Kleebi ekraanipilt",
		team_chat: "Meeskonna sõnumid"
	},
	"fi": {
		go_team: "Go Team",
		north: "Pohjoinen",
		north_east: "Koillinen",
		east: "Itä",
		south_east: "Kaakko",
		south: "Etelä",
		south_west: "Lounas",
		west: "Länsi",
		north_west: "Luode",
		ongoing_activities: "Aktiviteetit käynnissä",
		downloaded_activities: "Aktiviteetit Ladatut",
		finished_activities: "Lopetetut aktiviteetit",
		download: "Lataa",
		play_now: "Aloita",
		cont: "Jatka",
		cancel: "Peruuta",
		done: "Valmis",
		later: "Myöhemmin",
		close: "Sulje",
		ok: "OK",
		delete_activity: "Poista aktiviteetti?",
		do_you_want_to_delete: "Haluatko poistaa?",
		delete: "Poista",
		play: "Toista",
		error: "Virhe",
		loading: "Latautuu",
		confirm: "Vahvista",
		downloading: "Ladataan",
		launch: "Käynnistä",
		enter_you_name: "Valitse nimi",
		enter_name_hint: "Käyttäjätunnuksesi",
		ask_name_description: "Käyttäjätunnustasi käytetään tunnistamiseksesi aktiviteetissa",
		password: "Salasana",
		enter_activity_password: "Syötä tämän aktiviteetin salasana",
		licensee_password: "Syötä tämän palvelimen salasana",
		incorrect_password: "Salasana on virheellinen",
		enter_password_hint: "Salasanasi",
		activity_search: "Aktiviteettihaku",
		qr_code_not_recognised: "QR-koodia ei tunnisteta",
		scan_qr_code: "Lue QR-koodi",
		activity_not_found: "Aktiviteettia ei löydy",
		do_you_want_to_go_home: "Haluatko poistua aktiviteetista ja palata aloitussivulle?",
		you_score_point_key: "Sait %s pistettä ja %s pelimerkkiä saavuttamalla tämän rastipisteen",
		award_point_arrival: "Sait %s pistettä saavuttamalla tämän rastipisteen",
		award_key_arrival: "Sait %s pelimerkkiä saavuttamalla tämän rastipisteen",
		the_start_area: "Starttialue",
		the_finish_area: "Maalialue",
		forbidden_area: "Kielletty alue",
		choose_a_way: "Valitse reitti",
		you_enter_forbidden_area: "Olet astunut kielletylle alueelle",
		please_move_to_activity_zone: "Siirry starttialueelle",
		next_check_point_distance: "Seuraava rastipiste sijaitsee %s täältä",
		finish_zone_distance: "Maalialue sijaitsee %s täältä",
		settings: "Asetukset",
		meters_feet: "Metrijärjestelmä - Brittiläinen yksikköjärjestelmä",
		sounds: "Äänet",
		language: "Kieli",
		key_required: "Pelimerkkisi eivät oikeuta käynnistämään tätä rastipistettä, tämä rastipiste edellyttää %s pelimerkin",
		set_destination: "Aseta määränpää",
		home: "Alkuun",
		chat: "Chat",
		you_score_point: "Sait %s pistettä",
		you_score_point_token: "Sait %s pistettä ja %s pelimerkin",
		controller_score: "Sait %s pistettä pelimestarilta %s rastipisteestä",
		controller_key: "Sait %s pelimerkin pelimestarilta",
		award_key_route: "Sait %s pelimerkin tämän reitin valitsemisesta",
		award_point_route: "Sait %s pistettä tämän reitin valitsemisesta",
		award_point_key_route: "Sait %s pistettä ja %s pelimerkin tämän reitin valitsemisesta",
		task: "Tehtävä",
		back: "Takaisin",
		text_answer: "Tekstivastaus",
		numeric_answer: "Numerovastaus",
		pass: "Ohita",
		submit: "Lähetä",
		take_photo: "Lataa kuva",
		choose_photo: "Valitse kuva",
		record_video: "Ota video",
		play_video: "Toista video",
		choose_video: "Lataa video",
		person_at_checkpoint: "Anna pelilaite järjestäjille",
		enter_password: "Anna salasana",
		enter_score: "Anna pistemäärä / %s",
		player_answer: "Pelaajavastaus",
		pass_task_confirm: "Et saa pisteitä, jos ohitat. Vahvista ohitus.",
		invalid_score: "Tulos ei kelpaa",
		submit_answer_alert: "Vastauksesi on lähetetty arviointia varten. Saat pisteet tuota pikaa.",
		ranking: "Ranking",
		name: "Nimi",
		score: "Aika",
		time: "Pisteet",
		rank: "Sijoitus",
		end_zone_distance: "Maalialue %s",
		start_zone_distance: "Starttialue %s",
		next_checkpoint_distance: "Seuraava rastipiste %s",
		send: "Lähetä",
		enter_message: "Uusi viesti",
		today: "tänään",
		notification: "ILMOITUS",
		finish_activity_alert: "Onneksi olkoon, olette suorittaneet aktiviteetin",
		finish_activity: "Lopeta aktiviteetti",
		partial_correct_answer: "Osittain oikea vastaus",
		correct_answer: "Oikea vastaus",
		incorrect_answer: "Väärä vastaus",
		qr_code_not_supported: "Tämä laite ei tue QR-lukua",
		camera_not_supported: "Tämä laite ei tue kameratoimintoa",
		photo_not_supported: "Tämä laite ei tue kuva-albumia",
		gps_not_available: "Tämä on GPS-aktiviteetti. Salli paikannustoiminto ja yritä uudelleen",
		unknown_error: "Jotain meni väärin. Yritä uudelleen tai ota yhteyttä järjestäjiin",
		no_internet: "Tarkista Internet-yhteys",
		no_available_route: "Sinulta puuttuvat kaikki seuraavaan rastipisteeseen vaadittavat edellytykset. Ohjelma palauttaa sinut takaisin kartalle",
		scan: "Skannaa",
		magnetic_sensor_not_found: "Laitteestasi puuttuu magneettinen sensori. Kompassi ei toimi",
		on_demand_task: "Lisätehtävät ",
		company_questions: "Yrityskohtaiset kysymykset",
		photo_challenges: " Valokuvaustehtävät",
		general_challenges: "Yleiset tehtävät",
		new_check_point_found: "Saavuit rastipisteeseen %s. Haluatko KÄYNNISTÄÄ tehtävän %s vai PERUA ja jatkaa rastipisteeseen %s?",
		activity_ended: "Aktiviteetti on päättynyt",
		ask_require_key_title: "Tämän rastipisteen käynnistäminen kuluttaa ",
		available: "Käytettävissä",
		change_server: "Vaihda serveriä",
		controller_push_score: "Pelimestari on antanut sinulle %s pistettä %s tehtävästä",
		finished: "Päättynyt",
		ongoing: "Käynnissä",
		return_to_task: "Palaa tehtävään",
		warning_no_photo: "Et ole ottanut valokuvaa. Haluatko varmasti lähettää ilman valokuvaa?",
		warning_no_video: "Et ole kuvannut videota. Haluatko varmasti lähettää ilman videota?",
		you_enter_start_area: "Olet saapunut starttialueelle!",
		tokens: "Pelimerkit",
		activity_chat: "Yleiset",
		message: "Viestikeskus",
		controller_chat: "Pelimestari",
		one_to_one_team_chat: "Muut pelaajat",
		add_team: "Viesti toiselle pelaajalle",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Vastauksesi on väärin. Sinulla on %s yritystä jäljellä",
		max_attempts_try_other: "Vastauksesi on väärin. Sinulla on %s yritystä jäljellä",
		download_limit_reached: "Latausraja saavutettu",
		timer_run_out: "Ajastin loppuu",
		controller: "Controller",
		search: "Search",
		welcome_to: "Tervetuloa %s",
		your_name: "Nimesi",
		please_input_your_name: "Ole hyvä ja kirjoita oma nimesi",
		please_take_photo: "Ole hyvä ja ota selfie kuva. Kuvaa käytetään pelitunnuksenasi.",
		team_name: "Tiiminne nimi",
		input_team_description: "Päättäkää joukkueenne kanssa nimi tiimillenne ja valitkaa keskuudestanne tiimillenne Kapteeni.",
		are_you_captain: "Oletko sinä tiiminne Kapteeni?",
		please_input_team_name: "Ole hyvä ja kirjoita tiimisi nimi.",
		waiting_players: "Ole hyvä ja odota, että kaikki pelaajat liittyvät peliin.",
		lobby: "Aula",
		ready: "Valmis",
		captain: "Kapteeni",
		please_wait: "Ole hyvä ja odota…",
		refresh_page: "Päivitä sivu",
		show_other_players: "Näytä muut pelaajat",
		warning: "Varoitus",
		finish_activity_confirm: "Et voi palata toimintaan. Haluatko varmasti lopettaa tämän toiminnan?",
		end: "Loppu",
		return_to_activity: "Palaa toimintaan",
		paste_from_clipboard: "Liitä näyttökuva",
		team_chat: "Ryhmän viestit"
	},
	"fr": {
		go_team: "Go Team",
		north: "Nord",
		north_east: "Nord-Est",
		east: "Est",
		south_east: "Sud-Est",
		south: "Sud",
		south_west: "Sud-Ouest",
		west: "Ouest",
		north_west: "Nord-Ouest",
		ongoing_activities: "Activités en cours",
		downloaded_activities: "Activités Téléchargées",
		finished_activities: "Activités achevées",
		download: "Télécharger",
		play_now: "Démarrer",
		cont: "Continuer",
		cancel: "Annuler",
		done: "Terminé",
		later: "Plus tard",
		close: "Fermer",
		ok: "OK",
		delete_activity: "Supprimer l'activité?",
		do_you_want_to_delete: "Voulez-vous le/la supprimer?",
		delete: "Supprimer",
		play: "Jouer",
		error: "Erreur",
		loading: "Chargement",
		confirm: "Confirmer",
		downloading: "Téléchargement",
		launch: "Lancer",
		enter_you_name: "Choisir un nom",
		enter_name_hint: "Votre pseudo",
		ask_name_description: "Votre pseudo sera utilisé pour vous identifier",
		password: "Mot de passe",
		enter_activity_password: "Rentrez le mot de passe pour cette activité",
		licensee_password: "Rentrez le mot de passe pour ce serveur",
		incorrect_password: "Le mot de passe est incorrect",
		enter_password_hint: "Votre mot de passe",
		activity_search: "Recherche d'activités",
		qr_code_not_recognised: "Code QR non reconnu",
		scan_qr_code: "Lire le code QR",
		activity_not_found: "Aucune activité trouvée",
		do_you_want_to_go_home: "Êtes-vous sûr de vouloir quitter l'activité? Vous reviendrez sur la page d'accueil.",
		you_score_point_key: "Vous avez gagné %s points et le jeton %s en atteignant ce point de contrôle",
		award_point_arrival: "Vous avez gagné %s points en atteignant ce point de contrôle",
		award_key_arrival: "Vous avez gagné le jeton %s en atteignant ce point de contrôle",
		the_start_area: "Zone de départ",
		the_finish_area: "Zone d'arrivée",
		forbidden_area: "Zone restreinte",
		choose_a_way: "Choisir un chemin",
		you_enter_forbidden_area: "Vous avez pénétré une zone restreinte",
		please_move_to_activity_zone: "Revenir à la zone de départ",
		next_check_point_distance: "Le prochain point de contrôle se trouve à %s d'ici",
		finish_zone_distance: "La zone d'arrivée se trouve à %s d'ici",
		settings: "Configuration",
		meters_feet: "Unités métriques – Unités impériales",
		sounds: "Son",
		language: "Langue",
		key_required: "Vous ne possédez pas le bon jeton pour activer ce point de contrôle, il vous faut le jeton %s",
		set_destination: "Choisir une destination",
		home: "Accueil",
		chat: "Chat",
		you_score_point: "Vous avez gagné %s points",
		you_score_point_token: "Vous avez gagné %s points et le jeton %s",
		controller_score: "Vous avez reçu %s points de la part du superviseur du checkpoint %s",
		controller_key: "Vous avez reçu le token %s de la part du superviseur",
		award_key_route: "Vous avez reçu le token %s en prenant ce chemin",
		award_point_route: "Vous avez gagné %s points en prenant ce chemin",
		award_point_key_route: "Vous avez gagné %s points et le jeton %s en prenant ce chemin",
		task: "Tâche",
		back: "Retour",
		text_answer: "Réponse écrite",
		numeric_answer: "Réponse numérique",
		pass: "Passer",
		submit: "Soumettre",
		take_photo: "Télécharger une image",
		choose_photo: "Choisir une photo",
		record_video: "Faire une vidéo",
		play_video: "Voir la vidéo",
		choose_video: "Télécharger une vidéo",
		person_at_checkpoint: "Rendre la tablette à l'organisation de l'événement",
		enter_password: "Entrer le mot de passe",
		enter_score: "Entrer le score / %s",
		player_answer: "Réponse du joueur",
		pass_task_confirm: "Vous ne recevrez aucun point si vous passez le défi. Veuillez confirmer.",
		invalid_score: "Score invalide",
		submit_answer_alert: "Votre réponse a été soumise afin d'être vérifiée. Vous serez attribué(s) un score dans un instant.",
		ranking: "Classement",
		name: "Nom",
		score: "Temps",
		time: "Score",
		rank: "Rang",
		end_zone_distance: "Zone d'arrivée %s",
		start_zone_distance: "Zone de départ %s",
		next_checkpoint_distance: "Le prochain point de contrôle %s",
		send: "Envoyer",
		enter_message: "Nouveau message",
		today: "Aujourd'hui",
		notification: "Notification",
		finish_activity_alert: "Félicitations, vous avez conclu cette activité",
		finish_activity: "Terminer l'activité",
		partial_correct_answer: "Réponse partiellement correcte",
		correct_answer: "Réponse correcte",
		incorrect_answer: "Réponse incorrecte",
		qr_code_not_supported: "Le lecteur de code QR n'est pas supporté par le dispositif",
		camera_not_supported: "La caméra n'est pas supporté par le dispositif",
		photo_not_supported: "La galerie photos n'est pas supportée par le dispositif",
		gps_not_available: "C'est une activité GPS. Activez les services de localisation et essayez de nouveau",
		unknown_error: "Quelque chose n'a pas fonctionné correctement. Veuillez essayer de nouveau ou contactez l'organisation de l'événement.",
		no_internet: "Veuillez vérifier que votre connection internet fonctionne correctement",
		no_available_route: "Vous n'avez pas rempli les prérequis nécessaires pour consulter les itinéraires vers le prochain point de contrôle. Vous êtes renvoyé à la carte.",
		scan: "Scannez",
		magnetic_sensor_not_found: "Le capteur magnétique n'a pas été détecté sur votre dispositif. La boussole ne pourra pas être utilisée.",
		on_demand_task: "Tâches sur demande",
		company_questions: "Questions de l'entreprise",
		photo_challenges: "Épreuves photo",
		general_challenges: "Épreuves générales",
		new_check_point_found: "vous venez de trouver le point de contrôle %s, voulez-vous LANCER ce point de contrôle %s ou ANNULER et continuer vers le point de contrôle %s?",
		activity_ended: "Activité terminée",
		ask_require_key_title: "Lancer ce point de contrôle va utiliser ",
		available: "Disponible",
		change_server: "Change server",
		controller_push_score: "Vous avez recu %s points par le superviseur pour la tâche %s.",
		finished: "Terminé",
		ongoing: "En cours",
		return_to_task: "Retour à la tâche",
		warning_no_photo: "Vous n'avez pas pris de photo,êtes-vous sur de vouloir valider sans photo?",
		warning_no_video: "Vous n'avez pas enregistré de vidéo, êtes-vous sur de vouloir valider sans vidéo?",
		you_enter_start_area: "You have reached the start area!",
		tokens: "Jetons",
		activity_chat: "Message",
		message: "Chat",
		controller_chat: "Chat du Gamemaster",
		one_to_one_team_chat: "Messagerie instantanée",
		add_team: "Message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent",
		partial_correct_feedback: "Bien joué",
		feedback_wrong: "Dommage, vous ferez mieux la prochaine fois.",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "Limite de téléchargement atteinte",
		timer_run_out: "La minuterie s'épuise",
		controller: "Controller",
		search: "Search",
		welcome_to: "Bienvenue sur %s",
		your_name: "Votre Nom",
		please_input_your_name: "Veuillez indiquez votre nom.",
		please_take_photo: "Prenez une photo de profil.",
		team_name: "Nom de l'équipe",
		input_team_description: "En concertation avec vos coéquipiers, vous devez attribuer un nom à votre équipe et choisir un capitaine.",
		are_you_captain: "Êtes-vous le capitaine de l'équipe?",
		please_input_team_name: "Inscrivez le nom de votre équipe.",
		waiting_players: "En attente que tous les joueurs rejoignent la partie.",
		lobby: "Salle d'attente",
		ready: "Prêt",
		captain: "Capitaine",
		please_wait: "Veuillez patienter…",
		refresh_page: "Rafraîchir la page",
		show_other_players: "Afficher les autres joueurs",
		warning: "Avertissement",
		finish_activity_confirm: "Vous ne pourrez pas reprendre l'activité. Êtes-vous sûr de vouloir mettre fin à cette activité ?",
		end: "Finir",
		return_to_activity: "Retour à l'activité",
		paste_from_clipboard: "Coller la capture d'écran",
		team_chat: "Messagerie d'équipe"
	},
	"de": {
		go_team: "Go Team",
		north: "Norden",
		north_east: "Nord-Ost",
		east: "Osten",
		south_east: "Süd-Ost",
		south: "Süden",
		south_west: "Süd-West",
		west: "Westen",
		north_west: "Nord-West",
		ongoing_activities: "Laufende Aktivitäten",
		downloaded_activities: "Heruntergeladene Aktivitäten",
		finished_activities: "Beendete Aktivitäten",
		download: "Download",
		play_now: "Start",
		cont: "Fortfahren",
		cancel: "Beenden",
		done: "Beendet",
		later: "Später",
		close: "Schließen",
		ok: "OK",
		delete_activity: "Aktivität löschen?",
		do_you_want_to_delete: "Wirklich löschen?",
		delete: "Löschen",
		play: "Spielen",
		error: "Fehler",
		loading: "Laden",
		confirm: "Bestätigen",
		downloading: "herunterladen",
		launch: "Starten",
		enter_you_name: "Überlegt euch einen Namen",
		enter_name_hint: "Euer Benutzername",
		ask_name_description: "Wir benötigen euren Benutzernamen, um euch im Spiel zu identifizieren.",
		password: "Passwort",
		enter_activity_password: "Bitte gebt das Passwort für diese Aktivität ein",
		licensee_password: "Bitte gebt das Passwort für diesen Server ein",
		incorrect_password: "Das Passwort ist falsch",
		enter_password_hint: "Euer Passwort",
		activity_search: "Aktivität-Suche",
		qr_code_not_recognised: "Der QR Code wurde nicht erkannt",
		scan_qr_code: "QR Code scannen",
		activity_not_found: "Aktivität wurde nicht gefunden",
		do_you_want_to_go_home: "Seid ihr sicher, dass ihr die Aktivität schließen und zum Startbildschirm zurückkehren möchtet?",
		you_score_point_key: "Ihr habt %s Punkte und %s Schlüssel erhalten",
		award_point_arrival: "Ihr habt %s Punkte für das Erreichen dieses Checkpoints erhalten",
		award_key_arrival: "Ihr habt %s Schlüssel für das Erreichen dieses Checkpoints erhalten",
		the_start_area: "Der Startbereich",
		the_finish_area: "Der Zielbereich",
		forbidden_area: "Sperrgebiet",
		choose_a_way: "Wählt einen Weg",
		you_enter_forbidden_area: "Ihr befindet euch im Sperrgebiet",
		please_move_to_activity_zone: "Geht zum Startbereich",
		next_check_point_distance: "Der nächste Checkpoint befindet sich %s km von hier",
		finish_zone_distance: "Der Zielbereich befindet sich %s km von hier",
		settings: "Einstellungen",
		meters_feet: "metrisch - Imperial",
		sounds: "Ton",
		language: "Sprache",
		key_required: "Ihr habt nicht den richtigen Schlüssel, um diesen Checkpoint zu aktivieren, hierfür braucht ihr den %s Schlüssel.",
		set_destination: "Ziel festlegen",
		home: "Home",
		chat: "Chat",
		you_score_point: "Ihr habt %s Punkte erzielt",
		you_score_point_token: "Ihr habt %s Punkte erzielt und %s Schlüssel erhalten",
		controller_score: "Ihr habt %s Punkte vom Spielleiter für diesen %s Checkpoint erhalten",
		controller_key: "Ihr habt %sSchlüssel vom Spielleiter für diesen Checkpoint erhalten",
		award_key_route: "Ihr habt %s Schlüssel erhalten, weil ihr diese Route gewählt habt",
		award_point_route: "Ihr habt %s Punkte erhalten, weil ihr diese Route gewählt habt",
		award_point_key_route: "Ihr habt %s Punkte und %s Schlüssel erhalten, weil ihr diese Route gewählt habt",
		task: "Aufgabe",
		back: "Zurück",
		text_answer: "Textantwort",
		numeric_answer: "Numerische Antwort",
		pass: "Abbrechen",
		submit: "Absenden",
		take_photo: "Lade ein Bild hoch",
		choose_photo: "Foto auswählen",
		record_video: "Video aufnehmen",
		play_video: "Video abspielen",
		choose_video: "Video hochladen",
		person_at_checkpoint: "Tablet an Eventpersonal überreichen",
		enter_password: "Passwort eingeben",
		enter_score: "Punktestand eingeben / %s",
		player_answer: "Spieler-Antwort",
		pass_task_confirm: "Ihr erhaltet keine Punkte, wenn ihr die Aufgabe abbrecht. Bestätigt, wenn ihr wirklich abbrechen möchtet.",
		invalid_score: "Ergebnis ist ungültig.",
		submit_answer_alert: "Eure Antwort wurde an den Spielleiter gesendet. Ihr erhaltet euer Ergebnis schnellstmöglich.",
		ranking: "Rangliste",
		name: "Name",
		score: "Zeit",
		time: "Punktestand",
		rank: "Rang",
		end_zone_distance: "Zielbereich %s",
		start_zone_distance: "Startbereich %s",
		next_checkpoint_distance: "Der nächste Checkpoint %s",
		send: "Senden",
		enter_message: "Neue Nachricht",
		today: "heute",
		notification: "BENACHRICHTIGUNG",
		finish_activity_alert: "Herzlichen Glückwunsch, ihr habt die Aktivität gemeistert.",
		finish_activity: "Aktivität beenden",
		partial_correct_answer: "Zum Teil richtige Antwort",
		correct_answer: "Richtige Antwort",
		incorrect_answer: "Falsche Antwort",
		qr_code_not_supported: "Der QR Code Scanner wird von diesem Gerät nicht unterstützt",
		camera_not_supported: "Die Kamera wird von diesem Gerät nicht unterstützt",
		photo_not_supported: "Das Fotoarchiv wird von diesem Gerät nicht unterstützt",
		gps_not_available: "Diese Aktivität ist GPS-basiert. Aktiviert die Ortungsdienste und versucht es erneut.",
		unknown_error: "Etwas ist schief gelaufen. Bitte versucht es erneut oder wendet euch an das Event-Team.",
		no_internet: "Bitte überprüft eure Internetverbindung",
		no_available_route: "Da ihr die Anforderungen nicht erfüllt, um über eine der Routen zum nächsten Checkpoint zu gelangen, werdet ihr wieder zurück zur Karte geleitet.",
		scan: "Scannen",
		magnetic_sensor_not_found: "Der Magnetsensor konnte auf diesem Gerät nicht gefunden werden. Der Kompass wird deshalb nicht funktionieren.",
		on_demand_task: "Aufgaben auf Abruf",
		company_questions: "Firmenbezogene Fragen",
		photo_challenges: "Foto-Challenges",
		general_challenges: "Allgemeine Aufgaben",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Die Aktivität ist beendet",
		ask_require_key_title: "Wenn ihr diesen Checkpoint aktiviert, kostet euch das ",
		available: "Verfügbar",
		change_server: "Server wechseln",
		controller_push_score: "Der Spielleiter hat euch %s Punkte für Aufgabe %s gutgeschrieben.",
		finished: "Beendet",
		ongoing: "Fortlaufend",
		return_to_task: "Zurück zur Aufgabe",
		warning_no_photo: "Ihr habt noch kein Foto gemacht. Möchtet ihr wirklich ohne Foto fortfahren?",
		warning_no_video: "Ihr habt noch kein Video gemacht. Möchtet ihr wirklich ohne Video fortfahren?",
		you_enter_start_area: "Ihr habt die Startzone erreicht!",
		tokens: "Token",
		activity_chat: "Activity messaging",
		message: "Messaging",
		controller_chat: "Controller messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "Download-Limit erreicht",
		timer_run_out: "Der Timer läuft ab",
		controller: "Controller",
		search: "Search",
		welcome_to: "Willkommen zu %s",
		your_name: "Name",
		please_input_your_name: "Bitte nenne uns deinen Namen",
		please_take_photo: "Mach ein Foto für deinen Avatar.",
		team_name: "Team Name",
		input_team_description: "Entscheidet euch im Team für einen Team Namen und bestimmt einen Anführer",
		are_you_captain: "Bist du der Anführer",
		please_input_team_name: "Bitte nenne uns deinen Team Namen",
		waiting_players: "Warten auf andere Spieler",
		lobby: "Lobby",
		ready: "Bereit",
		captain: "Anführer",
		please_wait: "Bitte warten…",
		refresh_page: "Seite neu laden",
		show_other_players: "Andere Spieler anzeigen",
		warning: "Warnung",
		finish_activity_confirm: "Sie können nicht zur Aktivität zurückkehren. Möchten Sie diese Aktivität wirklich beenden?",
		end: "Ende",
		return_to_activity: "Zurück zur Aktivität",
		paste_from_clipboard: "Screenshot einfügen",
		team_chat: "Teamnachrichten"
	},
	"gr": {
		go_team: "Go Team",
		north: "Βόρεια",
		north_east: "Βορειοανατολικά",
		east: "Ανατολικά",
		south_east: "Νοτιοανατολικά",
		south: "Νότια",
		south_west: "Νοτιοδυτικά",
		west: "Δυτικά",
		north_west: "Βορειοδυτικά",
		ongoing_activities: "Τρέχουσες ασκήσεις",
		downloaded_activities: "Ληφθείσες δραστηριότητες",
		finished_activities: "Ολοκληρωμένες ασκήσεις",
		download: "Λήψη",
		play_now: "Έναρξη",
		cont: "Συνέχεια",
		cancel: "Ακύρωση",
		done: "Τέλος",
		later: "Αργότερα",
		close: "Κλείσιμο",
		ok: "ΟΚ",
		delete_activity: "Διαγραφή άσκησης;",
		do_you_want_to_delete: "Επιθυμείτε να διαγράψετε;",
		delete: "Διαγραφή",
		play: "Παιχνίδι",
		error: "Λάθος",
		loading: "Φόρτωση",
		confirm: "Επιβεβαίωση",
		downloading: "Λήψη",
		launch: "Έναρξη",
		enter_you_name: "Επιλέξτε όνομα",
		enter_name_hint: "Όνομα χρήστη",
		ask_name_description: "Το όνομα χρήστη θα χρησιμοποιείται για να προσδιορίζεστε στην άσκηση",
		password: "Κωδικός Πρόσβασης",
		enter_activity_password: "Παρακαλώ εισάγετε τον κωδικό πρόσβασης για την άσκηση",
		licensee_password: "Παρακαλώ εισάγετε τον κωδικό πρόσβασης για τoν server",
		incorrect_password: "Ο κωδικός πρόσβασης είναι λάθος",
		enter_password_hint: "Ο δικός σας Κωδικός Πρόσβασης",
		activity_search: "Αναζήτηση άσκησης",
		qr_code_not_recognised: "Ο QR κωδικός δεν αναγνωρίζεται",
		scan_qr_code: "Σαρώστε τον QR κωδικό",
		activity_not_found: "Η άσκηση δεν βρέθηκε",
		do_you_want_to_go_home: "Είστε σίγουροι ότι θέλτε να βγείτε από την άσκηση και να επιστρέψετε στην κεντρική οθόνη;",
		you_score_point_key: "Λάβατε %s πόντους και %s μάρκες επειδή φτάσατε σε αυτό το σημείο",
		award_point_arrival: "Λάβατε %s πόντους επειδή φτάσατε σε αυτό το σημείο",
		award_key_arrival: "Λάβατε %s μάρκες επειδή φτάσατε σε αυτό το σημείο",
		the_start_area: "Ζώνη έναρξης",
		the_finish_area: "Ζώνη λήξης",
		forbidden_area: "Απαγορευμένη Ζώνη",
		choose_a_way: "Επιλέξτε διαδρομή",
		you_enter_forbidden_area: "Βρίσκεστε σε απαγορευμένη ζώνη",
		please_move_to_activity_zone: "Κινηθείτε στην ζώνη έναρξης",
		next_check_point_distance: "Το επόμενο σημείο ελέγχου βρίσκεται %s από εδώ",
		finish_zone_distance: "Η ζώνη λήξης βρίσκεται %s από εδώ",
		settings: "Ρυθμίσεις",
		meters_feet: "Metric - Imperial",
		sounds: "Ήχοι",
		language: "Γλώσσα",
		key_required: "Δεν έχετε τη σωστή μάρκα για να ξεκινήσετε αυτό το σημείο ελέγχου, το σημείο αυτό χρειάζεται την %s μάρκα",
		set_destination: "Εισάγετε προορισμό",
		home: "Αρχική σελίδα",
		chat: "Συνομιλία",
		you_score_point: "Σκοράρατε %s πόντους",
		you_score_point_token: "Σκοράρατε %s πόντους και λάβατε %s μάρκες",
		controller_score: "Σκοράρατε %s πόντους από τον ελεγκτή για το %s σημείο ελέγχου",
		controller_key: "Λάβατε %s μάρκα από τον ελεγκτή",
		award_key_route: "Λάβατε %s μάρκα επειδή διαλέξατε αυτή τη διαδρομή",
		award_point_route: "Λάβατε %s πόντους επειδή διαλέξατε αυτή τη διαδρομή",
		award_point_key_route: "Λάβατε %s πόντους και %s μάρκες επειδή διαλέξατε αυτή τη διαδρομή",
		task: "Εργασία",
		back: "Πίσω",
		text_answer: "Απάντηση Κειμένου",
		numeric_answer: "Αριθμητική απάντηση",
		pass: "Πάσο",
		submit: "Υποβολή",
		take_photo: "Ανεβάστε μια εικόνα",
		choose_photo: "Επιλογή φωτογραφίας",
		record_video: "Βίντεο",
		play_video: "Αναπαραγωγή βίντεο",
		choose_video: "Φόρτωση Βίντεο",
		person_at_checkpoint: "Παραδώστε το tablet στον υπεύθυνο",
		enter_password: "Εισάγετε κωδικό πρόσβασης",
		enter_score: "Εισάγετε βαθμολογία / %s",
		player_answer: "Απάντηση Παίχτη",
		pass_task_confirm: "Δεν θα λάβετε κανέναν πόντο αν πάτε πάσο. Επιβεβαιώστε ότι θέλετε να πάτε πάσο",
		invalid_score: "Η βαθμολογία σας δεν είναι έγκυρη",
		submit_answer_alert: "Η απάντησή σας έχει καταχωρηθεί προς έλεγχο. Σύντομα θα λάβετε την βαθμολογία σας",
		ranking: "Κατάταξη",
		name: "Όνομα",
		score: "Χρόνος",
		time: "Βαθμολογία",
		rank: "Βαθμός",
		end_zone_distance: "Ζώνη Λήξης %s",
		start_zone_distance: "Ζώνη έναρξης %s",
		next_checkpoint_distance: "Επόμενο σημείο ελέγχου %s",
		send: "Αποστολή",
		enter_message: "Νέο Μήνυμα",
		today: "Σήμερα",
		notification: "ΕΙΔΟΠΟΙΗΣΗ",
		finish_activity_alert: "Συγχαρητήρια, ολοκληρώσατε την άσκηση",
		finish_activity: "Ολοκλήρωση άσκησης",
		partial_correct_answer: "Μερικώς σωστή απάντηση",
		correct_answer: "Σωστή απάντηση",
		incorrect_answer: "Λάθος απάντηση",
		qr_code_not_supported: "QR κωδικός σαρρωτή δεν υποστηρίζεται από την συγκεκριμένη συσκευή",
		camera_not_supported: "Η κάμερα δεν υποστηρίζεται από την συγκεκριμένη συσκευή",
		photo_not_supported: "Η βιβλιοθήκη φωτογραφιών δεν υποστηρίζεται από την συγκεκριμένη συσκευή",
		gps_not_available: "Αυτή είναι μια άσκηση με GPS. Ενεργοποιήστε τις ρυθμίσεις τοποθεσίας και προσπαθήστε ξανά",
		unknown_error: "Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά ή επικοινωνήστε με τον υπεύθυνο",
		no_internet: "Παρακαλώ ελέγξτε την σύνδεση σας",
		no_available_route: "Δεν έχετε τα προαπαιτούμενα για οποιαδήποτε διαδρομή προς το επόμενο σημείο ελέγχου. Έχετε επιστρέψει στον χάρτη.",
		scan: "Σάρωση",
		magnetic_sensor_not_found: "Ο μαγνητικός αισθητήρας δεν μπορεί να βρεθεί στη συσκευή. Η πυξίδα δεν θα λειτουργήσει.",
		on_demand_task: "Ζητούμενες εργασίες",
		company_questions: "Ερωτήσεις εταιρίας",
		photo_challenges: "Προκλήσεις φωτογραφίας",
		general_challenges: "Γενικές προκλήσεις",
		new_check_point_found: "Μόλις φτάσατε στο σημείο ελέγχου %s, θέλετε να ξεκινήσετε το σημείο ελέγχου %s ή να ακυρώσετε και να συνεχίσετε με το σημείο ελέγχου %s;",
		warning_no_photo: "Δεν έχετε τραβήξει φωτογραφία. Είστε σίγουροι ότι θέλετε να πατήσετε υποβολή χωρίς φωτογραφία;",
		warning_no_video: "Δεν έχετε τραβήξει βίντεο. Είστε σίγουροι ότι θέλετε να πατήσετε υποβολή χωρίς βίντεο;",
		return_to_task: "Επιστροφή στην εργασία",
		you_enter_start_area: "Φτάσατε στην περιοχή έναρξης!",
		activity_ended: "Τέλος άσκησης",
		controller_push_score: "Λάβατε %s πόντους από τον ελεγκτή για την %s εργασία",
		ask_require_key_title: "Ξεκινώντας αυτό το σημείο ελέγχου θα καταναλώσετε",
		ongoing: "Τρέχων",
		available: "Διαθέσιμο",
		finished: "Ολοκληρωμένο",
		change_server: "Αλλαγή server",
		tokens: "Μάρκες",
		activity_chat: "Μηνύματα Δραστηριοτήτων",
		message: "Κέντρο Μηνυμάτων",
		controller_chat: "Μηνύματα Ελεγκτή",
		one_to_one_team_chat: "Προσωπικά Μηνύματα",
		add_team: "Προσωπικό Μήνυμα",
		move_in_the_activity_boundary: "Κατευθυνθείτε εντός συνόρων της δραστηριότητας",
		correct_feedback: "Εξαιρετικά!",
		partial_correct_feedback: "Καλή δουλειά!",
		feedback_wrong: "Καλύτερη τύχη την επόμενη φορά!",
		max_attempts_try_one: "Η απάντησή σας δεν είναι σωστή. Σας απομένει %s προσπάθεια ακόμα",
		max_attempts_try_other: "Η απάντησή σας δεν είναι σωστή. Σας απομένουν %s προσπάθειες ακόμα",
		download_limit_reached: "Λήψη του ορίου λήψης",
		timer_run_out: "Το χρονόμετρο τελειώνει",
		controller: "Ελεγκτής",
		search: "Αναζήτηση",
		welcome_to: "Καλωσήλθατε στη δραστηριότητα %s",
		your_name: "Το Όνομά σας",
		please_input_your_name: "Παρακαλούμε εισάγετε το όνομά σας",
		please_take_photo: "Παρακαλούμε τραβήξτε φωτογραφία για το avatar σας",
		team_name: "Όνομα Ομάδας",
		input_team_description: "Με την ομάδα σας, επιλέξτε ένα όνομα ομάδας και ποιος θα είναι ο αρχηγός της ομάδας.",
		are_you_captain: "Είστε ο/η αρχηγός της ομάδας;",
		please_input_team_name: "Παρακαλούμε εισάγετε το όνομα της ομάδας.",
		waiting_players: "Περιμένετε όλους τους παίχτες να εισέλθουν.",
		lobby: "Αίθουσα αναμονής",
		ready: "Έτοιμοι",
		captain: "Αρχηγός",
		please_wait: "Παρακαλώ Περιμένετε…",
		refresh_page: "Ανανέωση σελίδας",
		show_other_players: "Εμφάνιση άλλων παικτών",
		warning: "Προειδοποίηση",
		finish_activity_confirm: "Δεν θα μπορείτε να επιστρέψετε στη δραστηριότητα. Είστε βέβαιοι ότι θέλετε να τερματίσετε αυτήν τη δραστηριότητα;",
		end: "Τέλος",
		return_to_activity: "Επιστροφή στη δραστηριότητα",
		paste_from_clipboard: "Επικόλληση λήψης οθόνης",
		team_chat: "Ομαδικά μηνύματα"
	},
	"il": {
		go_team: "Go Team",
		north: "צפון",
		north_east: "צפון מזרח",
		east: "מזרח",
		south_east: "דרום מזרח",
		south: "דרום",
		south_west: "דרום מערב",
		west: "מערב",
		north_west: "צפון מערב",
		ongoing_activities: "פעילויות בביצוע",
		downloaded_activities: "פעילויות שהורדו",
		finished_activities: "פעילויות שהושלמו",
		download: "הורד",
		play_now: "התחל",
		cont: "המשך",
		cancel: "בטל",
		done: "סיים",
		later: "מאוחר יותר",
		close: "סגור",
		ok: "OK",
		delete_activity: "למחוק את הפעילות?",
		do_you_want_to_delete: "האם ברצונכם למחוק?",
		delete: "מחק",
		play: "שחק",
		error: "שגיאה",
		loading: "טוען",
		confirm: "אישור",
		downloading: "מוריד",
		launch: "הפעל",
		enter_you_name: "בחרו שם",
		enter_name_hint: "שם המשתמש שלכם",
		ask_name_description: "שם המשתמש שלכם ישמש לזיהויכם בפעילות",
		password: "סיסמה",
		enter_activity_password: "אנא הכניסו את הסיסמה לפעילות זו",
		licensee_password: "אנא הכניסו את הסיסמה לשרת זה",
		incorrect_password: "הסיסמה שגויה",
		enter_password_hint: "סיסמתכם",
		activity_search: "חיפוש פעילות",
		qr_code_not_recognised: "קוד QR לא מזוהה",
		scan_qr_code: "סרקו את קוד ה-QR",
		activity_not_found: "לא נמצאה פעילות",
		do_you_want_to_go_home: "האם אתם בטוחים שברצונכם לצאת מהפעילות ולשוב למסך הראשי?",
		you_score_point_key: "קיבלתם %s נקודות ואסימון %s על ההגעה לנקודת ביקורת זו",
		award_point_arrival: "קיבלתם %s נקודות על ההגעה לנקודת ביקורת זו",
		award_key_arrival: "קיבלתם אסימון %s על ההגעה לנקודת ביקורת זו",
		the_start_area: "תחום ההתחלה",
		the_finish_area: "תחום הסיום",
		forbidden_area: "תחום אסור",
		choose_a_way: "בחרו דרך",
		you_enter_forbidden_area: "נכנסתם לתחום אסור",
		please_move_to_activity_zone: "עיברו לתחום ההתחלה",
		next_check_point_distance: "נקודת הביקורת הבאה ממוקמת %s מפה",
		finish_zone_distance: "תחום הסיום ממוקם %s מפה",
		settings: "הגדרות",
		meters_feet: "מטרית - אימפריאלית",
		sounds: "צלילים",
		language: "שפה",
		key_required: "אין ברשותכם אסימון מתאים להפעלת נקודת ביקורת זו, לנקודת ביקורת זו נדרש אסימון %s",
		set_destination: "קבע יעד",
		home: "עמוד הבית",
		chat: "צ'אט",
		you_score_point: "קיבלתם %s נקודות",
		you_score_point_token: "קיבלתם %s נקודות ואסימון %s",
		controller_score: "קיבלתם %s נקודות מהפקח בנקודות ביקורת %s",
		controller_key: "קיבלתם אסימון %s מהפקח",
		award_key_route: "קיבלתם אסימון %s על בחירת מסלול זה",
		award_point_route: "קיבלתם %s נקודות על בחירת מסלול זה",
		award_point_key_route: "קיבלתם %s נקודות ואסימון %s על בחירת מסלול זה",
		task: "משימה",
		back: "חזרה",
		text_answer: "תשובה טקסטואלית",
		numeric_answer: "תשובה מספרית",
		pass: "עבור",
		submit: "שלח",
		take_photo: "העלה תמונה",
		choose_photo: "בחר תמונה",
		record_video: "צלם סרטון",
		play_video: "הצג סרטון",
		choose_video: "העלה סרטון",
		person_at_checkpoint: "העבירו את הטאבלט לצוות האירוע",
		enter_password: "הכנס סיסמה",
		enter_score: "הכנס תוצאה / %s",
		player_answer: "תשובת השחקן",
		pass_task_confirm: "לא תקבלו ניקוד על המעבר. אשרו את רצונכם לעבור.",
		invalid_score: "ניקוד לא תקף",
		submit_answer_alert: "תשובתכם נשלחה לבדיקה, הניקוד יוענק בהתאם לביצוע המשימה.",
		ranking: "דרוג",
		name: "שם",
		score: "זמן",
		time: "ניקוד",
		rank: "דרוג",
		end_zone_distance: "תחום סופי %s",
		start_zone_distance: "תחום התחלתי %s",
		next_checkpoint_distance: "נקודת הביקורת הבאה %s",
		send: "שלח",
		enter_message: "הודעה חדשה",
		today: "היום",
		notification: "הודעה",
		finish_activity_alert: "כל הכבוד, סיימתם את הפעילות הזו",
		finish_activity: "סיימו פעילות",
		partial_correct_answer: "תשובה נכונה חלקית",
		correct_answer: "תשובה נכונה",
		incorrect_answer: "תשובה לא נכונה",
		qr_code_not_supported: "סורק קוד QR אינו נתמך במכשיר הנוכחי",
		camera_not_supported: "המצלמה אינה נתמכת במכשיר הנוכחי",
		photo_not_supported: "ספריית תמונות אינה נתמכת במכשיר הנוכחי",
		gps_not_available: "זוהי פעילות GPS. הפעילו את שירותי המיקום ונסו שנית",
		unknown_error: "משהו השתבש. נסו שוב או צרו קשר עם צוות האירוע לתמיכה",
		no_internet: "בדקו בבקשה את חיבור האינטרנט שלכם",
		no_available_route: "אין לך את הנדרש לאף אחד מהמסלולים לנקודת הביקורת הבאה, לכן הוחזרת למפה",
		scan: "סרוק",
		magnetic_sensor_not_found: "לא נמצא חיישן מגנטי על מכשירך. המצפן לא יעבוד.",
		on_demand_task: "משימות לפי דרישה",
		company_questions: "שאלות חברה",
		photo_challenges: "אתגרי תמונות",
		general_challenges: "אתגרים כלליים",
		new_check_point_found: "מצאתם את משימה %s האם תרצו להפעיל את המשימה %s או לבטל ולהמשיך למשימה %s",
		activity_ended: "המשחק הסתיים",
		ask_require_key_title: "הפעלת המשימה דורשת",
		available: "זמינים",
		change_server: "שינוי שרת",
		controller_push_score: "זכית ב %s נקודות עבור משימה %s",
		finished: "הסתיימו",
		ongoing: "מתמשכים",
		return_to_task: "חזור למשימות",
		warning_no_photo: "לא צולמה תמונה, האם לאשר ללא תמונה?",
		warning_no_video: "לא צילמת סרטון, האם לאשר ללא הסרטון?",
		you_enter_start_area: "הגעת לנקודת הפתיחה",
		tokens: "אסימונים",
		activity_chat: "הודעת פעילות",
		message: "הודעות",
		controller_chat: "הודעת קונטרולר",
		one_to_one_team_chat: "הודעות ישירות",
		add_team: "הודעה ישירה",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "התשובה אינה נכונה. נשאר לכם נסיונות %s",
		max_attempts_try_other: "התשובה נכונה. נשאר לכם נסיונות %s",
		download_limit_reached: "הגבלת ההורדה הגיעה",
		timer_run_out: "נגמר הטיימר",
		controller: "Controller",
		search: "Search",
		welcome_to: "ברוכים הבאים ל %s",
		your_name: "השם שלך",
		please_input_your_name: "הכנס את שמך",
		please_take_photo: "צלם את האווטר שלך",
		team_name: "שם הצוות",
		input_team_description: "בשיתוף הצוות, בחרו שם לצוות והחליטו מיהו הקפטן",
		are_you_captain: "האם אתה הקפטן",
		please_input_team_name: "אנא הכנס את שמך",
		waiting_players: "ממתינים",
		lobby: "לובי",
		ready: "מוכנים",
		captain: "קפטן",
		please_wait: "אנא המתן…",
		refresh_page: "רענן עמוד",
		show_other_players: "הצג שחקנים אחרים",
		warning: "אַזהָרָה",
		finish_activity_confirm: "לא תוכל לחזור לפעילות. האם אתה בטוח שברצונך לסיים את הפעילות הזו?",
		end: "סוֹף",
		return_to_activity: "חזור לפעילות",
		paste_from_clipboard: "הדבק צילום מסך",
		team_chat: "הודעות צוות"
	},
	"id": {
		go_team: "Go Team",
		north: "Utara",
		north_east: "Timur Laut", 
		east: "Timur",
		south_east: "Tenggara",
		south: "Selatan",
		south_west: "Barat Daya",
		west: "Barat",
		north_west: "Barat Laut",
		ongoing_activities: "Aktivitas sedang Berlangsung",
		downloaded_activities: "Aktivitas Terunduh",
		finished_activities: "Aktivitas Selesai",
		download: "Unduh",
		play_now: "Mulai",
		cont: "Lanjut",
		cancel: "Batal",
		done: "Selesai",
		later: "Nanti",
		close: "Tutup",
		ok: "OK",
		delete_activity: "Hapus Aktivitas?",
		do_you_want_to_delete: "Apakah Anda ingin menghapus?",
		delete: "Hapus",
		play: "Main",
		error: "Error",
		loading: "Loading",
		confirm: "Konfirmasi",
		downloading: "Mengunduh",
		launch: "Meluncurkan",
		enter_you_name: "Pilih nama",
		enter_name_hint: "Nama Pengguna",
		ask_name_description: "Nama Pengguna akan digunakan untuk mengidentifikasi Anda dalam aktivitas",
		password: "Password",
		enter_activity_password: "Masukkan Password untuk aktivitas ini",
		licensee_password: "Masukkan Password untuk server ini",
		incorrect_password: "Password salah",
		enter_password_hint: "Password Anda",
		activity_search: "Pencarian Aktivitas",
		qr_code_not_recognised: "Kode QR tidak dikenali",
		scan_qr_code: "Scan kode QR",
		activity_not_found: "Aktivitas tidak ditemukan",
		do_you_want_to_go_home: "Anda yakin ingin keluar dari aktivitas dan kembali ke layar utama?",
		you_score_point_key: "Anda menerima %s poin dan %s token untuk mencapai titik ini",
		award_point_arrival: "Anda menerima %s poin untuk mencapai titik ini",
		award_key_arrival: "Anda menerima %s token untuk mencapai titik ini",
		the_start_area: "Zona awal",
		the_finish_area: "Zona akhir",
		forbidden_area: "Zona terlarang",
		choose_a_way: "Pilih Jalan",
		you_enter_forbidden_area: "Anda memasuki zona terlarang",
		please_move_to_activity_zone: "Pindah ke zona awal",
		next_check_point_distance: "Titik  berikutnya terletak %s dari sini",
		finish_zone_distance: "Zona akhir terletak %s dari sini",
		settings: "Pengaturan",
		meters_feet: "Metrik - Imperial",
		sounds: "Suara",
		language: "Bahasa",
		key_required: "Anda tidak memiliki token yang benar untuk meluncurkan titik ini, titik ini memerlukan token %s",
		set_destination: "Tetapkan tujuan",
		home: "Layar Utama",
		chat: "Chat",
		you_score_point: "Anda memperoleh %s poin",
		you_score_point_token: "Anda mendapatkan %s poin dan Anda menerima %s token",
		controller_score: "Anda memperoleh %s poin dari pengontrol untuk %s titik ini",
		controller_key: "Anda menerima %s token dari pengontrol",
		award_key_route: "Anda menerima %s token untuk mengambil rute ini",
		award_point_route: "Anda menerima %s poin untuk mengambil rute ini",
		award_point_key_route: "Anda menerima %s poin dan %s token untuk mengambil rute ini",
		task: "Tugas",
		back: "Kembali",
		text_answer: "Jawaban",
		numeric_answer: "Jawaban Angka",
		pass: "Lewati",
		submit: "Kirim",
		take_photo: "Unggah gambar",
		choose_photo: "Pilih Foto",
		record_video: "Rekam Video",
		play_video: "Mainkan Video",
		choose_video: "Unggah video",
		person_at_checkpoint: "Berikan perangkat kepada staf acara",
		enter_password: "Masukkan Password",
		enter_score: "Masukkan nilai / %s",
		player_answer: "Jawaban Pemain",
		pass_task_confirm: "Anda tidak akan menerima poin apa pun jika melewati bagian ini. Konfirmasikan bahwa Anda ingin melewati",
		invalid_score: "Nilai tidak valid",
		submit_answer_alert: "Jawaban Anda telah dikirim untuk ditinjau",
		ranking: "Peringkat",
		name: "Nama",
		time: "Waktu",
		score: "Nilai",
		rank: "Peringkat",
		end_zone_distance: "Zona akhir %s",
		start_zone_distance: "Zona awal %s",
		next_checkpoint_distance: "Titik berikutnya %s",
		send: "Kirim",
		enter_message: "Pesan baru",
		today: "Hari ini",
		finish_activity_alert: "Selamat, Anda telah menyelesaikan aktivitas ini",
		finish_activity: "Aktivitas Selesai",
		partial_correct_answer: "Jawaban sebagian benar",
		correct_answer: "Jawaban benar",
		incorrect_answer: "Jawaban salah",
		notification: "Notifikasi",
		qr_code_not_supported: "Scanner Kode QR tidak didukung oleh perangkat saat ini",
		camera_not_supported: "Kamera tidak didukung oleh perangkat saat ini",
		photo_not_supported: "Photo library tidak didukung oleh perangkat saat ini",
		gps_not_available: "Ini adalah aktivitas menggunakan GPS. Aktifkan layanan lokasi dan coba lagi",
		unknown_error: "Ada yang salah. Silakan coba lagi atau hubungi tim acara untuk mendapatkan bantuan",
		no_internet: "Cek koneksi internet anda",
		no_available_route: "Anda tidak memiliki persyaratan untuk salah satu rute ke titik berikutnya, jadi Anda telah dikembalikan ke peta",
		scan: "Scan",
		magnetic_sensor_not_found: "Sensor magnet tidak dapat ditemukan di perangkat Anda. Kompas tidak akan berfungsi.",
		on_demand_task: "Tugas Sesuai Permintaan",
		company_questions: "Pertanyaan Perusahaan",
		photo_challenges: "Tantangan Foto",
		general_challenges: "Tantangan Umum",
		new_check_point_found: "Anda baru saja menemukan titik %s, apakah Anda ingin MELUNCURKAN titik %s atau BATAL dan lanjutkan ke titk %s?",
		warning_no_photo: "Anda belum mengambil foto, apakah Anda yakin ingin mengirim tanpa foto?",
		warning_no_video: "Anda belum merekam video, apakah Anda yakin ingin mengirim tanpa video?",
		return_to_task: "Kembali ke tugas",
		you_enter_start_area: "Anda telah mencapai area awal!",
		activity_ended: "Aktivitas berakhir!",
		controller_push_score: "Anda telah diberikan %s poin oleh pengontrol untuk tugas %s.",
		ask_require_key_title: "Meluncurkan titik ini akan menghabiskan",
		ongoing: "Sedang Berlangsung",
		available: "Tersedia",
		finished: "Berakhir",
		change_server: "Ganti Server",
		timer_run_out: "Waktu telah habis",
		tokens: "Tokens",
		correct_feedback: "Luar biasa!",
		partial_correct_feedback: "Kerja yang bagus!",
		feedback_wrong: "Semoga lain kali lebih beruntung!",
		message: "Pesan",
		controller_chat: "Pesan pengontrol",
		activity_chat: "Pesan Aktivitas",
		one_to_one_team_chat: "Pesan langsung",
		add_team: "Pesan langsung",
		max_attempts_try_one: "Jawaban Anda salah. Anda memiliki %s kesempatan lagi",
		max_attempts_try_other: "Jawaban Anda salah. Anda memiliki %s kesempatan lagi",
		move_in_the_activity_boundary: "Pindah di batas aktivitas",
		download_limit_reached: "Batas unduhan telah tercapai",
		controller: "Pengontrol",
		search: "Pencarian",
		welcome_to: "Selamat datang di %s",
		your_name: "Nama Anda",
		please_input_your_name: "Silakan masukkan nama Anda.",
		please_take_photo: "Silakan ambil foto untuk avatar Anda.",
		team_name: "Nama Tim",
		input_team_description: "Bersama tim Anda, tentukan nama tim dan pilih siapa yang akan menjadi kapten tim.",
		are_you_captain: "Apakah Anda kapten tim?",
		please_input_team_name: "Silakan masukkan nama tim Anda.",
		waiting_players: "Menunggu semua pemain untuk bergabung.",
		lobby: "Lobi",
		ready: "Siap",
		captain: "Kapten",
		please_wait: "Mohon tunggu…",
		refresh_page: "Segarkan Halaman",
		show_other_players: "Tampilkan pemain lain",
		warning: "Peringatan",
		finish_activity_confirm: "Anda tidak akan dapat kembali ke aktivitas.<br /> Anda yakin ingin mengakhiri aktivitas ini?",
		end: "Akhiri",
		return_to_activity: "Kembali ke Aktivitas",
		paste_from_clipboard: "Tempel Tangkapan Layar",
		team_chat: "Pesan tim"
	},
	"it": {
		go_team: "Go Team",
		north: "Nord",
		north_east: "Nord Est",
		east: "Est",
		south_east: "Sud Est",
		south: "Sud",
		south_west: "Sud Ovest",
		west: "Ovest",
		north_west: "Nord Ovest",
		ongoing_activities: "Attivita' in corso",
		downloaded_activities: "Attività Scaricate",
		finished_activities: "Attivita' terminate",
		download: "Scarica",
		play_now: "Inizia",
		cont: "Continua",
		cancel: "Cancella",
		done: "Fatto",
		later: "Dopo",
		close: "Chiudi",
		ok: "OK",
		delete_activity: "Elimina attivita'?",
		do_you_want_to_delete: "Vuoi eliminare?",
		delete: "Elimina",
		play: "Avvia",
		error: "Errore",
		loading: "Caricamento",
		confirm: "Conferma",
		downloading: "Scaricamento",
		launch: "Apri",
		enter_you_name: "Scegli un nome",
		enter_name_hint: "Nome Utente",
		ask_name_description: "Il tuo nome utente sara' usato per identificarti durante l'attivita'",
		password: "Password",
		enter_activity_password: "Inserisci la tua password per questa attivita'",
		licensee_password: "Inserisci la tua password per questo server",
		incorrect_password: "La password non è corretta",
		enter_password_hint: "La tua password",
		activity_search: "Cerca attivita'",
		qr_code_not_recognised: "Codice QR non riconosciuto",
		scan_qr_code: "Analizza il codice QR",
		activity_not_found: "Attivita' non trovata",
		do_you_want_to_go_home: "Si sicuro di voler uscire dall'attivita' e tornare alla schermata principale?",
		you_score_point_key: "Hai ricevuto %s punti e %s simboli per aver raggiunto questo obiettivo",
		award_point_arrival: "Hai ricevuto %s punti per aver raggiunto questo obiettivo",
		award_key_arrival: "Hai ricevuto %s simboli per aver raggiunto questo obiettivo",
		the_start_area: "zona di inizio",
		the_finish_area: "zona di fine",
		forbidden_area: "zona riservata",
		choose_a_way: "scegli una strada",
		you_enter_forbidden_area: "sei entrato in una zona riservata",
		please_move_to_activity_zone: "Spostati alla zona di inizio",
		next_check_point_distance: "Il prossimo obiettivo è posizionato %s da qui",
		finish_zone_distance: "La zona di fine è posizionata %s da qui",
		settings: "Impostazioni",
		meters_feet: "Metrico-imperiale",
		sounds: "Suoni",
		language: "Lingua",
		key_required: "Non hai il simbolo adatto per raggiungere questo obiettivo, l'obiettivo richiede il simbolo %s",
		set_destination: "Destinazione scelta",
		home: "Pagina principale",
		chat: "Chat",
		you_score_point: "Hai ottenuto %s punti",
		you_score_point_token: "Hai ottenuto %s punti e ricevuto %s simboli",
		controller_score: "Hai ottenuto %s punti dal regolatore per %s obiettivi",
		controller_key: "Hai ricevuto %s simboli dal regolatore",
		award_key_route: "Hai ricevuto %s simboli per aver scelto questa strada",
		award_point_route: "Hai ricevuto %s punti per aver scelto questa strada",
		award_point_key_route: "Hai ricevuto %s punti e %s simboli per aver scelto questa strada",
		task: "Mansione",
		back: "Indietro",
		text_answer: "Digita la risposta",
		numeric_answer: "Risposta numerica",
		pass: "Prosegui",
		submit: "Invia",
		take_photo: "Carica un'immagine",
		choose_photo: "Scegli una foto",
		record_video: "Registra un video",
		play_video: "Riproduci video",
		choose_video: "Carica video",
		person_at_checkpoint: "Dai il tablet allo staff evento",
		enter_password: "Inserisci la password",
		enter_score: "Inserisci punteggio / %s",
		player_answer: "Riproduttore risposte",
		pass_task_confirm: "Non riceverai nessun punto se prosegui. Conferma di voler proseguire.",
		invalid_score: "Punteggio non valido",
		submit_answer_alert: "La tua riposta è stata inviata e verra' revisionata. Riceverai presto un punteggio.",
		ranking: "Classifica",
		name: "Nome",
		score: "Tempo",
		time: "Punteggio",
		rank: "Posizione",
		end_zone_distance: "Zona di inizio %s",
		start_zone_distance: "Zona di fine %s",
		next_checkpoint_distance: "Prossimo obiettivo %s",
		send: "Invia",
		enter_message: "Nuovo messaggio",
		today: "oggi",
		notification: "NOTIFICHE",
		finish_activity_alert: "Congratulazioni, hai terminato questa attivita'",
		finish_activity: "Termina attivita'",
		partial_correct_answer: "Risposta in parte corretta",
		correct_answer: "Risposta corretta",
		incorrect_answer: "Risposta incorretta",
		qr_code_not_supported: "Lo scanner del codice QR non è supportato da questo dispositivo",
		camera_not_supported: "La macchina fotografica non è supportata da questo dispositivo",
		photo_not_supported: "La libreria foto non è supportata da questo dispositivo",
		gps_not_available: "Questa è un' attivita' GPS. Attiva le funzioni di localizzazione e prova di nuovo",
		unknown_error: "Qualcosa non ha funzionato. Si prega di riprovare o contattare il team evento per assistenza.",
		no_internet: "Controlla la tua connessione internet",
		no_available_route: "Non hai i requisiti per le seguenti strade per il prossimo obiettivo, quindi sei stato reindirizzato alla mappa",
		scan: "scansiona",
		magnetic_sensor_not_found: "il sensore magnetico non è stato rilevato sul tuo dispositivo",
		on_demand_task: "missioni on demand",
		company_questions: "domande per la compagnia",
		photo_challenges: "sfide con foto",
		general_challenges: "sfide generali",
		new_check_point_found: "Hai appena trovato l'obiettivo %s, vuoi AVVIARE l'obiettivo %s o CANCELLARE e continuare verso l'obiettivo %s?",
		activity_ended: "Attivita' terminata",
		ask_require_key_title: "Iniziare questo obiettivo usera'",
		available: "Disponibile",
		change_server: "Cambiare il Server",
		controller_push_score: "Il controllore ti ha dato %s punti per %s la mansione.",
		finished: "Finito",
		ongoing: "In corso",
		return_to_task: "Ritorna alla mansione",
		warning_no_photo: "Non hai scattato alcuna foto. Sei sicuro di voler inviare senza foto?",
		warning_no_video: "Non hai registrato alcun video, sei sicuro di voler inviare senza video?",
		you_enter_start_area: "Hai raggiunto l'area di inizio!",
		tokens: "Simboli",
		activity_chat: "Messaggi sulle attività",
		message: "Messaggi",
		controller_chat: "Messaggi dal responsabile",
		one_to_one_team_chat: "Messaggi diretti",
		add_team: "Spedisci un messaggio diretto",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Ottimo",
		partial_correct_feedback: "Bel lavoro",
		feedback_wrong: "Andrà meglio la prossima volta",
		max_attempts_try_one: "La tua risposta non è corretta. Vi rimane %s tentativo",
		max_attempts_try_other: "La tua risposta non è corretta. Vi rimangono %s tentativi",
		download_limit_reached: "Limite di download raggiunto",
		timer_run_out: "Il timer si esaurisce",
		controller: "Controller",
		search: "Search",
		welcome_to: "Benvenuti a %s",
		your_name: "Nominativo",
		please_input_your_name: "Per favore inserisci il tuo nome.",
		please_take_photo: "Per favore scatta una foto profilo.",
		team_name: "Nome squadra",
		input_team_description: "Con i tuoi compagni, scegliete un nome per la vostra squadra e nominate un capitano.",
		are_you_captain: "Sei tu il capitano?",
		please_input_team_name: "Per favore inserisci il nome della squadra.",
		waiting_players: "In attesa che tutti i partecipanti siano pronti.",
		lobby: "Lobby",
		ready: "Pronti",
		captain: "Capitano",
		please_wait: "Per favore attendere",
		refresh_page: "Aggiorna pagina",
		show_other_players: "Mostra altri giocatori",
		warning: "Avvertimento",
		finish_activity_confirm: "Non potrai tornare all'attività. Sei sicuro di voler terminare questa attività?",
		end: "Fine",
		return_to_activity: "Torna all'attività",
		paste_from_clipboard: "Incolla screenshot",
		team_chat: "Messaggi di squadra"
	},
	"jp": {
		go_team: "Go Team",
		north: "北",
		north_east: "北東",
		east: "東",
		south_east: "南東",
		south: "南",
		south_west: "南西",
		west: "西",
		north_west: "北西",
		ongoing_activities: "進行中のアクティビティ",
		downloaded_activities: "ダウンロードしたアクティビティ",
		finished_activities: "終了したアクティビティ",
		download: "ダウンロード",
		play_now: "スタート",
		cont: "続ける",
		cancel: "キャンセル",
		done: "完了",
		later: "後で",
		close: "閉じる",
		ok: "OK",
		delete_activity: "アクティビティを削除しますか？",
		do_you_want_to_delete: "削除しますか？",
		delete: "削除",
		play: "遊ぶ",
		error: "エラー",
		loading: "ローディング・ダウンロード中",
		confirm: "確定",
		downloading: "ダウンロードする",
		launch: "立ち上げ",
		enter_you_name: "名前を選んでください",
		enter_name_hint: "あなたのユーザーネーム",
		ask_name_description: "ユーザーネームはあなたを表します",
		password: "パスワード",
		enter_activity_password: "パスワードを入力してください",
		licensee_password: "パスワードを入力してください",
		incorrect_password: "パスワードが間違っています",
		enter_password_hint: "あなたのパスワード",
		activity_search: "アクティビティの検索",
		qr_code_not_recognised: "QRコードが認識されませんでした",
		scan_qr_code: "QRコードをスキャンする（してください）",
		activity_not_found: "アクティビティが見つかりません",
		do_you_want_to_go_home: "本当にアクティビティを辞めて、ホームスクリーンに戻りますか？",
		you_score_point_key: "%s point とこの チェックポイントに向かうための %s 証を手に入れました",
		award_point_arrival: "このチェックポイントに向かうために %s points を手に入れました",
		award_key_arrival: "このチェックポイントに向かうための %s 証を手に入れました",
		the_start_area: "スタートエリア",
		the_finish_area: "ゴールエリア",
		forbidden_area: "禁止エリア",
		choose_a_way: "道を選ぶ",
		you_enter_forbidden_area: "禁止エリアに入りました",
		please_move_to_activity_zone: "スタートエリアに移動する",
		next_check_point_distance: "次のチェックポイントはここから%sのところにあります",
		finish_zone_distance: "終了エリアはここから%sのところにあります",
		settings: "設定",
		meters_feet: "メートル - 皇帝の",
		sounds: "音・音量",
		language: "言語",
		key_required: "あなたは、このチェックポイントを立ち上げるための鍵を持っていません。このチェックポイントは%sが必要となります。",
		set_destination: "進む方向を設定する",
		home: "ホーム",
		chat: "チャット",
		you_score_point: "おめでとうございます！あなたは %s ポイントを獲得しました",
		you_score_point_token: "あなたは  %s ポイントと %s 証を獲得しました",
		controller_score: "あなたは %sチェックポイント（にチャレンジする）ために、コントローラーから %s ポイント獲得しました",
		controller_key: "あなたはコントローラーから%s証を受け取りました",
		award_key_route: "あなたはこのルートを通るための%s証を受け取りました",
		award_point_route: "あなたはこのルートを通るため%sポイントを受け取りました",
		award_point_key_route: "あなたはこのルートを通るため%sポイントと%s証を受け取りました",
		task: "タスク",
		back: "戻る",
		text_answer: "記述回答",
		numeric_answer: "数値回答",
		pass: "パス",
		submit: "提出する",
		take_photo: "画像をアップロードする",
		choose_photo: "写真を選ぶ",
		record_video: "ビデオを録画する",
		play_video: "ビデオを再生する",
		choose_video: "ビデオをアップロードする",
		person_at_checkpoint: "イベントスタッフにタブレットを渡す（渡してください）",
		enter_password: "パスワードを入力する",
		enter_score: "スコアを入力する / %s",
		player_answer: "プレーヤーの答え",
		pass_task_confirm: "もしパスをした場合ポイントを獲得することができません。本当にパスしますか？",
		invalid_score: "スコアが間違っています",
		submit_answer_alert: "あなたの回答は提出されました。まもなく結果が出ます。",
		ranking: "ランキング・順位",
		name: "名前",
		score: "時間",
		time: "得点",
		rank: "ランク",
		end_zone_distance: "終了エリア %s",
		start_zone_distance: "スタートエリア  %s",
		next_checkpoint_distance: "次のチェックポイント  %s",
		send: "送る",
		enter_message: "新しいメッセージ",
		today: "今日・本日",
		notification: "お知らせ",
		finish_activity_alert: "おめでとうございます。無事アクティビティが終了しました。",
		finish_activity: "アクティビティを終了する",
		partial_correct_answer: "一部正解！",
		correct_answer: "正解！",
		incorrect_answer: "不正解！",
		qr_code_not_supported: "QRコードスキャナーは最近のデバイスに対応していません",
		camera_not_supported: "カメラは最近のデバイスに対応していません",
		photo_not_supported: "写真ライブラリーは最近のデバイスに対応していません",
		gps_not_available: "これはGPSアクティビティです。利用可能な場所に行って再度試してください",
		unknown_error: "設定が間違っています。もう一度やり直すか、イベントチームに連絡をしてください",
		no_internet: "インターネットの接続状況をチェックしてください",
		no_available_route: "次のチェックポイントに向かうための要件を満たしていません。そのためマップに戻りました。",
		scan: "スキャン",
		magnetic_sensor_not_found: "センサーがあなたのデバイスを見つけられません。そのためキャンパスが機能しません。",
		on_demand_task: "オンデマンドタスク",
		company_questions: "会社に関する問題",
		photo_challenges: "フォトチャレンジ",
		general_challenges: "通常のチャレンジ",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activity ended",
		ask_require_key_title: "現在のタスクを完了してください。",
		available: "終了",
		change_server: "アクティビティを終了しました",
		controller_push_score: "You scored %s points from the controller for %s task",
		finished: "サーバーを変える",
		ongoing: "利用可能",
		return_to_task: "スタートエリアに到着しました！",
		warning_no_photo: "動画をまだ撮っていませんがこのまま送信してよろしいですか？",
		warning_no_video: "タスクに戻る",
		you_enter_start_area: "アクティビティは終了しました。",
		tokens: "Tokens",
		activity_chat: "参加者へのメッセージ交換",
		message: "メッセージ交換",
		controller_chat: "コントローラーとのメッセージ交換",
		one_to_one_team_chat: "個別のメッセージ交換",
		add_team: "個別メッセージ",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "答えが間違っています。あと%s回挑戦できます。",
		max_attempts_try_other: "答えが間違っています。あと%s回挑戦できます。",
		download_limit_reached: "ダウンロード制限に達しました",
		timer_run_out: "タイマーが切れます",
		controller: "Controller",
		search: "Search",
		welcome_to: "%s へようこそ！",
		your_name: "名前",
		please_input_your_name: "名前を入力してください",
		please_take_photo: "アバター用の写真を撮影してください",
		team_name: "チーム名",
		input_team_description: "チームメンバーと相談し、チーム名とチームキャプテンを決めてください",
		are_you_captain: "あなたがチームキャプテンですか？",
		please_input_team_name: "チーム名を入力してください",
		waiting_players: "全員が参加するまでお待ちください",
		lobby: "ロビー",
		ready: "準備完了",
		captain: "キャプテン",
		please_wait: "お待ちください・・・",
		refresh_page: "ページの更新",
		show_other_players: "他のプレイヤーに表示",
		warning: "警告",
		finish_activity_confirm: "アクティビティに戻ることはできません。 このアクティビティを終了してもよろしいですか？",
		end: "終わり",
		return_to_activity: "アクティビティに戻る",
		paste_from_clipboard: "スクリーンショットを貼り付ける",
		team_chat: "チームメッセージング"
	},
	"lv": {
		go_team: "Go Team",
		north: "Ziemeļi",
		north_east: "Ziemeļaustrumi",
		east: "Austrumi",
		south_east: "Dienvidaustrumi",
		south: "Dienvidi",
		south_west: "Dienvidrietumi",
		west: "Rietumi",
		north_west: "Ziemeļrietumi",
		ongoing_activities: "Notiekošās aktivitātes",
		downloaded_activities: "Lejupielādēts Aktivitātes",
		finished_activities: "Pabeigtās aktivitātes",
		download: "Lejupielādēt",
		play_now: "Sākt",
		cont: "Turpināt",
		cancel: "Atcelt",
		done: "Pabeigts",
		later: "Vēlāk",
		close: "Aizvērt",
		ok: "Labi",
		delete_activity: "Izdzēst aktivitāti?",
		do_you_want_to_delete: "Vai vēlaties izdzēst?",
		delete: "Izdzēst",
		play: "Spēlēt",
		error: "Kļūda",
		loading: "Ielādē",
		confirm: "Apstiprināt",
		downloading: "lejupielādē",
		launch: "Palaist",
		enter_you_name: "Izvēlēties nosaukumu",
		enter_name_hint: "Jūsu lietotājvārds",
		ask_name_description: "Jūsu lietotājvārds tiks izmantots, lai identificētu jūs aktivitātes laikā",
		password: "Parole",
		enter_activity_password: "Lūdzu, ievadiet aktivitātes paroli",
		licensee_password: "Lūdzu, ievadiet servera paroli",
		incorrect_password: "Parole ir nepareiza",
		enter_password_hint: "Jūsu parole",
		activity_search: "Meklēt aktivitāti",
		qr_code_not_recognised: "QR kods nav atpazīts",
		scan_qr_code: "Skenēt QR kodu",
		activity_not_found: "Aktivitāte nav atrasta",
		do_you_want_to_go_home: "Vai esat pārliecināts, ka vēlaties iziet no aktivitātes un atgriezties sākuma ekrānā?",
		you_score_point_key: "Par šī kontrolpunkta sasniegšanu jūs saņēmāt %s punktus un %s žetonu",
		award_point_arrival: "Par šī kontrolpunkta sasniegšanu jūs saņēmāt %s punktus",
		award_key_arrival: "Par šī kontrolpunkta sasniegšanu jūs saņēmāt %s žetonu",
		the_start_area: "sākuma zona",
		the_finish_area: "beigu zona",
		forbidden_area: "Lieguma zona",
		choose_a_way: "Izvēlēties ceļu",
		you_enter_forbidden_area: "Jūs iegājāt lieguma zonā",
		please_move_to_activity_zone: "Dodieties uz sākuma zonu",
		next_check_point_distance: "Nākamais kontrolpunkts atrodas %s no šejienes",
		finish_zone_distance: "Beigu zona atrodas %s no šejienes",
		settings: "Iestatījumi",
		meters_feet: "Metriskā - Angļu mērvienību sistēma",
		sounds: "Skaņas",
		language: "Valoda",
		key_required: "Jums nav šī kontrolpunkta iziešanai nepieciešamais žetons, šim kontrolpunktam nepieciešams %s žetons",
		set_destination: "Iestatīt galamērķi",
		home: "Sākums",
		chat: "Tērzēšana",
		you_score_point: "Jūs ieguvāt %s punktus",
		you_score_point_token: "Jūs ieguvāt %s punktus un saņēmāt %s žetonu",
		controller_score: "Jūs ieguvāt %s punktus no kontroliera %s kontrolpunktā",
		controller_key: "Jūs no kontroliera saņēmāt %s žetonu",
		award_key_route: "Par šo maršrutu jūs saņēmāt %s žetonu",
		award_point_route: "Par šo maršrutu jūs saņēmāt %s punktus",
		award_point_key_route: "Par šo maršrutu jūs saņēmāt %s punktus un %s žetonu",
		task: "Uzdevums",
		back: "Atpakaļ",
		text_answer: "Teksta atbilde",
		numeric_answer: "Skaitļu atbilde",
		pass: "Izlaist",
		submit: "Iesniegt",
		take_photo: "Augšupielādējiet attēlu",
		choose_photo: "Izvēlēties foto",
		record_video: "Ierakstīt video",
		play_video: "Atskaņot video",
		choose_video: "Augšupielādēt video",
		person_at_checkpoint: "Iedot pasākuma personālam planšetdatoru",
		enter_password: "Ievadīt paroli",
		enter_score: "Ievadīt rezultātu / %s",
		player_answer: "Spēlētāja atbilde",
		pass_task_confirm: "Izlaižot jūs nesaņemsiet punktus. Apstipriniet, ka vēlaties to izlaist.",
		invalid_score: "Rezultāts nav derīgs",
		submit_answer_alert: "Jūsu atbilde iesniegta pārskatīšanai. Drīzumā jūs saņemsiet rezultātu.",
		ranking: "Novērtējums",
		name: "Vārds",
		score: "Laiks",
		time: "Rezultāts",
		rank: "Vieta",
		end_zone_distance: "Beigu zona %s",
		start_zone_distance: "Sākuma zona %s",
		next_checkpoint_distance: "Nākamais kontrolpunkts %s",
		send: "Nosūtīt",
		enter_message: "Jauns ziņojums",
		today: "šodien",
		notification: "PAZIŅOJUMS",
		finish_activity_alert: "Apsveicam, jūs esiet pabeiguši aktivitāti!",
		finish_activity: "Pabeigt aktivitāti",
		partial_correct_answer: "Daļēji pareiza atbilde",
		correct_answer: "Pareiza atbilde",
		incorrect_answer: "Nepareiza atbilde",
		qr_code_not_supported: "Šī ierīce neatbalsta QR koda skeneri",
		camera_not_supported: "Šī ierīce neatbalsta kameru",
		photo_not_supported: "Šī ierīce neatbalsta fotogrāfiju bibliotēku",
		gps_not_available: "Šī ir GPS aktivitāte. Aktivizējiet atrašanās vietas noteikšanas pakalpojumus un mēģiniet vēlreiz",
		unknown_error: "Radās kļūda. Lūdzu, mēģiniet vēlreiz vai sazinieties ar pasākuma komandu atbalsta saņemšanai",
		no_internet: "Lūdzu, pārbaudiet savu interneta pieslēgumu",
		no_available_route: "Jums nav izpildīti nepieciešamie kritēriji, lai dotos uz nākamajiem kontrolpunktiem, tāpēc jūs esiet atgriezti atpakaļ kartē.",
		scan: "Skenēt",
		magnetic_sensor_not_found: "Jūsu ierīcē nav atrodams magnētiskais sensors. Kompass nestrādās.",
		on_demand_task: "Uzdevumi pēc pieprasījuma",
		company_questions: "Ar uzņēmumu saistīti jautājumi",
		photo_challenges: "Foto uzdevumi",
		general_challenges: "Vispārēji uzdevumi",
		new_check_point_found: "Jūs tikko atradāt kontrolpunktu %s, vai jūs vēlaties AKTIVIZĒT kontrolpunktu %s vai arī ATCELT un turpināt ceļu uz kontrolpunktu %s?",
		activity_ended: "Aktivitāte ir beigusies!",
		ask_require_key_title: "Šī kontrolpunkta aktivizēšana patērēs",
		available: "Pieejams",
		change_server: "Change server",
		controller_push_score: "Jūs ieguvāt %s punktus no kontroliera par %s uzdevumu.",
		finished: "Pabeigts",
		ongoing: "Notiek",
		return_to_task: "Atgriezties pie uzdevuma",
		warning_no_photo: "Jūs neesat uzņēmis fotoattēlu, vai tiešām vēlaties iesniegt atbildi bez foto?",
		warning_no_video: "Jūs neesat ierakstījis video, vai tiešām vēlaties iesniegt atbildi bez video?",
		you_enter_start_area: "Jūs esiet sasniedzis sākuma zonu!",
		tokens: "Žetoni",
		activity_chat: "Aktivitātes ziņojums",
		message: "Ziņapmaiņa",
		controller_chat: "Ziņapmaiņa ar kontrolieri",
		one_to_one_team_chat: "Tiešā ziņapmaiņa",
		add_team: "Tiešā ziņa",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Jūsu atbilde ir nepareiza. Jums ir atlicis %s mēģinājums.",
		max_attempts_try_other: "Jūsu atbilde ir nepareiza. Jums ir atlikuši %s mēģinājumi.",
		download_limit_reached: "Ir sasniegts lejupielādes ierobežojums",
		timer_run_out: "Taimeris beidzas",
		controller: "Controller",
		search: "Search",
		welcome_to: "Laipni lūgti %s",
		your_name: "Jūsu vārds",
		please_input_your_name: "Lūdzu ievadiet jūsu vārdu.",
		please_take_photo: "Lūdzu izveidojiet savu profila bildi.",
		team_name: "Komandas nosaukums",
		input_team_description: "Kopā ar jūsu komandu izveidojiet komandas nosaukumu un izlemiet, kurš būs komandas kapteinis.",
		are_you_captain: "Vai jūs esat komandas kapteinis?",
		please_input_team_name: "Lūdzu ievadiet jūsu komandas nosaukumu.",
		waiting_players: "Gaidām visu spēlētāju pievienošanos.",
		lobby: "Lobijs",
		ready: "Gatavs",
		captain: "Kapteinis",
		please_wait: "Lūdzu uzgaidiet!",
		refresh_page: "Atsvaidzināt lapu",
		show_other_players: "Parādiet citus spēlētājus",
		warning: "Brīdinājums",
		finish_activity_confirm: "Jūs nevarēsit atgriezties aktivitātē. Vai tiešām vēlaties pārtraukt šo darbību?",
		end: "Beigas",
		return_to_activity: "Atgriezties aktivitātē",
		paste_from_clipboard: "Ielīmēt ekrānuzņēmumu",
		team_chat: "Komandas ziņojumapmaiņa"
	},
	"lt": {
		go_team: "Go Team",
		north: "Šiaurė",
		north_east: "Šiaurės rytai",
		east: "Rytai",
		south_east: "Pietryčiai",
		south: "Pietūs",
		south_west: "Pietvakariai",
		west: "Vakarai",
		north_west: "Šiaurės vakarai",
		ongoing_activities: "Vykstantys žaidimai",
		downloaded_activities: "Atsisiųsta Žaidimai",
		finished_activities: "Užbaigti žaidimai",
		download: "Atsisiųsti",
		play_now: "Pradėti",
		cont: "Tęsti",
		cancel: "Nutraukti",
		done: "Atlikta",
		later: "Vėliau",
		close: "Uždaryti",
		ok: "Gerai",
		delete_activity: "Ištrinti veiksmą?",
		do_you_want_to_delete: "Ar norite ištrinti?",
		delete: "Ištrinti",
		play: "Paleisti",
		error: "Klaida",
		loading: "Kraunasi",
		confirm: "Patvirtinti",
		downloading: "parsisiuntimas",
		launch: "Paleisti",
		enter_you_name: "Pasirinkti pavadinimą",
		enter_name_hint: "Jūsų vartotojo vardas",
		ask_name_description: "Jūsų vartotojo vardas bus naudojamas identifikuoti Jus žaidimo metu",
		password: "Slaptažodis",
		enter_activity_password: "Įveskite slaptažodį šiam žaidimui",
		licensee_password: "Įveskite slaptažodį šiam serveriui",
		incorrect_password: "Slaptažodis neteisingas",
		enter_password_hint: "Jūsų slaptažodis",
		activity_search: "Žaidimų paieška",
		qr_code_not_recognised: "QR kodas neatpažintas",
		scan_qr_code: "Skanuoti QR kodą",
		activity_not_found: "Žaidimas nerastas",
		do_you_want_to_go_home: "Ar tikrai norite išeiti iš žaidimo ir grįžti į pradinį ekraną?",
		you_score_point_key: "Jūs gavote %s taškų ir %s žetonų už šio punkto pasiekimą",
		award_point_arrival: "Jūs gavote %s taškų už šio punkto pasiekimą",
		award_key_arrival: "Jūs gavote %s žetonų už šio punkto pasiekimą",
		the_start_area: "Pradžios zona",
		the_finish_area: "Pabaigos zona",
		forbidden_area: "Apribota zona",
		choose_a_way: "Pasirinkti būdą",
		you_enter_forbidden_area: "Jūs patekote į apribotą zoną",
		please_move_to_activity_zone: "Judėkite į pradžios zoną",
		next_check_point_distance: "Kitas punktas yra %s nuo čia",
		finish_zone_distance: "Pabaigos zona yra %s nuo čia",
		settings: "Nustatymai",
		meters_feet: "Metrinė - Britų",
		sounds: "Garsai",
		language: "Kalba",
		key_required: "Jūs neturite tinkamo žetono paleisti šį punktą, šiam punktui reikalingas %s žetonas",
		set_destination: "Nustatyti paskirties vietą",
		home: "Pradžia",
		chat: "Pasikalbėti",
		you_score_point: "Jūs gavote %s taškus",
		you_score_point_token: "Jūs gavote %s taškus ir %s žetonus",
		controller_score: "Jūs gavote %s taškus iš kontrolieriaus už %s punktą",
		controller_key: "Jūs gavote %s žetonus iš kontrolieriaus",
		award_key_route: "Jūs gavote %s žetonus už šio maršruto pasirinkimą",
		award_point_route: "Jūs gavote %s taškus už šio maršruto pasirinkimą",
		award_point_key_route: "Jūs gavote %s taškus ir %s žetonus už šio maršruto pasirinkimą",
		task: "Užduotis",
		back: "Atgal",
		text_answer: "Tekstinis atsakymas",
		numeric_answer: "Skaitinis atsakymas",
		pass: "Praleisti",
		submit: "Pateikti",
		take_photo: "Įkelti paveikslėlį",
		choose_photo: "Pasirinkti nuotrauką",
		record_video: "Įrašyti video",
		play_video: "Paleisti video",
		choose_video: "Įkelti video",
		person_at_checkpoint: "Duokite kompiuterį renginio personalui",
		enter_password: "Įvesti slaptažodį",
		enter_score: "Įvesti rezultatą / %s",
		player_answer: "Žaidėjo atsakymas",
		pass_task_confirm: "Jūs negausite taškų, jeigu praleisite. Patvirtinkite, kad norite praleisti.",
		invalid_score: "Rezultatas negalioja",
		submit_answer_alert: "Jūsų atsakymas pateiktas įvertinimui. Jūs gausite taškus netrukus.",
		ranking: "Komandų eiliškumas",
		name: "Pavadinimas",
		score: "Laikas",
		time: "Rezultatas",
		rank: "Vieta",
		end_zone_distance: "Pabaigos zona %s",
		start_zone_distance: "Pradžios zona %s",
		next_checkpoint_distance: "Kitas punktas %s",
		send: "Išsiųsti",
		enter_message: "Nauja žinutė",
		today: "Šiandien",
		notification: "PRANEŠIMAS",
		finish_activity_alert: "Sveikiname, Jūs baigėte šį žaidimą",
		finish_activity: "Baigti žaidimą",
		partial_correct_answer: "Dalinai teisingas atsakymas",
		correct_answer: "Teisingas atsakymas",
		incorrect_answer: "Neteisingas atsakymas",
		qr_code_not_supported: "QR kodo skaitytuvo šis įrenginys nepalaiko",
		camera_not_supported: "Kameros šis įrenginys nepalaiko",
		photo_not_supported: "Nuotraukų albumo šis įrenginys nepalaiko",
		gps_not_available: "Tai yra GPS žaidimas. Paleisti vietos nustatymą ir bandyti iš naujo",
		unknown_error: "Kažkas nepavyko. Bandykite iš naujo arba susisiekite su renginio personalu",
		no_internet: "Patikrinkite Jūsų interneto ryšį",
		no_available_route: "Jūs neturite reikalavimų maršrutams iki kito punkto, tad esate sugrąžinami į žemėlapį​",
		scan: "​Skanuoti",
		magnetic_sensor_not_found: "​Magnetinis jutiklis nerastas Jūsų įrenginyje. Kompasas neveiks",
		on_demand_task: "​Užduotys pagal poreikį",
		company_questions: "​Įmonės klausimai",
		photo_challenges: "​Foto iššūkiai",
		general_challenges: "​Bendri iššūkiai",
		new_check_point_found: "Jūs ką tik radote %s punktą, ar norite PALEISTI %s punktą ar NUTRAUKTI ir tęsti toliau į %s punktą?",
		activity_ended: "Žaidimas baigtas",
		ask_require_key_title: "Šio punkto paleidimui bus panaudoti",
		available: "Galimi",
		change_server: "Pakeisti serverį",
		controller_push_score: "Jūs apdovanojami %s taškais iš kontrolieriaus už %s užduotį.",
		finished: "Pasibaigę",
		ongoing: "Vykstantys",
		return_to_task: "Grįžti prie užduoties",
		warning_no_photo: "Jūs nepadarėte nuotraukos, ar tikrai norite pateikti be nuotraukos?",
		warning_no_video: "Jūs neįrašėte video, ar tikrai norite pateikti be video?",
		you_enter_start_area: "Jūs pasiekėte starto zoną!",
		tokens: "Žetonai",
		activity_chat: "Žaidimo pranešimų siuntimas",
		message: "Pranešimų siuntimas",
		controller_chat: "Kontrolieriaus pranešimų siuntimas",
		one_to_one_team_chat: "Tiesioginis pranešimų siuntimas",
		add_team: "Tiesioginė žinutė",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Puikiai",
		partial_correct_feedback: "Gerai atlikta",
		feedback_wrong: "Sėkmės kitą kartą",
		max_attempts_try_one: "Jūsų atsakymas neteisingas. Jūs turite %s likusį bandymą",
		max_attempts_try_other: "Jūsų atsakymas neteisingas. Jūs turite %s likusius bandymus",
		download_limit_reached: "Atsisiuntimo limitas pasiektas",
		timer_run_out: "Laikmatis baigsis",
		controller: "Controller",
		search: "Search",
		welcome_to: "Sveiki atvykę į %s",
		your_name: "Jūsų vardas",
		please_input_your_name: "Įveskite savo vardą.",
		please_take_photo: "Padarykite savo avataro nuotrauką.",
		team_name: "Komandos pavadinimas",
		input_team_description: "Kartu su komanda nuspręskite, koks bus komandos pavadinimas ir kas bus kapitonas.",
		are_you_captain: "Ar Jūs esate komandos kapitonas?",
		please_input_team_name: "Įveskite savo komandos pavadinimą.",
		waiting_players: "Laukiame, kol prisijungs visi žaidėjai.",
		lobby: "Laukiamasis",
		ready: "Pasiruošę",
		captain: "Kapitonas",
		please_wait: "Prašome palaukti…",
		refresh_page: "Atnaujinti puslapį",
		show_other_players: "Parodykite kitus žaidėjus",
		warning: "Įspėjimas",
		finish_activity_confirm: "Jūs negalėsite grįžti prie veiklos. Ar tikrai norite baigti šią veiklą?",
		end: "Galas",
		return_to_activity: "Grįžti į veiklą",
		paste_from_clipboard: "Įklijuoti ekrano kopiją",
		team_chat: "Komandos pranešimų siuntimas"
	},
	"pl": {
		go_team: "Go Team",
		north: "Północ",
		north_east: "Północny wschód",
		east: "Wschód",
		south_east: "Południowy wschód",
		south: "Południe",
		south_west: "Południowy zachód",
		west: "Zachód",
		north_west: "Północny zachód",
		ongoing_activities: "Bieżące aktywności",
		downloaded_activities: "Pobrane Aktywności",
		finished_activities: "Skończone aktywności",
		download: "Pobierz",
		play_now: "Start",
		cont: "Kontynuuj",
		cancel: "Anuluj",
		done: "Gotowe",
		later: "Później",
		close: "Zamknij",
		ok: "OK",
		delete_activity: "Usunąć aktywność?",
		do_you_want_to_delete: "Czy chcesz usunąć?",
		delete: "Usuń",
		play: "Graj",
		error: "Błąd",
		loading: "Ładowanie",
		confirm: "Potwierdź",
		downloading: "Pobieranie",
		launch: "Uruchom",
		enter_you_name: "Wybierz imię",
		enter_name_hint: "Wybierz nazwę użytkownika",
		ask_name_description: "Twoja nazwa użytkownika, która będzie używana do Twojej identyfkacji w aktywności",
		password: "Hasło",
		enter_activity_password: "Proszę wprowadzić hasło dla tej aktywności",
		licensee_password: "Proszę wprowadzić hasło dla tego serwera",
		incorrect_password: "Twoje hasło jest niepoprawne",
		enter_password_hint: "Twoje hasło",
		activity_search: "Wyszukiwanie aktywności",
		qr_code_not_recognised: "Nie rozpoznano kodu QR",
		scan_qr_code: "Skanuj kod QR",
		activity_not_found: "Nie znaleziono aktywności",
		do_you_want_to_go_home: "Czy na pewno chcesz opuścić aktywność i wrócić do ekranu początkowego?",
		you_score_point_key: "Otrzymałeś %s punktów i %s tokenów za osiągnięcie tego punktu kontrolnego",
		award_point_arrival: "Otrzymałeś %s punktów za osiągnięcie tego punktu kontrolnego",
		award_key_arrival: "Otrzymałeś %s tokenów za osiągnięcie tego punktu kontrolnego",
		the_start_area: "Strefa startu",
		the_finish_area: "Strefa mety",
		forbidden_area: "Strefa ograniczona",
		choose_a_way: "Wybierz drogę",
		you_enter_forbidden_area: "Wkroczyłeś w strefę ograniczoną",
		please_move_to_activity_zone: "Idź do strefy startu",
		next_check_point_distance: "Następny punkt kontrolny znajduje się %s stąd",
		finish_zone_distance: "Strefa mety znajduje się %s stąd",
		settings: "Ustawienia",
		meters_feet: "Metryczny - Imperial",
		sounds: "Dźwięki",
		language: "Język",
		key_required: "Nie masz prawidłowego tokena do rozpoczęcia tego punktu, ten punkt wymaga %s tokena",
		set_destination: "Ustaw cel",
		home: "Ekran startowy",
		chat: "Chat",
		you_score_point: "Zdobyłeś %s punktów",
		you_score_point_token: "Zdobyłeś %s punktów i otrzymałeś %s tokenów",
		controller_score: "Zdobyłeś %s punktów od kontrolera za %s punkt kontrolny",
		controller_key: "Otrzymałeś %s tokenów od kontrolera",
		award_key_route: "Otrzymałeś %s tokenów za wybranie tej drogi",
		award_point_route: "Otrzymałeś %s punktów za wybranie tej drogi",
		award_point_key_route: "Otrzymałeś %s punktów i %s tokenów za wybranie tej drogi",
		task: "Zadanie",
		back: "Powrót",
		text_answer: "Napisz odpowiedź",
		numeric_answer: "Odpowiedź numeryczna",
		pass: "Odrzuć",
		submit: "Zatwierdź",
		take_photo: "Prześlij obraz",
		choose_photo: "Wybierz zdjęcie",
		record_video: "Nagraj Video",
		play_video: "Włącz video",
		choose_video: "Załaduj video",
		person_at_checkpoint: "Przekaż tablet obsłudze evntu",
		enter_password: "Wprowadź hasło",
		enter_score: "Wprowadź wynik / %s",
		player_answer: "Odpowiedź gracza",
		pass_task_confirm: "Nie otrzymasz żadnych punktów, jeśli odrzucisz. Potwierdź, że chcesz odrzucić.",
		invalid_score: "Nieprawidłowy wynik",
		submit_answer_alert: "Twoja odpowiedź została zgłoszona do sprawdzenia. Wkrótce otrzymasz wynik",
		ranking: "Ranking",
		name: "Nazwa",
		score: "Czas",
		time: "Wynik",
		rank: "Pozycja",
		end_zone_distance: "Strefa mety %s",
		start_zone_distance: "Strefa startu %s",
		next_checkpoint_distance: "Następny punkt kontrolny %s",
		send: "Wyślij",
		enter_message: "Nowa wiadomość",
		today: "Dzisiaj",
		notification: "Powiadomienia",
		finish_activity_alert: "Gratulujemy, zakończyłeś tę aktywność",
		finish_activity: "Zakończ aktywność",
		partial_correct_answer: "Częściowo poprawna odpowiedź",
		correct_answer: "Poprawna odpowiedź",
		incorrect_answer: "Niepoprawna odpowiedź",
		qr_code_not_supported: "Skaner kodu QR nie jest obsługiwany przez obecne urządzenie",
		camera_not_supported: "Kamera nie jest obsługiwana przez obecne urządzenie",
		photo_not_supported: "Nie otrzymasz żadnych punktów, jeśli odrzucisz. Potwierdź, że chcesz odrzucić.",
		gps_not_available: "Ta aktywność wymaga GPS. Włącz lokalizację i spróbuj ponownie",
		unknown_error: "Coś poszło nie tak. Prosimy spróbować ponownie lub skontaktować się z obsługą eventu w celu uzyskania wsparcia",
		no_internet: "Proszę sprawdź swoje połączenie internetowe",
		no_available_route: "Nie spełniasz żadnego z wymagań dla którejkolwiek z tras do następnego punktu kontrolnego, więc zostałeś przeniesiony z powrotem na mapę.",
		scan: "Skan",
		magnetic_sensor_not_found: "W urządzeniu nie ma czujnika magnetycznego. Kompas nie zadziała.",
		on_demand_task: "Zadania na żądanie",
		company_questions: "Pytania firmowe",
		photo_challenges: "Wyzwania ze zdjęciami",
		general_challenges: "Wyzwania ogólne",
		new_check_point_found: "Właśnie odnalazłeś punkt kontrolny %s, czy chcesz URUCHOMIĆ punkt kontrolny %s czy ANULOWAĆ i kontynuować punkt kontrolny %s?",
		activity_ended: "Aktywność zakończona",
		ask_require_key_title: "Uruchomienie tego punktu kontrolnego zużyje",
		available: "Dostępny",
		change_server: "Zmień serwer",
		controller_push_score: "Otrzymałeś %s punktów od kontrolera za %s zadanie.",
		finished: "Skończony",
		ongoing: "Trwający",
		return_to_task: "Wróć do zadania",
		you_enter_start_area: "Dotarłeś do obszaru startu!",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		tokens: "Tokeny",
		activity_chat: "Komunikaty o aktywnościach",
		message: "Przesyłanie wiadomości",
		controller_chat: "Komunikacja z kontrolerem",
		one_to_one_team_chat: "Komunikacja bezpośrednia",
		add_team: "Wiadomość bezpośrednia",
		move_in_the_activity_boundary: "Poruszaj się w granicach aktywności",
		correct_feedback: "Wspaniale!",
		partial_correct_feedback: "Dobra robota!",
		feedback_wrong: "Więcej szczęścia następnym razem!",
		max_attempts_try_one: "Twoja odpowiedź jest niepoprawna. Została ci %s próba.",
		max_attempts_try_other: "Twoja odpowiedź jest niepoprawna. Zostało ci %s prób.",
		download_limit_reached: "Osiągnięto limit pobierania",
		timer_run_out: "Czasomierz się skończył",
		controller: "Controller",
		search: "Search",
		welcome_to: "Witajcie w %s",
		your_name: "Twoje imię",
		please_input_your_name: "Proszę wpisać swoje imię",
		please_take_photo: "Proszę zrobić zdjęcie swojego awatara",
		team_name: "Nazwa zespołu",
		input_team_description: "Wymyślcie nazwę zespołu oraz zdecydujcie, kto będzie kapitanem",
		are_you_captain: "Czy jesteś kapitanem zespołu?",
		please_input_team_name: "Proszę wpisać nazwę zespołu",
		waiting_players: "Czekamy, aż dołączą wszyscy gracze",
		lobby: "Poczekalnia",
		ready: "Gotowe",
		captain: "Kapitan",
		please_wait: "Proszę czekać…",
		refresh_page: "Odśwież stronę",
		show_other_players: "Pokaż innym graczom",
		warning: "Ostrzeżenie",
		finish_activity_confirm: "Nie będziesz mógł wrócić do aktywności. Czy na pewno chcesz zakończyć tę aktywność?",
		end: "Kończyć się",
		return_to_activity: "Wróć do aktywności",
		paste_from_clipboard: "Wklej zrzut ekranu",
		team_chat: "Wiadomości zespołowe"
	},
	"br": {
		go_team: "Go Team",
		north: "Norte",
		north_east: "Nordeste",
		east: "Leste",
		south_east: "Sudeste",
		south: "Sul",
		south_west: "Sudoeste",
		west: "Oeste",
		north_west: "Noroeste",
		ongoing_activities: "Atividades em Andamento",
		downloaded_activities: "Atividades Baixadas",
		finished_activities: "Atividades Terminadas",
		download: "Download",
		play_now: "Início",
		cont: "Continue",
		cancel: "Cancelar",
		done: "Está Feito",
		later: "Depois",
		close: "Fechar",
		ok: "Ok",
		delete_activity: "Apagar Atividade?",
		do_you_want_to_delete: "Você quer apagar?",
		delete: "Apagar",
		play: "Play",
		error: "Erro",
		loading: "Carregando",
		confirm: "Confirmar",
		downloading: "Dowloading",
		launch: "Iniciar",
		enter_you_name: "Escolha um nome",
		enter_name_hint: "Seu nome",
		ask_name_description: "Seu nome será utilizado para sua identificação durante a atividade",
		password: "Senha",
		enter_activity_password: "Por favor, coloque a senha para esta atividade",
		licensee_password: "Por favor, coloque a senha para este servidor",
		incorrect_password: "A senha está incorreta",
		enter_password_hint: "Sua senha",
		activity_search: "Busca de atividades",
		qr_code_not_recognised: "QR Code não reconhecido",
		scan_qr_code: "Examinando o QR Code",
		activity_not_found: "Atividade não encontrada",
		do_you_want_to_go_home: "Você tem certeza que quer sair da atividade e retornar para a tela inicial",
		you_score_point_key: "Você recebeu %s pontos e a ficha %s por chegar a este checkpoint",
		award_point_arrival: "Você recebeu %s pontos por chegar a este checkpoint",
		award_key_arrival: "Você recebeu a ficha %s por chegar a este checkpoint",
		the_start_area: "A área inicial",
		the_finish_area: "A área final",
		forbidden_area: "Área proibida",
		choose_a_way: "Escolha um caminho",
		you_enter_forbidden_area: "Você entrar em uma área proibida",
		please_move_to_activity_zone: "Vá para a área inicial",
		next_check_point_distance: "O próximo checkpoint está localizado %s daqui",
		finish_zone_distance: "A área final está localizada %s daqui",
		settings: "Configurações",
		meters_feet: "Metros - Imperial",
		sounds: "Sons",
		language: "Idioma",
		key_required: "You do not have the correct token to launch this checkpoint, this checkpoint requires the %s token",
		set_destination: "Definir destino",
		home: "Início",
		chat: "chat",
		you_score_point: "Você ganhou %s pontos",
		you_score_point_token: "Você ganhou %s pontos e recebeu a ficha %s",
		controller_score: "Você recebeu %s pontos da central de controle pelo %s checkpoint",
		controller_key: "Você recebeu a ficha %s da central de controle",
		award_key_route: "Você recebeu a ficha %s por escolhar esta rota",
		award_point_route: "Você recebeu %s pontos por escolher esta rota",
		award_point_key_route: "Você recebeu %s pontos e a ficha %s por escolher esta rota",
		task: "Tarefa",
		back: "Voltar",
		text_answer: "Resposta em Texto",
		numeric_answer: "Resposta em número",
		pass: "Passar",
		submit: "Submeter",
		take_photo: "Faça upload de uma imagem",
		choose_photo: "Escolher a foto",
		record_video: "Gravar vídeo",
		play_video: "Tocar vídeo",
		choose_video: "Carregar vídeo",
		person_at_checkpoint: "Entregar o tablet para os monitores",
		enter_password: "Digitar senha",
		enter_score: "Digitar pontuação / %s",
		player_answer: "Resposta do Jogador",
		pass_task_confirm: "Você não receber nenhum ponto se passar. Tem certeza que quer passar?",
		invalid_score: "Pontuação não válida",
		submit_answer_alert: "Sua resposta está sendo enviada para ser revisada. Você receberá sua pontuação em breve.",
		ranking: "Ranking",
		name: "Nome",
		score: "Tempo",
		time: "Pontuação",
		rank: "Classificação",
		end_zone_distance: "Área final %s",
		start_zone_distance: "Área inicial %s",
		next_checkpoint_distance: "Para o próximo checkpoint %s",
		send: "Enviar",
		enter_message: "Nova mensagem",
		today: "hoje",
		notification: "NOTIFICAÇÃO",
		finish_activity_alert: "Parabéns! Você concluiu esta atividade",
		finish_activity: "Concluir atividade",
		partial_correct_answer: "Resposta parcialmente correta",
		correct_answer: "Resposta Correta",
		incorrect_answer: "Resposta Incorreta",
		qr_code_not_supported: "QR Code Scanner não suportado pelo aparelho",
		camera_not_supported: "Camera não suportada pelo aparelho",
		photo_not_supported: "Galeria de fotos não suportada pelo aparelho",
		gps_not_available: "Esta é uma atividade baseada no uso do GPS. Ative a localização e tente de novo",
		unknown_error: "Algo deu errado. Por favor, entre em contato com os monitores para ajuda",
		no_internet: "Por favor, cheque sua conexção de internet",
		no_available_route: "Não têm acesso a nenhuma rota para o próximo checkpoint, por isso serão redirecionados para o mapa.",
		scan: "Scan",
		magnetic_sensor_not_found: "Sensor magnético do equipamento não detetado. Desta forma a bússola não funciona.",
		on_demand_task: "Desafios 'On-demand'",
		company_questions: "Desafios de empresa",
		photo_challenges: "Desafios fotográficos",
		general_challenges: "Desafios gerais",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "A actividade acabou",
		ask_require_key_title: "A escolha deste checkpoint vai consumir",
		available: "Disponível",
		change_server: "A mudar de servidor",
		controller_push_score: "A vossa equipa obteve %s pontos atribuídos pelo controlador no desafio %s",
		finished: "Terminada",
		ongoing: "A decorrer",
		return_to_task: "Voltar ao desafio",
		warning_no_photo: "Não tiraram foto. Têm a certeza que pretendem continuar sem submeter uma foto?",
		warning_no_video: "Não captaram um vídeo. Têm a certeza que pretendem continuar sem submeter um vídeo?",
		you_enter_start_area: "Chegaram à zona de partida",
		tokens: "Chave",
		activity_chat: "Registo das Mensagens",
		message: "Centro de Mensagens",
		controller_chat: "Mensagens ao Controlador",
		one_to_one_team_chat: "Envio de Mensagem direta",
		add_team: "Mensagem direta",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "A resposta está incorreta. Resta %s tentativa",
		max_attempts_try_other: "A resposta está incorreta. Restam %s tentativas",
		download_limit_reached: "Limite de downloads atingido",
		timer_run_out: "O temporizador acaba",
		controller: "Controller",
		search: "Search",
		welcome_to: "Bem vindo ao %s",
		your_name: "O seu nome",
		please_input_your_name: "Insira o seu nome.",
		please_take_photo: "Tire uma foto para o seu avatar.",
		team_name: "Nome da equipa",
		input_team_description: "Em conjunto com a sua equipa, escolham um nome para a equipa e quem será o vosso capitão.",
		are_you_captain: "É o capitão da sua equipa?",
		please_input_team_name: "Por favor, insira o nome da sua equipa.",
		waiting_players: "A aguardar que todos os jogadores se juntem.",
		lobby: "Lobby",
		ready: "Pronto",
		captain: "Capitão",
		please_wait: "Aguarde por favor…",
		refresh_page: "Atualizar a página",
		show_other_players: "Mostrar outros jogadores",
		warning: "Aviso",
		finish_activity_confirm: "Você não poderá retornar à atividade. Tem certeza que deseja encerrar esta atividade?",
		end: "Fim",
		return_to_activity: "Retornar à atividade",
		paste_from_clipboard: "Colar captura de tela",
		team_chat: "Mensagens da equipe"
	},
	"pt": {
		go_team: "Go Team",
		north: "Norte",
		north_east: "Nordeste",
		east: "Este",
		south_east: "Sudeste",
		south: "Sul",
		south_west: "Sudoeste",
		west: "Oeste",
		north_west: "Noroeste",
		ongoing_activities: "Atividades a decorrer",
		downloaded_activities: "Atividades Baixadas",
		finished_activities: "Atividades terminadas",
		download: "Transferir",
		play_now: "Iniciar",
		cont: "Continuar",
		cancel: "Cancelar",
		done: "Terminado",
		later: "Mais tarde",
		close: "Fechar",
		ok: "Ok",
		delete_activity: "Apagar atividade?",
		do_you_want_to_delete: "Quer apagar?",
		delete: "Apagar",
		play: "Jogar",
		error: "Erro",
		loading: "A carregar",
		confirm: "Confirmar",
		downloading: "a transferir",
		launch: "Iniciar",
		enter_you_name: "Escolham um nome",
		enter_name_hint: "Escolham um nome",
		ask_name_description: "O vosso nome de utilizador será usado para o identificar na atividade",
		password: "Password",
		enter_activity_password: "Por favor introduzam a password desta atividade",
		licensee_password: "Por favor introduzam a password deste servidor",
		incorrect_password: "A password está incorreta",
		enter_password_hint: "A vossa password",
		activity_search: "Procura de atividade",
		qr_code_not_recognised: "Código QR não reconhecido",
		scan_qr_code: "Ler o código QR",
		activity_not_found: "Atividade não encontrada",
		do_you_want_to_go_home: "Têm a certeza que querem sair da atividade e ir para a página inicial?",
		you_score_point_key: "Ganharam %s pontos e o token %s por atingir este checkpoint",
		award_point_arrival: "Ganharam %s pontos por atingir este checkpoint",
		award_key_arrival: "Ganharam o token %s por atingir este checkpoint",
		the_start_area: "Ponto de partida",
		the_finish_area: "Ponto de chegada",
		forbidden_area: "Zona restrita",
		choose_a_way: "Escolham um caminho",
		you_enter_forbidden_area: "Entraram numa zona restrita",
		please_move_to_activity_zone: "Desloquem-se para o ponto de partida",
		next_check_point_distance: "O próximo checkpoint está a %s",
		finish_zone_distance: "O ponto de chegada está a %s",
		settings: "Configurações",
		meters_feet: "Métrico - Imperial",
		sounds: "Sons",
		language: "Idioma",
		key_required: "Não têm o token correto para lançar este checkpoint. Este checkpoint requer o token %s",
		set_destination: "Escolham o destino",
		home: "Home",
		chat: "Chat",
		you_score_point: "Ganharam %s pontos",
		you_score_point_token: "Ganharam %s pontos e recebeu o token %s",
		controller_score: "Ganharam %s pontos do controlador",
		controller_key: "Ganharam o token %s do controlador",
		award_key_route: "Ganharam o token %s por",
		award_point_route: "Ganharam %s pontos por",
		award_point_key_route: "Ganharam %s pontos e o token %s por seguir este caminho",
		task: "Desafio",
		back: "Para trás",
		text_answer: "Resposta em texto",
		numeric_answer: "Resposta numerica",
		pass: "Passar",
		submit: "Submeter",
		take_photo: "Faça upload de uma imagem",
		choose_photo: "Escolher foto",
		record_video: "Gravar vídeo",
		play_video: "Reproduzir vídeo",
		choose_video: "Enviar vídeo",
		person_at_checkpoint: "Entreguem o tablet a um membro do staff",
		enter_password: "Insiram a password",
		enter_score: "Insiram a pontuação / %s",
		player_answer: "Resposta do jogador",
		pass_task_confirm: "Não receberão pontos se passarem. Confirmem que desejam passar.",
		invalid_score: "Pontuação não disponível",
		submit_answer_alert: "A vossa resposta foi submetida para apreciação. Receberão a pontuação em breve.",
		ranking: "Ranking",
		name: "Nome",
		score: "Tempo",
		time: "Pontuação",
		rank: "Rank",
		end_zone_distance: "Ponto de chegada %s",
		start_zone_distance: "Ponto de partida %s",
		next_checkpoint_distance: "Próximo checkpoint %s",
		send: "Enviar",
		enter_message: "Nova mensagem",
		today: "Hoje",
		notification: "NOTIFICAÇÃO",
		finish_activity_alert: "Parabéns, terminaram a atividade!",
		finish_activity: "Terminem a atividade",
		partial_correct_answer: "Resposta parcialmente correta",
		correct_answer: "Resposta correta",
		incorrect_answer: "Resposta incorreta",
		qr_code_not_supported: "Leitura de código QR não suportada pelo dispositivo",
		camera_not_supported: "Camera não suportada pelo dispositivo",
		photo_not_supported: "Biblioteca de fotos não suportada por este dispositivo",
		gps_not_available: "Esta é uma atividade com recurso a GPS. Ativem os serviços de localização e tentem novamente",
		unknown_error: "Algo correu mal. Por favor tentem novamente ou contactem um elemento da organização",
		no_internet: "Por favor, confirmem a vossa ligação à internet",
		no_available_route: "Não têm acesso a nenhuma rota para o próximo checkpoint, por isso serão redirecionados para o mapa.",
		scan: "Scan",
		magnetic_sensor_not_found: "Sensor magnético do equipamento não detetado. Desta forma a bússola não funciona.",
		on_demand_task: "Desafios 'On-demand'",
		company_questions: "Desafios de empresa",
		photo_challenges: "Desafios fotográficos",
		general_challenges: "Desafios gerais",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "A actividade acabou",
		ask_require_key_title: "A escolha deste checkpoint vai consumir",
		available: "Disponível",
		change_server: "A mudar de servidor",
		controller_push_score: "A vossa equipa obteve %s pontos atribuídos pelo controlador no desafio %s",
		finished: "Terminada",
		ongoing: "A decorrer",
		return_to_task: "Voltar ao desafio",
		warning_no_photo: "Não tiraram foto. Têm a certeza que pretendem continuar sem submeter uma foto?",
		warning_no_video: "Não captaram um vídeo. Têm a certeza que pretendem continuar sem submeter um vídeo?",
		you_enter_start_area: "Chegaram à zona de partida",
		tokens: "Chave",
		activity_chat: "Registo das Mensagens",
		message: "Centro de Mensagens",
		controller_chat: "Mensagens ao Controlador",
		one_to_one_team_chat: "Envio de Mensagem direta",
		add_team: "Mensagem direta",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "A resposta está incorreta. Resta %s tentativa",
		max_attempts_try_other: "A resposta está incorreta. Restam %s tentativas",
		download_limit_reached: "Limite de downloads atingido",
		timer_run_out: "O temporizador acaba",
		controller: "Controller",
		search: "Search",
		welcome_to: "Bem vindo ao %s",
		your_name: "O seu nome",
		please_input_your_name: "Insira o seu nome.",
		please_take_photo: "Tire uma foto para o seu avatar.",
		team_name: "Nome da equipa",
		input_team_description: "Em conjunto com a sua equipa, escolham um nome para a equipa e quem será o vosso capitão.",
		are_you_captain: "É o capitão da sua equipa?",
		please_input_team_name: "Por favor, insira o nome da sua equipa.",
		waiting_players: "A aguardar que todos os jogadores se juntem.",
		lobby: "Lobby",
		ready: "Pronto",
		captain: "Capitão",
		please_wait: "Aguarde por favor…",
		refresh_page: "Atualizar a página",
		show_other_players: "Mostrar outros jogadores",
		warning: "Aviso",
		finish_activity_confirm: "Você não poderá retornar à atividade. Tem certeza que deseja encerrar esta atividade?",
		end: "Fim",
		return_to_activity: "Retornar à atividade",
		paste_from_clipboard: "Colar captura de tela",
		team_chat: "Mensagens da equipe"
	},
	"ro": {
		go_team: "Go Team",
		north: "Nord",
		north_east: "Nord-Est",
		east: "Est",
		south_east: "Sud Est",
		south: "Sud",
		south_west: "Sud-Vest",
		west: "Vest",
		north_west: "Nord-Vest",
		ongoing_activities: "Activități în curs",
		downloaded_activities: "Activități Descărcate",
		finished_activities: "Activități finalizate",
		download: "Descarcă",
		play_now: "Start",
		cont: "Continuă",
		cancel: "Anulare",
		done: "Terminat",
		later: "Mai târziu",
		close: "Închide",
		ok: "OK",
		delete_activity: "Șterge activitatea?",
		do_you_want_to_delete: "Doriți să stergeți?",
		delete: "Stergere",
		play: "Play",
		error: "Eroare",
		loading: "Încărcare",
		confirm: "Confirmă",
		downloading: "Descărcare",
		launch: "Lansează",
		enter_you_name: "Alege un nume",
		enter_name_hint: "Nume de utilizator",
		ask_name_description: "Vei fi identificat în cadrul activității cu numele tău de utilizator",
		password: "Parola",
		enter_activity_password: "Vă rugăm introduceți parola pentru această activitate",
		licensee_password: "Vă rugăm introduceți parola pentru acest server",
		incorrect_password: "Parola este incorectă",
		enter_password_hint: "Parola",
		activity_search: "Activitate de căutare",
		qr_code_not_recognised: "Codul QR nu este recunoscut",
		scan_qr_code: "Scanează codul QR",
		activity_not_found: "Activitatea nu este găsită",
		do_you_want_to_go_home: "Sunteți siguri că doriți să ieșiți din această activitate și să vă întoarceți la ecranul principal?",
		you_score_point_key: "Ați primit  %s puncte și %s jetoane pentru că ați ajuns la acest punct de verificare",
		award_point_arrival: "Ați primit %s puncte pentru că ați ajuns la acest punct de verificare",
		award_key_arrival: "Ați primit %s jetoane pentru că ați ajuns la acest punct de verificare",
		the_start_area: "zona de start",
		the_finish_area: "zona de final",
		forbidden_area: "Zonă interzisă",
		choose_a_way: "Alege o cale",
		you_enter_forbidden_area: "Ați intrat într-o zonă restricționată",
		please_move_to_activity_zone: "Mergeți la zona de start",
		next_check_point_distance: "Urmatorul punct de verificare este localizat %s de aici",
		finish_zone_distance: "Zona de final este localizată %s de aici",
		settings: "Setări",
		meters_feet: "Metric - Imperial",
		sounds: "Sunete",
		language: "Limbă",
		key_required: "Nu ai cheia corectă pentru a accesa acest checkpoint. Ai nevoie de %s.",
		set_destination: "Setează destinația",
		home: "Acasă",
		chat: "Chat",
		you_score_point: "Felicitări! Ați câștigat %s puncte",
		you_score_point_token: "Felicitări! Ați câștigat %s puncte și ați primit %s jetoane",
		controller_score: "Felicitări! Ați câștigat %s puncte de la verificator pentru %s checkpoint(-uri)",
		controller_key: "Felicitări! Ați câștigat %s jetoane de la verificator",
		award_key_route: "Felicitări! Ați câștigat %s jetoane pentru că ați selectat această rută",
		award_point_route: "Felicitări! Ați câștigat %s puncte pentru că ați selectat această rută",
		award_point_key_route: "Felicitări! Ați câștigat %s puncte și %s jetoane pentru că ați selectat aceasta rută",
		task: "Sarcina",
		back: "Înapoi",
		text_answer: "Răspuns text",
		numeric_answer: "Răspuns numeric",
		pass: "Renunțare",
		submit: "Validează/Înregistrează",
		take_photo: "Încărcați o imagine",
		choose_photo: "Selectează fotografia",
		record_video: "Înregistrează video",
		play_video: "Rulează videoclipul",
		choose_video: "Încarcă film",
		person_at_checkpoint: "Înmânează tableta către organizator",
		enter_password: "Introdu parola",
		enter_score: "Introdu scorul / %s",
		player_answer: "Răspuns jucător",
		pass_task_confirm: "Nu veți primi nici un punct dacă renunțați. Vă rugăm confirmați faptul că săriți peste acest pas.",
		invalid_score: "Scor invalid",
		submit_answer_alert: "Răspunsul tău este analizat. Vei primi un scor în scurt timp.",
		ranking: "Clasament",
		name: "Nume",
		score: "Timp",
		time: "Scor",
		rank: "Poziție în clasament",
		end_zone_distance: "Zona de final %s",
		start_zone_distance: "Zona de start %s",
		next_checkpoint_distance: "Urmatorul punct de verificare",
		send: "Trimite",
		enter_message: "Mesaj nou",
		today: "astazi",
		notification: "NOTIFICĂRI",
		finish_activity_alert: "Felicitări, ați terminat această activitate",
		finish_activity: "Încheie activitatea",
		partial_correct_answer: "Răspuns parțial corect",
		correct_answer: "Răspuns corect!",
		incorrect_answer: "Răspuns greșit!",
		qr_code_not_supported: "Scanarea de coduri QR nu este compatibilă cu dispozitivul actual",
		camera_not_supported: "Camera nu este disponibilă pe dispozitivul actual",
		photo_not_supported: "Librăria foto nu este disponibilă pe dispozitivul actual",
		gps_not_available: "Aceasta este o activitate cu coordonate GPS.",
		unknown_error: "A apărut o eroare. Vă rugăm reîncercați sau contactați echipa organizatoare",
		no_internet: "Vă rugăm verificați conexiunea la internet",
		no_available_route: "In acest moment nu îndepliniți cerințele necesare pentru a accesa următorul punct de verificare. Ați revenit la harta principală.",
		scan: "Scanare",
		magnetic_sensor_not_found: "Senzorul magnetic al dispozitivului dumneavoastră nu poate fi gasit. Busola nu va funcționa.",
		on_demand_task: "Sarcini la cerere",
		company_questions: "Intrebări despre companie",
		photo_challenges: "Provocare foto",
		general_challenges: "Provocări generale",
		new_check_point_found: "Ați descoperit punctul de verificare %s, doriți sa LANSATI punctul de verificare %s sau continuați către punctul %s?",
		activity_ended: "Activitatea a luat sfârșit",
		ask_require_key_title: "Lansarea acestui punct de verificare va consuma cheia",
		available: "Disponibil",
		change_server: "Schimba serverul",
		controller_push_score: "Ați primit %s puncte de la moderator pentru sarcina %s",
		finished: "Finalizat",
		ongoing: "In curs",
		return_to_task: "Întoarcere la sarcina",
		warning_no_photo: "Nu ați făcut nicio fotografie, sunteți siguri ca doriți sa confirmați fără fotografie?",
		warning_no_video: "Nu ați realizat niciun videoclip, sunteți siguri ca doriți sa confirmați fără videoclip?",
		you_enter_start_area: "Ați ajuns in zona de start!",
		tokens: "Chei",
		activity_chat: "Mesagerie activitate",
		message: "Mesagerie",
		controller_chat: "Mesagerie Moderator",
		one_to_one_team_chat: "Mesagerie directa",
		add_team: "Mesaj direct",
		move_in_the_activity_boundary: "Mergeți in zona activității",
		correct_feedback: "Excelent!",
		partial_correct_feedback: "Foarte bine!",
		feedback_wrong: "Mai mult noroc data viitoare!",
		max_attempts_try_one: "Răspunsul dvs. este incorect. Mai aveți %s încercări.",
		max_attempts_try_other: "Răspunsul dvs. este incorect. Mai aveți %s încercări.",
		download_limit_reached: "Limita de descărcare atinsă",
		timer_run_out: "Timpul a expirat",
		controller: "Moderator",
		search: "Căutare",
		welcome_to: "Bine ati venit la activitatea %s",
		your_name: "Numele tau",
		please_input_your_name: "Te rugam sa iti introduci numele.",
		please_take_photo: "Te rugam sa faci o poza pentru avatarul tau.",
		team_name: "Numele echipei tale",
		input_team_description: "Impreuna cu ceilalti membri ai echipei, decideti-va cu privire la numele echipei si desemnati un capitan de echipa.",
		are_you_captain: "Tu esti capitanul de echipa?",
		please_input_team_name: "Te rugam sa introduci numele echipei tale.",
		waiting_players: "Asteptam sa intre toti jucatorii.",
		lobby: "Lobby",
		ready: "Pregatiti?",
		captain: "Capitan",
		please_wait: "Te rugam sa astepti…",
		refresh_page: "Reîmprospătare pagină",
		show_other_players: "Arătați alți jucători",
		warning: "Avertizare",
		finish_activity_confirm: "Nu veți putea reveni la activitate. Sigur doriți să încheiați această activitate?",
		end: "Sfârșit",
		return_to_activity: "Reveniți la activitate",
		paste_from_clipboard: "Inserați captura de ecran",
		team_chat: "Mesaje în echipă"
	},
	"ru": {
		go_team: "Go Team",
		north: "Север",
		north_east: "Северо-восток",
		east: "Восток",
		south_east: "Юго-восток",
		south: "Юг",
		south_west: "Юго-запад",
		west: "Запад",
		north_west: "Северо-запад",
		ongoing_activities: "Текущее задание",
		downloaded_activities: "Скачанные действия",
		finished_activities: "Законченные задания",
		download: "Скачать",
		play_now: "Начать",
		cont: "Продолжить",
		cancel: "Отменить",
		done: "Выполнено",
		later: "Позже",
		close: "Закрыть",
		ok: "ОК",
		delete_activity: "Удалить задание?",
		do_you_want_to_delete: "Вы хотите удалить?",
		delete: "Удалить",
		play: "Играть",
		error: "Ошибка",
		loading: "Загрузка",
		confirm: "Подтвердить",
		downloading: "Скачивание",
		launch: "Запустить",
		enter_you_name: "Выбрать имя",
		enter_name_hint: "Ваш логин",
		ask_name_description: "Логин будет использоваться в задании",
		password: "Пароль",
		enter_activity_password: "Пожалуйста, введите пароль для этого задания",
		licensee_password: "Пожалуйста, введите пароль для этого сервера",
		incorrect_password: "Пароль неверный",
		enter_password_hint: "Ваш пароль",
		activity_search: "Поиск заданий",
		qr_code_not_recognised: "QR-код не распознается",
		scan_qr_code: "Сканировать QR-код",
		activity_not_found: "Задание не найдено",
		do_you_want_to_go_home: "Вы уверены, что хотите выйти из задания и вернуться на домашнюю страницу?",
		you_score_point_key: "Вы получили %s баллов и %s ключей за достижение данного чекпойнта",
		award_point_arrival: "Вы получили %s баллов за достижение данного чекпойнта",
		award_key_arrival: "Вы получили %s ключей за достижение данного чекпойнта",
		the_start_area: "место старта",
		the_finish_area: "место финиша",
		forbidden_area: "Запретная зона",
		choose_a_way: "Выбрать путь",
		you_enter_forbidden_area: "Вы вошли в запретную зону",
		please_move_to_activity_zone: "Перейти к следующему месту старту",
		next_check_point_distance: "Следующий чекпойнт расположен в %s отсюда",
		finish_zone_distance: "Место фининша расположено в %s отсюда",
		settings: "Настройки",
		meters_feet: "Система мер - британская",
		sounds: "Звуки",
		language: "Язык",
		key_required: "У вас нет подходящего ключа для запуска данного чекпойнта, чекпойнт требует %s ключей",
		set_destination: "Установить конечный пункт",
		home: "Дом",
		chat: "Чат",
		you_score_point: "Вы набрали %s баллов",
		you_score_point_token: "Вы набрали %s баллов и получили %s ключей",
		controller_score: "Вы набрали %s баллов от контроллера для %s чекпойнта",
		controller_key: "Вы получили %s ключей от контроллера",
		award_key_route: "Вы получили %s ключей для этого маршрута",
		award_point_route: "Вы получили %s баллов для этого маршрута",
		award_point_key_route: "Вы получили %s баллов и %s ключей для этого маршрута",
		task: "Задача",
		back: "Назад",
		text_answer: "Текстовый ответ",
		numeric_answer: "Цифровой ответ",
		pass: "Сдаться",
		submit: "Отправить",
		take_photo: "Загрузите изображение",
		choose_photo: "Выбрать фото",
		record_video: "Снять видео",
		play_video: "Включить видео",
		choose_video: "Загрузить видео",
		person_at_checkpoint: "Передайте планшет команде организаторов",
		enter_password: "Ввести пароль",
		enter_score: "Ввести счет / %s",
		player_answer: "Ответ игрока",
		pass_task_confirm: "Вы не получите баллы, если решите сдаться. Подтвердите, что хотите сдаться.",
		invalid_score: "Очки не действительны",
		submit_answer_alert: "Вас ответ был передан на рассмотрение, скоро вы получите очки.",
		ranking: "Рейтинг",
		name: "Имя",
		score: "Время",
		time: "Очки",
		rank: "Место",
		end_zone_distance: "Место финиша %s",
		start_zone_distance: "Место старта %s",
		next_checkpoint_distance: "Следующий чекпойнт %s",
		send: "Отправить",
		enter_message: "Новое сообщение",
		today: "сегодня",
		notification: "УВЕДОМЛЕНИЕ",
		finish_activity_alert: "Поздравляем, вы прошли задание",
		finish_activity: "Закончить задание",
		partial_correct_answer: "Частично верный ответ",
		correct_answer: "Верный ответ",
		incorrect_answer: "Неверный ответ",
		qr_code_not_supported: "Сканер QR-кода не поддерживается на данном устройстве",
		camera_not_supported: "Камера не поддерживается на данном устройстве",
		photo_not_supported: "Фотоархив не поддерживается на данном устройстве",
		gps_not_available: "Для этого задания необходим GPS. Разрешите определение местоположения и попробуйте еще раз",
		unknown_error: "Что-то пошло не так. Пожалуйста, попробуйте еще раз или обратитесь за помощью к команде организаторов",
		no_internet: "Проверьте интернет-соединение",
		no_available_route: "Вы не поставили условий для маршрутов к следующему чекпойнту, поэтому вернулись в режим карты",
		scan: "Сканировать",
		magnetic_sensor_not_found: "На вашем устройстве нет магнитного сенсора, компас не будет работать.",
		on_demand_task: "Задания по запросу",
		company_questions: "Вопросы компании",
		photo_challenges: "Фото-задания",
		general_challenges: "Общие задания",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activity ended",
		ask_require_key_title: "Launching this checkpoint will consume",
		available: "Available",
		change_server: "Change server",
		controller_push_score: "You scored %s points from the controller for %s task",
		finished: "Finished",
		ongoing: "Ongoing",
		return_to_task: "Return To Task",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		you_enter_start_area: "You have reached the start area!",
		tokens: "Tokens",
		activity_chat: "Activity messaging",
		message: "Messaging",
		controller_chat: "Controller messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "Предел загрузки достигнут",
		timer_run_out: "Таймер заканчивается",
		controller: "Controller",
		search: "Search",
		welcome_to: "Добро пожаловать в %s",
		your_name: "Ваше имя",
		please_input_your_name: "Пожалуйста, введите свое имя.",
		please_take_photo: "Пожалуйста, сделайте фото для аватарки.",
		team_name: "Название команды",
		input_team_description: "Вместе с другими игроками придумайте название команды и выберите капитана.",
		are_you_captain: "Вы капитан команды?",
		please_input_team_name: "Пожалуйста, введите название команды.",
		waiting_players: "Ждем, пока подключатся все игроки.",
		lobby: "Лобби",
		ready: "Все готовы",
		captain: "Капитан",
		please_wait: "Пожалуйста, подождите…",
		refresh_page: "Обновить страницу",
		show_other_players: "Показать других игроков",
		warning: "Предупреждение",
		finish_activity_confirm: "Вы не сможете вернуться к активности. Вы уверены, что хотите прекратить это действие?",
		end: "Конец",
		return_to_activity: "Вернуться к активности",
		paste_from_clipboard: "Вставить снимок экрана",
		team_chat: "Обмен сообщениями в команде"
	},
	"sk": {
		go_team: "Go Team",
		north: "Sever",
		north_east: "Severovýchod",
		east: "Východ",
		south_east: "Juhovýchod",
		south: "Juh",
		south_west: "Juhozápad",
		west: "Západ",
		north_west: "Severozápad",
		ongoing_activities: "Prebiehajúce aktivity",
		downloaded_activities: "Stiahnuté aktivity",
		finished_activities: "Dokončené aktivity",
		download: "Stiahnuť",
		play_now: "Začať",
		cont: "Pokračovať",
		cancel: "Zrušiť",
		done: "Hotové",
		later: "Neskôr",
		close: "Zatvoriť",
		ok: "OK",
		delete_activity: "Vymazať aktivitu?",
		do_you_want_to_delete: "Chcete to naozaj zmazať ?",
		delete: "Zmazať",
		play: "Hrať",
		error: "Chyba",
		loading: "Načítava",
		confirm: "Potvrdiť",
		downloading: "Sťahujem",
		launch: "Spustiť",
		enter_you_name: "Zvoľte meno",
		enter_name_hint: "Vaše meno",
		ask_name_description: "Vaše používateľské meno bude použité na identifikáciu v aktivite",
		password: "Heslo",
		enter_activity_password: "Zadajte heslo pre túto aktivitu",
		licensee_password: "Zadajte heslo pre tento server",
		incorrect_password: "Heslo je nesprávne",
		enter_password_hint: "Vaše heslo",
		activity_search: "Vyhľadávanie aktivity",
		qr_code_not_recognised: "QR kód nebol rozpoznaný",
		scan_qr_code: "Naskenujte QR kód",
		activity_not_found: "Aktivita sa nenašla",
		do_you_want_to_go_home: "Naozaj chcete ukončiť aktivitu a vrátiť sa na domovskú obrazovku?",
		you_score_point_key: "Za dosiahnutie tohto kontrolného bodu ste získali %s bodov a %s žetónov",
		award_point_arrival: "Za dosiahnutie tohto kontrolného bodu ste získali %s bodov",
		award_key_arrival: "Za dosiahnutie tohto kontrolného bodu ste dostali %s žetónov",
		the_start_area: "Štartovacia zóna",
		the_finish_area: "Koncová zóna",
		forbidden_area: "Zakázaná zóna",
		choose_a_way: "Vyberte si cestu",
		you_enter_forbidden_area: "Vstúpili ste do zakázanej zóny",
		please_move_to_activity_zone: "Presuňte sa do štartovacej zóny",
		next_check_point_distance: "Ďalší kontrolný bod sa nachádza %s odtiaľto",
		finish_zone_distance: "Koncová zóna sa nachádza %s odtiaľto",
		settings: "Nastavenia",
		meters_feet: "Metrické – imperiálne",
		sounds: "Zvuky",
		language: "Jazyk",
		key_required: "Nemáte správny žetón na spustenie tohto kontrolného bodu, tento kontrolný bod vyžaduje žetón %s",
		set_destination: "Nastaviť cieľ",
		home: "Domov",
		chat: "Chat",
		you_score_point: "Získali ste %s bodov",
		you_score_point_token: "Získali ste %s bodov a %s žetónov",
		controller_score: "Dosiahli ste %s od kontrolóra pre %s kontrolný bod",
		controller_key: "Od kontrolóra ste dostali žetón %s",
		award_key_route: "Za túto cestu ste dostali %s žetón",
		award_point_route: "Za absolvovanie tejto trasy ste získali %s bodov",
		award_point_key_route: "Za absolvovanie tejto trasy ste získali %s bodov a %s žetón",
		task: "Úloha",
		back: "Späť",
		text_answer: "Textová odpoveď",
		numeric_answer: "Číselná odpoveď",
		pass: "Preskočiť",
		submit: "Odoslať",
		take_photo: "Urobiť fotku",
		choose_photo: "Vybrať fotku",
		record_video: "Natočiť video",
		play_video: "Prehrať video",
		choose_video: "Nahrať video",
		person_at_checkpoint: "Odovzdajte tablet personálu",
		enter_password: "Zadajte heslo",
		enter_score: "Zadajte skóre / %s",
		player_answer: "Odpoveď hráča",
		pass_task_confirm: "Ak túto úlohu preskočíte, nezískate žiadne body. Potvrďte, že chcete preskočiť",
		invalid_score: "Skóre nie je platné",
		submit_answer_alert: "Vaša odpoveď bola odoslaná na posúdenie",
		ranking: "Poradie",
		name: "Názov",
		time: "Čas",
		score: "Skóre",
		rank: "Poradie",
		end_zone_distance: "Koncová zóna %s",
		start_zone_distance: "Štartovacia zóna %s",
		next_checkpoint_distance: "Ďalší kontrolný bod %s",
		send: "Odoslať",
		enter_message: "Nová správa",
		today: "Dnes",
		notification: "Upozornenia",
		finish_activity_alert: "Práve ste dokončili hru. Blahoželáme.",
		finish_activity: "Dokončiť aktivitu",
		partial_correct_answer: "Čiastočne správna odpoveď",
		correct_answer: "Správna odpoveď",
		incorrect_answer: "Nesprávna odpoveď",
		qr_code_not_supported: "Skener QR kódov nie je podporovaný aktuálnym zariadením",
		camera_not_supported: "Fotoaparát nie je podporovaný aktuálnym zariadením",
		photo_not_supported: "Knižnica fotografií nie je podporovaná aktuálnym zariadením",
		gps_not_available: "Toto je GPS aktivita. Povoľte prosím služby určovania polohy a skúste to znova.",
		unknown_error: "Niečo sa pokazilo. Skúste to znova, alebo požiadajte o podporu personálu.",
		no_internet: "Skontrolujte svoje internetové pripojenie.",
		no_available_route: "Nemáte požiadavky na žiadnu z trás k ďalšiemu kontrolnému bodu, takže ste boli vrátení na mapu.",
		scan: "Skenovať",
		magnetic_sensor_not_found: "Na vašom zariadení sa nedá nájsť magnetický senzor. Kompas nebude fungovať.",
		on_demand_task: "Úlohy na požiadanie",
		company_questions: "Otázky spoločnosti",
		photo_challenges: "Fotografické výzvy",
		general_challenges: "Všeobecné výzvy",
		new_check_point_found: "Práve ste našli kontrolný bod %s, chcete SPUSTIŤ kontrolný bod %s alebo ZRUŠIŤ a pokračovať na ďalší kontrolný bod %s?",
		warning_no_photo: "Neurobili ste fotku, ste si istý, že chcete odoslať úlohu bez fotky?",
		warning_no_video: "Nenahrali ste video, ste si istý, že chcete odoslať úlohu bez videa?",
		return_to_task: "Vráťte sa k úlohe",
		you_enter_start_area: "Dostali ste sa na štartovaciu zónu!",
		activity_ended: "Aktivita sa skončila",
		controller_push_score: "Za úlohu %s vám kontrolór udelil %s bodov.",
		ask_require_key_title: "Na spustenie tohto kontrolného bodu spotrebujete %s žetónov.",
		ongoing: "Prebieha",
		available: "K dispozícii",
		finished: "Dokončené",
		change_server: "Zmeniť server",
		timer_run_out: "Časovač vypršal",
		tokens: "Žetóny",
		correct_feedback: "Výborne!",
		partial_correct_feedback: "Dobrá práca!",
		feedback_wrong: "Nabudúce budete mať viac šťastia!",
		message: "Správy",
		controller_chat: "Správy kontrolóra",
		activity_chat: "Správy o aktivite",
		one_to_one_team_chat: "Priame správy",
		add_team: "Priama správa",
		controller: "Kontrolór",
		search: "Vyhľadávanie",
		max_attempts_try_one: "Vaša odpoveď je nesprávna. Zostáva vám %s pokusov",
		max_attempts_try_other: "Vaša odpoveď je nesprávna. Minuli ste %s pokusov",
		move_in_the_activity_boundary: "Pohybujte sa v hernej zóne",
		download_limit_reached: "bol dosiahnutý limit sťahovania",
		welcome_to: "Vitajte. Toto je %s",
		your_name: "Vaše meno",
		please_input_your_name: "Prosím zadajte vaše meno",
		please_take_photo: "Teraz sa odfoťte.",
		team_name: "Názov tímu",
		input_team_description: "Dohodnite sa na názve vášho tímu a zvoľte si kapitána.",
		are_you_captain: "Ste kapitán?",
		please_input_team_name: "Prosím zadajte vaše tímové meno",
		waiting_players: "Čakáme na pripojenie všetkých hráčov.",
		lobby: "Lobby",
		ready: "Pripravený",
		captain: "Kapitán",
		please_wait: "Prosím čakajte…",
		refresh_page: "Obnoviť stránku",
		show_other_players: "Ukážte ostatných hráčov",
		warning: "Pozor",
		finish_activity_confirm: "K aktivite sa nebudete môcť vrátiť. Ste si istí, že chcete s touto aktivitou skončiť?",
		end: "Koniec",
		return_to_activity: "Návrat k aktivite",
		paste_from_clipboard: "Prilepiť snímku obrazovky",
		team_chat: "Tímové správy"
	},
	"si": {
		go_team: "Go Team",
		north: "sever",
		north_east: "severovzhod",
		east: "vzhod",
		south_east: "jugovzhod",
		south: "jug",
		south_west: "jugozahod",
		west: "zahod",
		north_west: "severozahod",
		ongoing_activities: "Aktivnosti v izvajanju",
		downloaded_activities: "Prenesene Aktivnosti",
		finished_activities: "Končane aktivnosti",
		download: "Prenos",
		play_now: "Začetek",
		cont: "Nadaljuj",
		cancel: "Prekliči",
		done: "Končano",
		later: "Kasneje",
		close: "Zapri",
		ok: "V redu",
		delete_activity: "Izbriši aktivnost?",
		do_you_want_to_delete: "Ali želite izbrisati?",
		delete: "Izbriši",
		play: "Igraj",
		error: "Napaka",
		loading: "Nalaganje",
		confirm: "Potrdi",
		downloading: "prenašanje",
		launch: "Zagon",
		enter_you_name: "Izberite ime",
		enter_name_hint: "Ime vaše ekipe",
		ask_name_description: "Ime vaše ekipe vas bo identificiralo v aktivnosti",
		password: "Geslo",
		enter_activity_password: "Prosimo, vnesite geslo za to aktivnost",
		licensee_password: "Prosimo, vnesite geslo za ta strežnik",
		incorrect_password: "Geslo ni pravilno",
		enter_password_hint: "Vaše geslo",
		activity_search: "Išči aktivnost",
		qr_code_not_recognised: "QR koda ni prepoznana",
		scan_qr_code: "Skeniraj QR kodo",
		activity_not_found: "Aktivnost ni najdena",
		do_you_want_to_go_home: "Ali ste prepričani, da se želite vrniti na domači zaslon?",
		you_score_point_key: "Ker ste uspešno prišli na lokacijo, ste prejeli %s točk in %s ključ.",
		award_point_arrival: "Ker ste uspešno prišli na lokacijo, ste prejeli %s točk.",
		award_key_arrival: "Ker ste uspešno prišli na lokacijo, ste prejeli %s ključ.",
		the_start_area: "štartno območje",
		the_finish_area: "ciljno območje",
		forbidden_area: "Prepovedano območje",
		choose_a_way: "Izberite pot",
		you_enter_forbidden_area: "Vstopili ste na prepovedano območje",
		please_move_to_activity_zone: "Premaknite se na štartno območje",
		next_check_point_distance: "Naslednja kontrolna točka se nahaja %s od tu",
		finish_zone_distance: "Končno območje se nahaja %s od tu",
		settings: "Nastavitve",
		meters_feet: "metrično - anglosaško",
		sounds: "Zvoki",
		language: "Jezik",
		key_required: "Za vstop na kontrolno točko nimate pravilnega ključa. Kontrolna točka zahteva %s ključ.",
		set_destination: "Nastavite destinacijo",
		home: "Domov",
		chat: "Klepet",
		you_score_point: "Dosegli ste %s točk.",
		you_score_point_token: "Dosegli ste %s točk in prejeli %s ključ.",
		controller_score: "Od kontrolorja ste prejeli %s točk za %s kontrolno točko.",
		controller_key: "Od kontrolorja ste prejeli %s ključ.",
		award_key_route: "Ker ste izbrali to pot, ste prejeli %s ključ.",
		award_point_route: "Ker ste izbrali to pot, ste prejeli %s točk.",
		award_point_key_route: "Prejeli ste %s točk in %s ključ, ker ste izbrali to pot.",
		task: "Naloga",
		back: "Nazaj",
		text_answer: "Tekstovni odgovor",
		numeric_answer: "Številčni odgovor",
		pass: "Preskoči",
		submit: "Oddaj",
		take_photo: "Naložite sliko",
		choose_photo: "Izberite fotografijo",
		record_video: "Posnemite video",
		play_video: "Predvajaj video",
		choose_video: "Naloži video",
		person_at_checkpoint: "Predajte tablico trenerju",
		enter_password: "Vpiši geslo",
		enter_score: "Vpiši rezultat / %s",
		player_answer: "Odgovor igralca",
		pass_task_confirm: "Če izberete Preskoči, ne boste prejeli točk. Potrdite, da želite izbrati Preskoči.",
		invalid_score: "Rezultat ni veljaven",
		submit_answer_alert: "Vaš odgovor je bil poslan v ocenjevanje. Rezultat boste prejeli v kratkem.",
		ranking: "Razvrstitev",
		name: "Ime",
		score: "Čas",
		time: "Rezultat",
		rank: "Uvrstitev",
		end_zone_distance: "Ciljno območje %s",
		start_zone_distance: "Štartno območje %s",
		next_checkpoint_distance: "Naslednja kontrolna točke %s",
		send: "Pošlji",
		enter_message: "Novo sporočilo",
		today: "danes",
		notification: "OBVESTILA",
		finish_activity_alert: "Čestitamo, končali ste aktivnost",
		finish_activity: "Končaj aktivnost",
		partial_correct_answer: "Delno pravilen odgovor",
		correct_answer: "Pravilen odgovor",
		incorrect_answer: "Nepravilen odgovor",
		qr_code_not_supported: "Bralnik QR kode ni podprt na vaši napravi",
		camera_not_supported: "Kamera ni podprta na vaši napravi",
		photo_not_supported: "Fotogalerija ni podprta na vaši napravi",
		gps_not_available: "To je GPS aktivnost. Aktivirajte lokacijske storitve in poskusite ponovno.",
		unknown_error: "Nekaj ne deluje pravilno. Prosimo, poskusite ponovno ali prosite za pomoč trenerje.",
		no_internet: "Preverite internetno povezavo",
		no_available_route: "Ne izpolnjujete nobenega od pogojev za nobeno od poti do naslednje točke, zato ste bili vrnjeni na zemeljvid.",
		scan: "Skeniraj",
		magnetic_sensor_not_found: "Na vaši napravi nismo našli magnetnega senzorja. Kompas ne bo deloval.",
		on_demand_task: "Naloge na zahtevo",
		company_questions: "Vprašanja stranke",
		photo_challenges: "Foto izzivi",
		general_challenges: "Splošni izzivi",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Aktivnost se je zaključila",
		ask_require_key_title: "Zagon naloge bo porabil",
		available: "Na voljo",
		change_server: "Spremeni strežnik",
		controller_push_score: "Za %s nalogo ste prejeli %s točk s strani kontrolorja",
		finished: "Končano",
		ongoing: "V izvajanju",
		return_to_task: "Vrnite se na nalogo",
		warning_no_photo: "Niste posneli fotografije! Ali ste prepričani, da želite oddati brez fotografije?",
		warning_no_video: "Niste posneli videa! Ali ste prepričani, da želite oddati brez videa?",
		you_enter_start_area: "Prišli ste na štartno območje",
		tokens: "Ključi",
		activity_chat: "Sporočila vsem",
		message: "Klepet",
		controller_chat: "Sporočila baze",
		one_to_one_team_chat: "Sporočila med ekipami",
		add_team: "Sporočilo ekipi",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Odlično.",
		partial_correct_feedback: "Dobro opravljeno.",
		feedback_wrong: "Več sreče prihodnjič.",
		max_attempts_try_one: "Vaš odgovor ni pravilen. Na voljo imate še %s poskus.",
		max_attempts_try_other: "Vaš odgovor ni pravilen. Na voljo imate še %s poskusov.",
		download_limit_reached: "Dosežena omejitev prenosa",
		timer_run_out: "Časovnika zmanjka",
		controller: "Controller",
		search: "Search",
		welcome_to: "Dobrodošli na %s",
		your_name: "Vaše ime",
		please_input_your_name: "Vnesite vaše ime.",
		please_take_photo: "Prosim, posnamite fotografijo za vaš avatar.",
		team_name: "Ime ekipe",
		input_team_description: "Z ekipo izberite vodjo.",
		are_you_captain: "Ali ste vodja ekipe?",
		please_input_team_name: "Prosim vnesite ime ekipe.",
		waiting_players: "Čakamo na ostale udeležence, da se pridružijo.",
		lobby: "Čakalnica",
		ready: "Pripravljeni",
		captain: "Vodja",
		please_wait: "Prosim počakajte…",
		refresh_page: "Osveži stran",
		show_other_players: "Pokažite drugim igralcem",
		warning: "Opozorilo",
		finish_activity_confirm: "Ne boste se mogli vrniti k dejavnosti. Ali ste prepričani, da želite končati to dejavnost?",
		end: "Konec",
		return_to_activity: "Nazaj na dejavnost",
		paste_from_clipboard: "Prilepi posnetek zaslona",
		team_chat: "Timsko sporočanje"
	},
	"es": {
		go_team: "Go Team",
		north: "Norte",
		north_east: "Noreste",
		east: "Este",
		south_east: "Sureste",
		south: "Sur",
		south_west: "Suroeste",
		west: "Oeste",
		north_west: "Noroeste",
		ongoing_activities: "Actividades en Curso",
		downloaded_activities: "Actividades Descargadas",
		finished_activities: "Actividades Terminadas",
		download: "Descargar",
		play_now: "Inicio",
		cont: "Continuar",
		cancel: "Cancelar",
		done: "Listo",
		later: "Después",
		close: "Cerrar",
		ok: "OK",
		delete_activity: "Eliminar Actividad?",
		do_you_want_to_delete: "Seguro que desea eliminar?",
		delete: "Eliminar",
		play: "Jugar",
		error: "Error",
		loading: "Cargando",
		confirm: "Confirmar",
		downloading: "Descargando",
		launch: "Lanzamiento",
		enter_you_name: "Elija un nombre",
		enter_name_hint: "Nombre de usuario",
		ask_name_description: "Nombre de suario se utilizará para identificarlo en la actividad",
		password: "Contraseña",
		enter_activity_password: "Por favor introducir la clave para esta actividad",
		licensee_password: "Por favor introducir la clave para este servidor",
		incorrect_password: "La clave es incorrecta",
		enter_password_hint: "Su clave",
		activity_search: "Búsqueda de actividad",
		qr_code_not_recognised: "Código QR no reconocido",
		scan_qr_code: "Escanee código QR",
		activity_not_found: "Actividad no encontrada",
		do_you_want_to_go_home: "Está seguro que quiere salir de la actividad y regresar a la pantalla de inicio?",
		you_score_point_key: "Recibió %s puntos y %s token por alcanzar este punto de control",
		award_point_arrival: "Recibió %s puntos por alcanzar este punto de control",
		award_key_arrival: "Recibió %s token por alcanzar este punto de control",
		the_start_area: "Zona de Inicio",
		the_finish_area: "Zona final",
		forbidden_area: "Área restringida",
		choose_a_way: "Elija un camino",
		you_enter_forbidden_area: "Ha entrado a una zona restringida",
		please_move_to_activity_zone: "Colóquese en la zona de inicio",
		next_check_point_distance: "El próximo checkpoint se localiza a %s de aquí",
		finish_zone_distance: "La zona final se localiza a %s de aquí",
		settings: "Configuración",
		meters_feet: "Metrica - Imperial",
		sounds: "Sonido",
		language: "Lenguaje",
		key_required: "No tiene el token correcto para iniciar este checkpoint, este checkpoint requiere el token %s",
		set_destination: "Elija destino",
		home: "Casa / Punto de inicio",
		chat: "Chatear",
		you_score_point: "Ha anotado %s puntos",
		you_score_point_token: "Ha anotado %s puntos y ha recibido %s token",
		controller_score: "Ha anotado %s puntos del controlador para %s checkpoint",
		controller_key: "Recibió %s token desde el controlador",
		award_key_route: "Recibió %s token por tomar esta ruta",
		award_point_route: "Recibió %s puntos por tomar esta ruta",
		award_point_key_route: "Recibió %s puntos y %s token por tomar esta ruta",
		task: "Tarea",
		back: "Atrás",
		text_answer: "Escriba la respuesta",
		numeric_answer: "Respuesta númerica",
		pass: "Pasar",
		submit: "Enviar",
		take_photo: "Subir una imagen",
		choose_photo: "Elija foto",
		record_video: "Grabar video",
		play_video: "Inicio video",
		choose_video: "Subir video",
		person_at_checkpoint: "Entregar la tablet al staff del evento",
		enter_password: "Introducir la contraseña",
		enter_score: "Escriba el puntaje / %s",
		player_answer: "Respuesta del jugador",
		pass_task_confirm: "No recibirá ningún punto si pasa. Confirme que quiere pasar.",
		invalid_score: "Puntaje no válido",
		submit_answer_alert: "Su respuesta ha sido enviada para ser revisada. Recibirá una puntuación en breve",
		ranking: "Tabla de posiciones / Ranking",
		name: "Nombre",
		score: "Tiempo",
		time: "Puntaje",
		rank: "Rango",
		end_zone_distance: "Zona Final %s",
		start_zone_distance: "Zona de Inicio %s",
		next_checkpoint_distance: "El siguiente checkpoint %s",
		send: "Enviar",
		enter_message: "Nuevo mensaje",
		today: "Hoy",
		notification: "Notificación",
		finish_activity_alert: "Felicitaciones, usted ha terminado la actividad",
		finish_activity: "Terminar actividad",
		partial_correct_answer: "Respuesta parcialmente correcta",
		correct_answer: "¡Respuesta correcta!",
		incorrect_answer: "¡Respuesta incorrecta!",
		qr_code_not_supported: "El escaner de códigos QR no es compatible con su equipo",
		camera_not_supported: "La cámara no es compatible con su equipo",
		photo_not_supported: "La galería de fotos no es compatible con su equipo",
		gps_not_available: "Esta es una actividad con GPS. Habilitar servicios de ubicación e intentar de nuevo",
		unknown_error: "Algo salió mal. Inténtelo de nuevo o póngase en contacto con el staff del evento para obtener asistencia.",
		no_internet: "Por favor revise su conexion de internet",
		no_available_route: "No tiene los requerimientos para ninguna de las rutas de siguiente punto de control, por lo que debes regresar al mapa",
		scan: "Escanear",
		magnetic_sensor_not_found: "No se puede encontrar el sensor magnético en su dispositivo. la brújula no funcionará.",
		on_demand_task: "Tarea a pedido",
		company_questions: "Preguntas de la empresa",
		photo_challenges: "Desafíos Fotográficos",
		general_challenges: "Desafíos Generales",
		new_check_point_found: "Acaba de encontrar el checkpoint %s, quiere ACTIVAR el checkpoint %s or CANCELAR y continuar hacia el checkpoint %s?",
		activity_ended: "Actividad terminada",
		ask_require_key_title: "La activación de este checkpoint consumirá",
		available: "Disponible",
		change_server: "Cambiar de servidor",
		controller_push_score: "El administrador le ha otorgado %s puntos por la tarea %s.",
		finished: "Finalizado",
		ongoing: "En marcha",
		return_to_task: "Regresar a la tarea",
		warning_no_photo: "No ha tomado la foto, ¿está seguro de que desea enviarla sin la foto?",
		warning_no_video: "No ha grabado el video, ¿está seguro de que desea enviarlo sin el video?",
		you_enter_start_area: "¡Ha llegado al área de inicio!",
		move_in_the_activity_boundary: "Moverse dentro de los límites de la actividad",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "¡Mejor suerte la próxima!",
		tokens: "Tokens",
		activity_chat: "Mensajes de actividad",
		message: "Mensajería",
		controller_chat: "Mensajes del controlador",
		one_to_one_team_chat: "Mensajería Directa",
		add_team: "Mensaje directo",
		max_attempts_try_one: "Su respuesta es incorrecta. Usted tiene un %s de intentos restantes",
		max_attempts_try_other: "Su respuesta es incorrecta. Usted tiene un %s de intentos restantes",
		download_limit_reached: "Límite de descarga alcanzado",
		timer_run_out: "Se acabó el tiempo",
		controller: "Controller",
		search: "Search",
		welcome_to: "Bienvenido a %s",
		your_name: "Su Nombre",
		please_input_your_name: "Por favor introduzca su nombre.",
		please_take_photo: "Por favor tome una foto para su avatar",
		team_name: "Nombre del Equipo",
		input_team_description: "Con su equipo, decida el nombre de su equipo y seleccione quién será el capitán del equipo.",
		are_you_captain: "Es usted el capitán del equipo?",
		please_input_team_name: "Por favor introduzca el nombre de su equipo.",
		waiting_players: "Esperando que se unan todos.",
		lobby: "Sala de Espera",
		ready: "Listo",
		captain: "Capitán",
		please_wait: "Por favor espere…",
		refresh_page: "Actualizar página",
		show_other_players: "Mostrar otros jugadores",
		warning: "Advertencia",
		finish_activity_confirm: "No podrás volver a la actividad. ¿Estás seguro de que deseas finalizar esta actividad?",
		end: "Fin",
		return_to_activity: "Regresar a la actividad",
		paste_from_clipboard: "Pegar captura de pantalla",
		team_chat: "Mensajes de equipo"
	},
	"es-sa": {
		go_team: "Go Team",
		north: "norte",
		north_east: "Noreste",
		east: "Este",
		south_east: "Sur este",
		south: "Sur",
		south_west: "Sur oeste",
		west: "Oeste",
		north_west: "noroeste",
		ongoing_activities: "Actividades en Curso",
		downloaded_activities: "Actividades Descargadas",
		finished_activities: "Actividades terminadas",
		download: "Descargar",
		play_now: "Comienzo",
		cont: "continue",
		cancel: "Cancelar",
		done: "Completado",
		later: "Despúes",
		close: "Cerca",
		ok: "OK",
		delete_activity: "Eliminar la actividad?",
		do_you_want_to_delete: "Quieres eliminar?",
		delete: "Eliminar",
		play: "Jugar",
		error: "Error",
		loading: "Cargando",
		confirm: "Confirmar",
		downloading: "Descargando",
		launch: "Lanzamiento",
		enter_you_name: "Elija un nombre",
		enter_name_hint: "Tu nombre de usuario",
		ask_name_description: "Tu nombre será usado para identificarte en la actividad",
		password: "Contraseña",
		enter_activity_password: "Ingrese la contaseña para continuar",
		licensee_password: "Por favir introduccir la clave para este servidor",
		incorrect_password: "La clave es incorrecta",
		enter_password_hint: "Su clave",
		activity_search: "Búsqueda de actividad",
		qr_code_not_recognised: "Código QR no reconocido",
		scan_qr_code: "Escanee código QR",
		activity_not_found: "Actividad no encontrada",
		do_you_want_to_go_home: "Está seguro que quiere salir de la actividad y regresar a la pantalla de inicio?",
		you_score_point_key: "Recibió %s puntos y %s token por alcanzar este punto de control",
		award_point_arrival: "Recibiste %s puntos por alcanzar este punto de control",
		award_key_arrival: "Recibiste %s token por alcanzar este punto de control",
		the_start_area: "Zona de Inicio",
		the_finish_area: "Zona de termino",
		forbidden_area: "Área Prohibida",
		choose_a_way: "Elige un camino",
		you_enter_forbidden_area: "Usted entro en una zona prohibida",
		please_move_to_activity_zone: "Muevase a la zona de inicio",
		next_check_point_distance: "El próximo checkpoint se localiza %s desde aquí",
		finish_zone_distance: "La zona de termino se localiza %s desde aquí",
		settings: "Configuración",
		meters_feet: "Metrica - Imperial",
		sounds: "Sonido",
		language: "Lenguaje",
		key_required: "You do not have the correct token to launch this checkpoint, this checkpoint requires the %s token",
		set_destination: "Elija destino",
		home: "Inicio",
		chat: "Chatear",
		you_score_point: "Has anotado %s puntos",
		you_score_point_token: "Has anotado %s puntos y has recibido %s token",
		controller_score: "El controlador te ha dado %s puntos por el %s puntos de control",
		controller_key: "Recibió %s token desde el controlador",
		award_key_route: "Recibió %s token por tomar esta ruta",
		award_point_route: "Recibió %s puntos por tomar esta ruta",
		award_point_key_route: "Recibió %s puntos y %s token por tomar esta ruta",
		task: "Tarea",
		back: "Atrás",
		text_answer: "Escriba la respuesta",
		numeric_answer: "Respuesta númerica",
		pass: "Pasar",
		submit: "Enviar",
		take_photo: "Subir una imagen",
		choose_photo: "Elija foto",
		record_video: "Grabar video",
		play_video: "Inicio video",
		choose_video: "Subir video",
		person_at_checkpoint: "Entregar la tablet al staff del evento",
		enter_password: "Introducir la contraseña",
		enter_score: "Escriba Score / %s",
		player_answer: "Respuesta del jugador",
		pass_task_confirm: "No recibirá ningún punto si pasa. Confirme que quiere pasar.",
		invalid_score: "Puntaje no valido",
		submit_answer_alert: "Su respuesta ha sido sometida para ser revisada. Recibirá una puntuación en breve",
		ranking: "Tabla de posiciones / Ranking",
		name: "Nombre",
		score: "Tiempo",
		time: "Puntaje",
		rank: "Rango",
		end_zone_distance: "Zona de termino %s",
		start_zone_distance: "Zona de Inicio %s",
		next_checkpoint_distance: "El siguiente punto de control %s",
		send: "Enviar",
		enter_message: "Nuevo mensaje",
		today: "Hoy",
		notification: "Notificación",
		finish_activity_alert: "Felicitaciones, usted ha termiando la actividad",
		finish_activity: "Termino de la actividad",
		partial_correct_answer: "Respuesta parcialmente correcta",
		correct_answer: "¡Respuesta correcta!",
		incorrect_answer: "¡Respuesta incorrecta!",
		qr_code_not_supported: "Escaneo de codigos QR no es compatible con su equipo",
		camera_not_supported: "La camara no es compatible con su equipo",
		photo_not_supported: "La galeria de fotos no es compatible con su equipo",
		gps_not_available: "Esta es una actividad con GPS. Habilitar servicios de ubicación e intentar de nuevo",
		unknown_error: "Algo salió mal. Inténtelo de nuevo o póngase en contacto con el staff del evento para obtener asistencia.",
		no_internet: "Por favor revise su conexion de internet",
		no_available_route: "No tiene los requerimientos para ninguna de las rutas de siguiente punto de control, por lo que debes regresar al mapa",
		scan: "Escanear",
		magnetic_sensor_not_found: "No se puede encontrar el sensor magnético en su dispositivo. la brújula no funcionará.",
		on_demand_task: "Tarea a demanda",
		company_questions: "Preguntas de la empresa",
		photo_challenges: "Desafíos Fotográficos",
		general_challenges: "Desafíos Generales",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activity ended",
		ask_require_key_title: "Launching this checkpoint will consume",
		available: "Available",
		change_server: "Change server",
		controller_push_score: "You scored %s points from the controller for %s task",
		finished: "Finished",
		ongoing: "Ongoing",
		return_to_task: "Return To Task",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		you_enter_start_area: "You have reached the start area!",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		tokens: "Tokens",
		activity_chat: "Actividad de Mensajería",
		message: "Mensajería",
		controller_chat: "Controlador de Mensajería",
		one_to_one_team_chat: "Mensajería Directa",
		add_team: "Mensaje directo",
		max_attempts_try_one: "Su respuesta es incorrecta. Usted tiene un %s de intentos restantes",
		max_attempts_try_other: "Su respuesta es incorrecta. Usted tiene un %s de intentos restantes",
		download_limit_reached: "Límite de descarga alcanzado",
		timer_run_out: "Se acabó el tiempo",
		controller: "Controller",
		search: "Search",
		welcome_to: "Bienvenido a %s",
		your_name: "Tu nombre",
		please_input_your_name: "Porfavor ingresa tu nombre",
		please_take_photo: "Tómate una foto en modo selfie.",
		team_name: "Nombre del Equipo",
		input_team_description: "Junto con tus compañeros escojan cual será el nombre de su equipo y también designen quien será el capitán.",
		are_you_captain: "¿Eres tú el capitán?",
		please_input_team_name: "Porfavor ingresa el nombre de tu equipo",
		waiting_players: "Esperando a que otros jugadores se unan.",
		lobby: "Lobby",
		ready: "Listos",
		captain: "Capitán",
		please_wait: "Porfavor espera…",
		refresh_page: "Actualizar página",
		show_other_players: "Mostrar otros jugadores",
		warning: "Advertencia",
		finish_activity_confirm: "No podrás volver a la actividad. ¿Estás seguro de que deseas finalizar esta actividad?",
		end: "Fin",
		return_to_activity: "Regresar a la actividad",
		paste_from_clipboard: "Pegar captura de pantalla",
		team_chat: "Mensajes de equipo"
	},
	"se": {
		go_team: "Go Team",
		north: "Norr",
		north_east: "Nordost",
		east: "Öst",
		south_east: "Sydost",
		south: "Syd",
		south_west: "Sydväst",
		west: "Väst",
		north_west: "Nordväst",
		ongoing_activities: "Aktuella Aktiviteter",
		downloaded_activities: "Nedladdade Aktiviteter",
		finished_activities: "Genomförda Aktiviteter",
		download: "Download",
		play_now: "Börja",
		cont: "Fortsätt",
		cancel: "Avbryt",
		done: "Färdig",
		later: "Senare",
		close: "Stänga",
		ok: "OK",
		delete_activity: "Ta bort aktivitet?",
		do_you_want_to_delete: "Vill du ta bort?",
		delete: "Ta bort aktivitet?",
		play: "Spela",
		error: "Fel",
		loading: "Loading",
		confirm: "Bekräfta",
		downloading: "downloading",
		launch: "Kör",
		enter_you_name: "Välj ett namn",
		enter_name_hint: "Ditt användarnamn",
		ask_name_description: "Ditt användarnamn används för att identifiera dig i aktiviteten",
		password: "Lösord",
		enter_activity_password: "Ange lösenordet för denna aktivitet",
		licensee_password: "Ange lösenordet för denna server",
		incorrect_password: "Detta lösenord är inte korrekt",
		enter_password_hint: "Ditt lösenord",
		activity_search: "Aktivitetssök",
		qr_code_not_recognised: "QR Kode inte identifieras",
		scan_qr_code: "Scan QR Kode",
		activity_not_found: "Aktivitet hittades inte",
		do_you_want_to_go_home: "Är du säker på att du vill avsluta verksamheten och återgå till hemskärmen?",
		you_score_point_key: "Du fick %s points och %s token för att uppnå detta checkpoint",
		award_point_arrival: "Du fick %s points för att uppnå detta checkpoint",
		award_key_arrival: "Du fick %s token för att uppnå detta checkpoint",
		the_start_area: "startzonen",
		the_finish_area: "ändzonen",
		forbidden_area: "Begränsad zon",
		choose_a_way: "Välj en väg",
		you_enter_forbidden_area: "Du går in i en restriktionszon",
		please_move_to_activity_zone: "Flytta til startzonen",
		next_check_point_distance: "Nästa checkpoint ligger %s härifrån",
		finish_zone_distance: "Ändzonen ligger %s härifrån",
		settings: "Inställningar",
		meters_feet: "Metriska - Imperial",
		sounds: "Ljud",
		language: "Språk",
		key_required: "Du har inte den korrekta token att lansera detta checkpoint, detta checkpoin kräver en %s token",
		set_destination: "Välj destination",
		home: "Hem",
		chat: "Chat",
		you_score_point: "Du fick %s points",
		you_score_point_token: "Du fick %s points och du fick en %s token",
		controller_score: "Du fick %s points fra kontrollern for %s checkpoint",
		controller_key: "Du fick %s token fra kontrollern",
		award_key_route: "Du fick %s token för att ha tagit denna rutt",
		award_point_route: "Du fick %s points för att ha tagit denna rutt",
		award_point_key_route: "Du fick %s points og em %s token för att ha tagit denna rutt",
		task: "Uppgift",
		back: "Tillbaka",
		text_answer: "SMS svar",
		numeric_answer: "Numerisk svar",
		pass: "Hoppa över",
		submit: "Skicka",
		take_photo: "Ladda upp en bild",
		choose_photo: "Välj Bild",
		record_video: "Spela in Video",
		play_video: "Spela Videon",
		choose_video: "Upload Video",
		person_at_checkpoint: "Ge tablett till händelsens personal",
		enter_password: "Indtast lösenord",
		enter_score: "Indtast poäng / %s",
		player_answer: "Spiller Svar",
		pass_task_confirm: "Du kommer inte att få några poäng om du hoppar över. Bekräfta att du vill hoppa över.",
		invalid_score: "Poäng ogiltigt",
		submit_answer_alert: "Ditt svar har skickats och skall ses över. Du kommer att få din poäng.",
		ranking: "Rankning",
		name: "Namn",
		score: "Tid",
		time: "Poäng",
		rank: "Rankning",
		end_zone_distance: "Ändzon %s",
		start_zone_distance: "Startzon %s",
		next_checkpoint_distance: "Nästa checkpoint %s",
		send: "Enviar",
		enter_message: "Nytt meddelande",
		today: "i dag",
		notification: "anmälan",
		finish_activity_alert: "Grattis, du har avslutade den här aktiviteten",
		finish_activity: "Avslutta aktivitet",
		partial_correct_answer: "Delvis rätt svar",
		correct_answer: "Rätt svar",
		incorrect_answer: "Felaktiga svar",
		qr_code_not_supported: "QR-kod scanner stöds inte av den aktuella enheten",
		camera_not_supported: "Kameran stöds inte av den aktuella enheten",
		photo_not_supported: "Fotogalleriet stöds inte av den aktuella enheten",
		gps_not_available: "Detta är en GPS-aktivitet. Aktivera platstjänster och försök igen",
		unknown_error: "Något gick fel. Försök igen eller kontakta händelsens team för att få hjälp.",
		no_internet: "Kontrollera din Internet-anslutning",
		no_available_route: "You do not have the requirements for any of the routes to the next checkpoint, so you have been returned to the map",
		scan: "Scan",
		magnetic_sensor_not_found: "Magnetic sensor cannot be found on your device. Compass will not work.",
		on_demand_task: "On Demand Task",
		company_questions: "Company Questions",
		photo_challenges: "Photo Challenges",
		general_challenges: "General Challenges",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activity ended",
		ask_require_key_title: "Launching this checkpoint will consume",
		available: "Available",
		change_server: "Change server",
		controller_push_score: "You scored %s points from the controller for %s task",
		finished: "Finished",
		ongoing: "Ongoing",
		return_to_task: "Return To Task",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		you_enter_start_area: "You have reached the start area!",
		tokens: "Tokens",
		activity_chat: "Activity messaging",
		message: "Messaging",
		controller_chat: "Controller messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "Nedladdningsgränsen har uppnåtts",
		timer_run_out: "Timern tar slut",
		controller: "Controller",
		search: "Search",
		welcome_to: "Välkommen till %s",
		your_name: "Ditt namn",
		please_input_your_name: "Vänligen ange ditt namn",
		please_take_photo: "Ta en bild till din avatar.",
		team_name: "Teamnamn",
		input_team_description: "Med ditt lag, bestäm ett teamnamn och välj vem som kommer att vara lagkapten.",
		are_you_captain: "Är du lagkapten?",
		please_input_team_name: "Ange ditt lagnamn.",
		waiting_players: "Programmet väntar på att alla spelare har gått med i spelet",
		lobby: "Lobby",
		ready: "Färdig",
		captain: "Kapten",
		please_wait: "Vänta…",
		refresh_page: "Uppdatera sida",
		show_other_players: "Visa andra spelare",
		warning: "Varning",
		finish_activity_confirm: "Du kommer inte att kunna återgå till aktiviteten. Är du säker på att du vill avsluta den här aktiviteten?",
		end: "Slutet",
		return_to_activity: "Återgå till aktivitet",
		paste_from_clipboard: "Klistra in skärmdump",
		team_chat: "Teammeddelanden"
	},
	"tr": {
		go_team: "Go Team",
		north: "Kuzey",
		north_east: "Kuzey Doğu",
		east: "Doğu",
		south_east: "Güney Doğu",
		south: "Güney",
		south_west: "Güney Batı",
		west: "Batı",
		north_west: "Kuzey Batı",
		ongoing_activities: "Devam eden aktiviteler",
		downloaded_activities: "İndirildi aktviteler",
		finished_activities: "Biten aktiviteler",
		download: "İndir",
		play_now: "Başla",
		cont: "Devam",
		cancel: "Çıkış",
		done: "Bitti",
		later: "Sonra",
		close: "Kapat",
		ok: "Tamam",
		delete_activity: "Aktiviteyi sil?",
		do_you_want_to_delete: "Silmek istiyormusunuz?",
		delete: "Sil",
		play: "Oyna",
		error: "Hata",
		loading: "Yükleniyor",
		confirm: "Onayla",
		downloading: "İndiriliyor",
		launch: "Başlat",
		enter_you_name: "Bir İsim Seç",
		enter_name_hint: "Kullanıcı Adı",
		ask_name_description: "Kullanıcı adınız sizi aktivitede tanımlamak  için kullanılacaktır.",
		password: "Şifre",
		enter_activity_password: "Bu aktivite için şifre giriniz.",
		licensee_password: "Bu sunucu için şifre giriniz.",
		incorrect_password: "Şifre yanlış",
		enter_password_hint: "Şifreniz",
		activity_search: "Aktivite Araması",
		qr_code_not_recognised: "QR Kod tanınmadı",
		scan_qr_code: "QR Kodu tarayın",
		activity_not_found: "Aktivite bulunamadı",
		do_you_want_to_go_home: "Aktiviteden çıkıp ana ekrana dönmek istediğinizden eminmisiniz?",
		you_score_point_key: "Bu kontrol noktasına ulaştığınız için %s puan ve %s jeton aldınız",
		award_point_arrival: "Bu kontrol noktasına ulaştığınız için %s puan aldınız",
		award_key_arrival: "Bu kontrol noktasına ulaştığınız için %s jetonu aldınız",
		the_start_area: "Başlangıç Noktası",
		the_finish_area: "Bitiş Noktası",
		forbidden_area: "Yasak Bölge",
		choose_a_way: "Bir yol seçiniz",
		you_enter_forbidden_area: "Yasaklı bir bölgeye girdiniz",
		please_move_to_activity_zone: "Başlangıç noktasına gidiniz",
		next_check_point_distance: "Bir sonraki kontrol noktası buradan %s konumunda",
		finish_zone_distance: "Bitiş Noktası buradan %s konumundadır",
		settings: "Ayarlar",
		meters_feet: "Metrik - İngiliz",
		sounds: "Sesler",
		language: "Dil",
		key_required: "Bu kontrol noktasını başlatmak için doğru jetona sahip değilsiniz, bu kontrol noktası %s jetonunu gerektiriyor",
		set_destination: "Hedefi belirleyin",
		home: "Ana Ekran",
		chat: "Sohbet",
		you_score_point: "%s puan kazandınız",
		you_score_point_token: "%s puan kazandınız ve %s jeton aldınız",
		controller_score: "%s Kontrol noktası için yöneticiden %s puan aldınız",
		controller_key: "Yöneticiden %s jetonu aldınız",
		award_key_route: "Bu rotayı kullandığınız için %s jetonu aldınız",
		award_point_route: "Bu rotayı kullandığınız için %s puan aldınız",
		award_point_key_route: "Bu rotayı kullandığınız için %s puan ve %s jeton aldınız",
		task: "Görev",
		back: "Geri",
		text_answer: "Metin yanıtı",
		numeric_answer: "Sayısal yanıt",
		pass: "Geç",
		submit: "Gönder",
		take_photo: "Fotoğraf çek",
		choose_photo: "Fotoğraf seç",
		record_video: "Video kaydet",
		play_video: "Video oynat",
		choose_video: "Video yükle",
		person_at_checkpoint: "Tableti etkinlik yöneticisine teslim edin",
		enter_password: "Şifre girin",
		enter_score: "Puanı girin / %s",
		player_answer: "Oyuncu cevabı",
		pass_task_confirm: "Geçerseniz puan alamayacaksınız.Geçmek istediğinizi onaylayın.",
		invalid_score: "Puan geçerli değil",
		submit_answer_alert: "Cevabınız incelenmek üzere gönderildi",
		ranking: "Sıralama",
		name: "İsim",
		time: "Süre",
		score: "Puan",
		rank: "Sıra ",
		end_zone_distance: "Bitiş Noktası %s",
		start_zone_distance: "Başlangıç Noktası %s",
		next_checkpoint_distance: "Sonraki Kontrol Noktası %s",
		send: "Gönder",
		enter_message: "Yeni Mesaj",
		today: "Bugün",
		notification: "Bildirim",
		finish_activity_alert: "Tebrikler bu aktiviteyi bitirdiniz.",
		finish_activity: "Aktiviteyi Bitir",
		partial_correct_answer: "Kısmen doğru cevap",
		correct_answer: "Doğru Cevap",
		incorrect_answer: "Yanlış Cevap",
		qr_code_not_supported: "QR Kod Tarayıcı mevcut cihaz tarafından desteklenmiyor",
		camera_not_supported: "Kamera mevcut cihaz tarafından desteklenmiyor",
		photo_not_supported: "Fotoğraf galerisi mevcut cihaz tarafından desteklenmiyor",
		gps_not_available: "Bu bir GPS etkinliği. Konum hizmetlerini etkinleştirin ve tekrar deneyin",
		unknown_error: "Bir şeyler yanlış gitti. Lütfen tekrar deneyin veya destek için etkinlik ekibiyle iletişime geçin",
		no_internet: "Lütfen internet bağlantınızı kontrol edin",
		no_available_route: "Bir sonraki kontrol noktasına giden rotalardan herhangi biri için ihtiyacınız yok, bu nedenle haritaya geri döndünüz",
		scan: "Tarama",
		magnetic_sensor_not_found: "Cihazınızda manyetik sensör bulunamıyor. Pusula çalışmayacak.",
		on_demand_task: "İsteğe bağlı görevler",
		company_questions: "Şirket soruları",
		photo_challenges: "Fotoğraf aktiviteleri",
		general_challenges: "Genel Aktiviteler",
		new_check_point_found: "%s Kontrol noktasını buldunuz, %s kontrol noktasını BAŞLATMAK veya İPTAL edip %s kontrol noktasını kontrol etmeye devam etmek istiyor musunuz?",
		warning_no_photo: "Fotoğraf çekmediniz, fotoğrafsız göndermek istediğinizden emin misiniz?",
		warning_no_video: "Video kaydetmediniz, video olmadan göndermek istediğinizden emin misiniz?",
		return_to_task: "Göreve Dön",
		you_enter_start_area: "Başlangıç alanına ulaştınız!",
		activity_ended: "Aktivite sona erdi.",
		controller_push_score: "%s Görevi için yönetici tarafından %s puan kazandınız.",
		ask_require_key_title: "Bu kontrol noktasını başlatmak İçin jeton kullanılacak",
		please_complete_task: "Lütfen geçerli görevi tamamlayınız",
		ongoing: "Devam ediyor",
		available: "Mevcut Aktviteler",
		finished: "Bitti",
		change_server: "Sunucuyu değiştir",
		you_have_finished: "Bu aktiviteyi tamamladınız",
		timer_run_out: "Süre Bitti",
		tokens: "Jetonlar",
		correct_feedback: "Mükemmel!",
		partial_correct_feedback: "Aferin!",
		feedback_wrong: "Bir dahaki sefere bol şans!",
		message: "Mesajlaşma",
		controller_chat: "Yönetici ile mesajlaşma",
		activity_chat: "Aktivite mesajları",
		one_to_one_team_chat: "Direk mesajalma",
		add_team: "Direk mesajalma",
		controller: "Yönetici",
		search: "Arama",
		messaging_loading: "Mesaj yükleniyor. Lütfen biraz bekleyiniz.",
		move_in_the_activity_boundary: "Aktivite sınırlarında hareket edin",
		download_limit_reached: "İndirme sınırına ulaşıldı",
		welcome_to: "%s 'e Hoşgeldiniz",
		your_name: "Adınız",
		please_input_your_name: "Lütfen adınızı giriniz",
		please_take_photo: "Lütfen avatar olarak kullanmak üzere bir fotoğraf çekin",
		team_name: "Takım İsmi",
		input_team_description: "Takımınızı seçin ve seçtiğiniz takımda kimin takım kaptanı olacağına karar verin",
		are_you_captain: "Takım kaptanı siz misiniz?",
		please_input_team_name: "Lütfen takım ismini girin",
		waiting_players: "Tüm oyuncuların oyunda katılması bekleniyor",
		lobby: "Lobi",
		ready: "Hazır",
		captain: "Kaptan",
		please_wait: "Lütfen bekleyin…",
		please_wait_controller: "Lütfen yöneticinin oyunu devam ettirmesini bekleyin",
		select_team_name: "Takım İsmi Seçin",
		send_token_info: "Jetonunuzu başka bir oyuncuya gönderin",
		select_player: "Oyuncu Seç",
		select_token: "Jeton Seç",
		you_will_send_token: "\n%s adet jetonu %s 'a göndermek üzeresiniz",
		you_received_token: "Size jeton yada jetonlar gönderildi! \n%s2",
		show_all: "Tümünü Göster",
		show_team_only: "Sadece Takımı Göster",
		team_chat: "Takım içi mesajlaşma",
		location_unknown: "Bilinmeyen konum",
		select_avatar: "Bir avatar seçin",
		max_attempts_try_one: "Cevabınız yanlış. %s deneme hakkınız kaldı",
		max_attempts_try_other: "Cevabınız yanlış. %s deneme hakkınız kaldı",
		refresh_page: "Sayfayı yenile",
		show_other_players: "Diğer oyuncuları göster",
		warning: "Uyarı",
		finish_activity_confirm: "Etkinliğe geri dönemeyeceksiniz. Bu etkinliği bitirmek istediğinizden emin misiniz?",
		end: "Son",
		return_to_activity: "Aktiviteye Dön",
		paste_from_clipboard: "Ekran Görüntüsü Yapıştır"
	},
	"vn": {
		go_team: "Go Team",
		north: "Bắc",
		north_east: "Đông Bắc",
		east: "Đông",
		south_east: "Đông Nam",
		south: "Nam",
		south_west: "Tây Nam",
		west: "Tây",
		north_west: "Tây Bắc",
		ongoing_activities: "Hoạt động đang diễn ra",
		downloaded_activities: "Hoạt động đã tải xuống",
		finished_activities: "Hoạt động đã hoàn thành",
		download: "Tải về",
		play_now: "Bắt đầu",
		cont: "Tiếp tục",
		cancel: "Huỷ",
		done: "Xong",
		later: "Để sau",
		close: "Đóng",
		ok: "OK",
		delete_activity: "Xoá hoạt động?",
		do_you_want_to_delete: "Bạn có muốn xoá?",
		delete: "Xoá",
		play: "Chơi",
		error: "Lỗi",
		loading: "Đang tải",
		confirm: "Đồng ý",
		downloading: "đang tải",
		launch: "Chơi",
		enter_you_name: "Chọn tên",
		enter_name_hint: "Tên của bạn",
		ask_name_description: "Tên sẽ được sử dụng để định danh bạn trong hoạt động",
		password: "Mật khẩu",
		enter_activity_password: "Vui lòng nhập mật khẩu cho hoạt động này",
		licensee_password: "Vui lòng nhập mật khẩu cho kênh này",
		incorrect_password: "Sai mật khẩu",
		enter_password_hint: "Mật khẩu của bạn",
		activity_search: "Tìm kiếm hoạt động",
		qr_code_not_recognised: "Không nhận dạng được mã QR",
		scan_qr_code: "Quét mã QR",
		activity_not_found: "Không tìm thấy hoạt động",
		do_you_want_to_go_home: "Bạn có muốn thoát khỏi hoạt động và trở về trang chủ?",
		you_score_point_key: "Bạn đã nhận được %s điểm và %s chìa khoá do đến được trạm này",
		award_point_arrival: "Bạn đã nhận được %s điểm do đến được trạm này",
		award_key_arrival: "Bạn nhận được %s chìa khoá do đến được trạm này",
		the_start_area: "Khu vực bắt đầu",
		the_finish_area: "Khu vực kết thúc",
		forbidden_area: "Khu vực cấm",
		choose_a_way: "Chọn đường",
		you_enter_forbidden_area: "Bạn đã đi vào vùng cấm",
		please_move_to_activity_zone: "Di chuyển đến khu vực bắt đầu",
		next_check_point_distance: "Trạm kế tiếp nằm cách đây %s",
		finish_zone_distance: "Khu vực kết thúc nằm cách đây %s",
		settings: "Cài đặt",
		meters_feet: "Met - Feet",
		sounds: "Âm thanh",
		language: "Ngôn ngữ",
		key_required: "Bạn không có chìa khoá cần thiết để kích hoạt trạm này. Trạm này yêu cầu %s chìa khoá",
		set_destination: "Chọn điểm đến",
		home: "Trang chủ",
		chat: "Trò chuyện",
		you_score_point: "Bạn ghi được %s điểm",
		you_score_point_token: "Bạn ghi được %s điểm và %s chìa khoá",
		controller_score: "Bạn ghi được %s điểm từ người điều kiểm cho trạm %s",
		controller_key: "Bạn nhận được %s chìa khoá từ người điều khiển",
		award_key_route: "Bạn nhận được %s chìa khoá cho việc chọn con đường này",
		award_point_route: "Bạn nhận được %s điểm cho việc chọn con đường này",
		award_point_key_route: "Bạn nhận được %s điểm và %s chìa khoá cho việc chọn con đường này",
		task: "Công việc",
		back: "Trở về",
		text_answer: "Trả lời chữ",
		numeric_answer: "Trả lời số",
		pass: "Bỏ qua",
		submit: "Gửi",
		take_photo: "Tải lên một hình ảnh",
		choose_photo: "Chọn ảnh",
		record_video: "Quay video",
		play_video: "Xem video",
		choose_video: "Tải lên video",
		person_at_checkpoint: "Đưa máy tính bảng cho nhân viên sự kiện",
		enter_password: "Nhập mật khẩu",
		enter_score: "Nhập điểm / %s",
		player_answer: "Người chơi trả lời",
		pass_task_confirm: "Bạn sẽ không nhận được bất kì điểm nào nếu bạn bỏ qua. Hãy xác nhận rằng bạn muốn bỏ qua",
		invalid_score: "Điểm không hợp lệ",
		submit_answer_alert: "Câu trả lời của bạn đã được gửi đi để xem xét. Bạn sẽ nhận được điểm sớm thôi",
		ranking: "Xếp hạng",
		name: "Tên",
		score: "Thời gian",
		time: "Điểm",
		rank: "Hạng",
		end_zone_distance: "Khu vực kết thúc %s",
		start_zone_distance: "Khu vực bắt đầu %s",
		next_checkpoint_distance: "Trạm kế tiếp %s",
		send: "Gửi",
		enter_message: "Tin nhắn mới",
		today: "Hôm nay",
		notification: "Thông báo",
		finish_activity_alert: "Chúc mừng, bạn đã hoàn thành hoạt động",
		finish_activity: "Kết thúc hoạt động",
		partial_correct_answer: "Trả lời gần đúng",
		correct_answer: "Trả lời đúng",
		incorrect_answer: "Trả lời sai",
		qr_code_not_supported: "Thiết bị không hỗ trợ quét mã QR",
		camera_not_supported: "Thiết bị này không hỗ trợ chụp ảnh",
		photo_not_supported: "Thiết bị này không hỗ trợ truy cập thư viện ảnh",
		gps_not_available: "Đây là hoạt động GPS. Hãy bật định vị và thử lại",
		unknown_error: "Xảy ra lỗi gì đó rồi. Bạn hãy thử lại và liên lạc với đội tổ chức sự kiện để được hỗ trợ",
		no_internet: "Hãy kiểm tra kết nối mạng của bạn",
		no_available_route: "Bạn không có thoả bất cứ yêu cầu nào của các đường đến trạm kế tiếp, vì vậy bạn đã được trở về bản đồ",
		scan: "Scan",
		magnetic_sensor_not_found: "Không tìm thấy cảm ứng từ trường trên thiết bị của bạn. La bàn sẽ không thể hoạt động",
		on_demand_task: "Nhiệm vụ theo yêu cầu",
		company_questions: "Câu hỏi công ty",
		photo_challenges: "Thử thách hình ảnh",
		general_challenges: "Thử thách chung",
		new_check_point_found: "You just found checkpoint %s, do you want to LAUNCH checkpoint %s or CANCEL and continue to checkpoint %s?",
		activity_ended: "Activity ended",
		ask_require_key_title: "Launching this checkpoint will consume",
		available: "Available",
		change_server: "Change server",
		controller_push_score: "You scored %s points from the controller for %s task",
		finished: "Finished",
		ongoing: "Ongoing",
		return_to_task: "Return To Task",
		warning_no_photo: "You have not taken a photo, are you sure you want to submit without the photo?",
		warning_no_video: "You have not recorded a video, are you sure you want to submit without the video?",
		you_enter_start_area: "You have reached the start area!",
		tokens: "Tokens",
		activity_chat: "Activity messaging",
		message: "Messaging",
		controller_chat: "Controller messaging",
		one_to_one_team_chat: "Direct messaging",
		add_team: "Direct message",
		move_in_the_activity_boundary: "Move in the activity boundary",
		correct_feedback: "Excellent!",
		partial_correct_feedback: "Good job!",
		feedback_wrong: "Better luck next time!",
		max_attempts_try_one: "Your answer is incorrect. You have %s attempt left",
		max_attempts_try_other: "Your answer is incorrect. You have %s attempts left",
		download_limit_reached: "Đạt giới hạn tải xuống",
		timer_run_out: "Hết giờ",
		controller: "Controller",
		search: "Search",
		welcome_to: "Chào mừng bạn đến với %s",
		your_name: "Tên bạn là",
		please_input_your_name: "Hãy nhập tên bạn vào đây",
		please_take_photo: "Vui lòng chụp ảnh đại diện của bạn",
		team_name: "Tên đội là",
		input_team_description: "Hãy chọn tên đội và đội trưởng của đội bạn là ai?",
		are_you_captain: "Bạn có phải là đội trưởng không?",
		please_input_team_name: "Hãy nhập tên của đội bạn",
		waiting_players: "Chúng ta cùng chờ các thành viên còn lại, họ đang đăng nhập vào.",
		lobby: "Sảnh đợi",
		ready: "Sẵn sàng nha",
		captain: "Đội trưởng",
		please_wait: "Vui lòng đợi…",
		refresh_page: "Làm mới trang",
		show_other_players: "Hiển thị những người chơi khác",
		warning: "Cảnh báo",
		finish_activity_confirm: "Bạn sẽ không thể quay lại hoạt động. Bạn có chắc chắn muốn kết thúc hoạt động này không?",
		end: "Kết thúc",
		return_to_activity: "Quay lại hoạt động",
		paste_from_clipboard: "Dán ảnh chụp màn hình",
		team_chat: "Nhắn tin nhóm"
	},
}

const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "es",
	messages
});

export default i18n;