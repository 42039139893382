<template>
	<v-container>
		<v-card tile>
			<v-card-title class="gtheader white--text">{{ $t("on_demand_task") }}</v-card-title>
		</v-card>
		<v-tabs v-if="!$store.state.isFinished" v-model="tab" centered center-active prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" show-arrows>
			<v-tab
				v-for="item in [
					{ id: 1, name: 'Company Question' },
					{ id: 2, name: 'Photo Challenges' },
					{ id: 3, name: 'General Challenges' }
				].filter((x) => odtList.find((y) => x.id == y.type) != null)"
				:key="item.id"
			>
				{{ item.name }}
			</v-tab>
			<v-tabs-items v-model="tab">
				<v-tab-item v-for="idx in [
					{ id: 1, name: 'Company Question' },
					{ id: 2, name: 'Photo Challenges' },
					{ id: 3, name: 'General Challenges' }
				].filter((x) => odtList.find((y) => x.id == y.type) != null)" :key="idx.id">
					<v-list dense>
						<div v-for="item in odtList.filter((odt) => odt.type == idx.id)" :key="item.id">
							<v-list-item @click.stop="setSelectedOdt(item), (showOdt = false)">
								<v-list-item-content>
									<v-list-item-title v-text="item.task.name"></v-list-item-title>
								</v-list-item-content>

								<v-list-item-icon>
									<v-icon color="primary"> mdi-chevron-right </v-icon>
								</v-list-item-icon>
							</v-list-item>
							<v-divider></v-divider>
						</div>
					</v-list>
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>

		<!-- launch the task ODT-->
		<v-dialog v-model="showTaskOdt" fullscreen>
			<!-- show element in a task of checkpoint -->
			<v-card tile>
				<v-card-title class="gtheader white--text">{{ $t('task') }}</v-card-title>
				<!-- <v-card-title v-if="task.name && task.description">{{ task.name }}-{{ task.description }}</v-card-title> -->
				<v-progress-linear v-if="task.time_limit > 0" color="red" :value="(100 / task.time_limit) * timerOdt" :buffer-value="100" reverse height="20">
					<span>{{ secondsToHms(timerOdt) }}</span>
				</v-progress-linear>
				<v-card-text class="d-flex flex-column justify-space-between align-center">
					<v-form ref="taskForm">
						<v-container v-if="showTaskOdt">
							<div v-for="(element, index) in task.elements" :key="'333' + index" class="d-flex flex-column justify-space-between">
								<p v-if="element.type == 'text'" class="wrap-text" v-html="element.pivot.content"></p>

								<!-- EMBED -->
								<media v-if="element.type == 'image' || element.type == 'audio'" :name="getSourceFromContent(element.pivot.content)" />
								<iframe
									v-if="element.type == 'youtube_video'"
									frameborder="0"
									height="250"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									scrolling="0"
									:src="getUrlEmbed(element.pivot.content)"
									allowfullscreen
									class="mb-4"
								></iframe>
								<v-btn v-if="element.type == 'web_link'" class="mb-4" :href="JSON.parse(element.pivot.content).url" target="_blank" color="primary" tile min-width="100">{{ JSON.parse(element.pivot.content).button_text }}</v-btn>
								<a v-if="element.type == 'web_page'" class="mb-4" :href="element.pivot.content" target="_self" color="primary">{{ element.pivot.content }}</a>

								<v-radio-group v-if="element.type == 'multiple_choice'" v-model="element.multiple_choice" @change="setAnswer(element.type, element.pivot.id, element.customId, element.multiple_choice)" dense hide-details>
									<v-radio v-for="(content, index) in JSON.parse(element.pivot.content)" :key="'444' + index" :label="content.question" :value="content.question"></v-radio>
								</v-radio-group>
								<div v-if="element.type == 'multiple_answer'">
									<div v-for="(content, index) in JSON.parse(element.pivot.content)" :key="'555' + index">
										<v-checkbox
											@change="setAnswer(element.type, element.pivot.id, element.customId, element.multiple_answer)"
											v-model="element.multiple_answer"
											:label="content.question"
											:value="content.question"
											dense
											hide-details
										>
										</v-checkbox>
									</div>
								</div>
								<v-img v-if="element.type == 'photo_answer' && photoTemp && photoTemp.startsWith('data')" :src="photoTemp" max-height="400" class="mb-4" contain></v-img>
								<input
									v-if="element.type == 'photo_answer'"
									type="file"
									id="file"
									accept="image/*"
									style="display: none"
									@change="showPhoto"
									:onchange="setAnswer(element.type, element.pivot.id, element.customId, attachment)"
									required
								/>
								<div v-if="element.type == 'photo_answer'" class="text-center mx-auto mb-4">
									<v-row no-gutters>
										<v-col cols="12" sm="auto" offset="0">
											<v-btn v-if="element.type == 'photo_answer'" color="primary" @click="openFileDialog()" min-width="100" tile>{{ $t("take_photo") }}</v-btn>
										</v-col>
										<v-col cols="12" sm="auto" :class="$vuetify.breakpoint.width > 600 ? 'pt-2' : ''">
											<span class="font-weight-black mx-4">OR</span>
										</v-col>
										<v-col cols="12" sm="auto">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn v-if="element.type == 'photo_answer'" color="primary" @click="pasteClipboard()" min-width="100" tile v-bind="attrs" v-on="on">{{ $t("paste_from_clipboard") }}</v-btn>
												</template>
												<span>If button is not working, please paste using ctrl + V (Windows) or Command (⌘) + V (Mac)</span>
											</v-tooltip>
										</v-col>
									</v-row>
								</div>
								<v-text-field v-if="element.type == 'text_answer'" type="text" v-model="element.text_answer" @change="setAnswer(element.type, element.pivot.id, element.customId, element.text_answer)"></v-text-field>
								<v-text-field v-if="element.type == 'number_answer'" type="number" v-model="element.number_answer" @change="setAnswer(element.type, element.pivot.id, element.customId, element.number_answer)"></v-text-field>
								<iframe v-if="element.type == 'video_answer' && videoTemp" :src="videoTemp" class="mb-4" frameborder="0" allow="autoplay = 0; accelerometer; encrypted-media; gyroscope; picture-in-picture;" scrolling="0"></iframe>
								<input
									v-if="element.type == 'video_answer'"
									type="file"
									id="fileVideo"
									accept="video/mp4"
									style="display: none"
									@change="showVideo"
									:onchange="setAnswer(element.type, element.pivot.id, element.customId, attachmentVideo)"
									required
								/>
								<v-btn v-if="element.type == 'video_answer'" class="mx-auto mb-4" color="primary" @click="openFileVideoDialog()" width="300" tile>{{ $t('choose_video') }}</v-btn>
								<iframe v-if="element.type == 'video_recording' && recordingVideoTemp" :src="recordingVideoTemp" class="mb-4" frameborder="0" allow="autoplay = 0; accelerometer; encrypted-media; gyroscope; picture-in-picture;" scrolling="0"></iframe>
								<input
									v-if="element.type == 'video_recording'"
									type="file"
									id="fileRecordingVideo"
									accept="video/mp4"
									style="display: none"
									@change="showRecordingVideo"
									:onchange="setAnswer(element.type, element.pivot.id, element.customId, attachmentRecordingVideo)"
									required
								/>
								<v-btn v-if="element.type == 'video_recording'" class="mx-auto mb-4" color="primary" @click="openFileRecordingVideoDialog()" width="300" tile>{{ $t('record_video') }}</v-btn>
								<v-btn v-if="element.type == 'finish'" @click="endActivity()" color="primary" tile min-width="100">{{ element.pivot.content }}</v-btn>
							</div>
							<div class="d-flex justify-center mt-4">
								<v-btn class="mx-2" @click="closeDialogTaskOdt" color="secondary lighten-2" tile min-width="100">{{ $t("cancel") }}</v-btn>
								<v-btn class="mx-2" v-if="$root.hasAnswer(task.elements) && odt.answer_type_id == 3" @click="showDialogPasswordPersonal()" color="primary" tile min-width="100">{{ $t('submit') }}</v-btn>
								<v-btn class="mx-2" v-else-if="$root.hasAnswer(task.elements)" @click="submitOdt()" color="primary" tile min-width="100">{{ $t('submit') }}</v-btn>
							</div>
						</v-container>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- show message -->
		<v-dialog v-model="showMessage.show" max-width="400" persistent>
			<v-card>
				<v-card-title :class="showMessage.color ? showMessage.color : 'gtheader'" class="justify-center white--text">{{ showMessage.title }}</v-card-title>
				<v-card-text v-if="showMessage.icon" class="d-flex justify-center mt-4">
					<v-img :src="showMessage.icon" eager max-width="150"></v-img>
				</v-card-text>
				<v-card-actions class="d-flex flex-column justify-center wrap-text" v-html="showMessage.message"></v-card-actions>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="primary" tile @click="closeDialogMessage()" min-width="100">{{ $t('cont') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- type == password_personal -->
		<v-dialog v-model="showPasswordPersonal" max-width="500" no-click-animation persistent>
			<v-card>
				<v-card-title class="gtheader white--text justify-center">{{ $t('person_at_checkpoint') }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-img src="ic_app.png" max-width="150"></v-img>
				</v-card-text>
				<v-card-text>
					<v-container>
						<v-text-field type="password" v-model="passwordPersonal" label="Password Personal"></v-text-field>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="secondary lighten-2" tile @click="closeDialogPasswordPersonal()" min-width="100">{{ $t('back') }}</v-btn>
					<v-btn text color="primary" tile @click="submitPassword()" min-width="100">{{ $t('cont') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- player answer -->
		<v-dialog v-model="showPlayerAnswer" max-width="400" no-click-animation persistent>
			<v-card>
				<v-card-title class="gtheader white--text justify-center">{{ $t('player_answer') }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-img src="ic_app.png" max-width="150"></v-img>
				</v-card-text>
				<v-card-text>
					<v-container>
						<v-text-field v-model="playerAnswer" :label="'Enter score / ' + this.task.point"></v-text-field>
					</v-container>
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-spacer></v-spacer>
					<v-btn text color="secondary lighten-2" tile @click="closeDialogPlayerAnswer()" min-width="100">{{ $t('back') }}</v-btn>
					<v-btn text color="primary" tile @click="submitOdt()" min-width="100">{{ $t('cont') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import media from "../media";
import Vue from "vue";
import axios from "axios";
export default {
	components: {
		media
	},
	data() {
		return {
			tab: null,
			odtList: this.$root.clone(this.$store.state.principal.activity ? this.$store.state.principal.activity.on_demand_tasks.filter((x) => !this.$store.state.doneOdt.includes(x.id)) : []),
			showMessage: { show: false, title: null, content: null },
			showTaskOdt: false,
			showPasswordPersonal: false,
			showPlayerAnswer: false,
			odt: {},
			task: {},
			elementList: [],
			taskAnswerList: [],
			correctAnswerOdt: 0,
			passwordPersonal: null,
			playerAnswer: null,
			intervalOdt: 0,
			timerOdt: 0,
			photoTemp: null,
			attachment: null,
			videoTemp: null,
			attachmentVideo: null,
			recordingVideoTemp: null,
			attachmentRecordingVideo: null,
		}
	},
	watch: {
		"$store.state.doneOdt": function () {
			let list = this.$store.state.principal.activity ? this.$store.state.principal.activity.on_demand_tasks.filter((x) => !this.$store.state.doneOdt.includes(x.id)) : [];
			this.odtList = this.$root.clone(list);
		},
		timerOdt: function () {
			if (this.task.time_limit && this.task.time_limit > 0 && this.timerOdt <= 0 && this.showTaskOdt) {
				this.submitOdt();
				clearInterval(this.intervalOdt);
				this.intervalOdt = 0;
			}
		},
		"$store.state.isFinished": function() {
			if (this.$store.state.isFinished) {
				this.$router.push("/games/map/"+this.$store.state.principal.activityId);
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.intervalOdt != 0) {
			clearInterval(this.intervalOdt);
			this.intervalOdt = 0;
		}
		next();
	},
	mounted() {
		if (this.$store.state.principal.activity.on_demand_tasks.length == this.$store.state.doneOdt.length) {
			this.$router.push("/games/map/"+this.$store.state.principal.activityId);
		}

		// handle image paste to clipboard
		let vm = this;
		document.addEventListener("paste", async function (e) {
			if (vm.showTaskOdt) {
				e.preventDefault();
				e.stopPropagation();
				let file = e.clipboardData.items[0].getAsFile();
				if (file && file.type.startsWith("image")) {
					const fr = new FileReader();
					fr.readAsDataURL(file);
					fr.addEventListener("load", () => {
						vm.photoTemp = fr.result;
						vm.attachment = file; // this is an image file that can be sent to server...
					});
				}
			}
		});
	},
	methods: {
		setSelectedOdt(item) {
			// reset counter correct answer
			this.correctAnswer = 0;

			if (item) {
				this.odt = item;

				// set start time
				if (this.odt.start == null) {
					this.odt.start = Date.now();
					this.$store.commit("updateOdt", this.odt);
				}

				// reset initiate answer
				this.taskAnswerList = [];

				if (this.odt.task != null) {
					this.task = this.odt.task;
					if (this.task.elements) {
						this.elementList = this.task.elements;

						// set initiate answer
						for (let i in this.elementList) {
							this.elementList[i].customId = i;
							if (this.$root.isAnswerElement(this.elementList[i].type)) {
								this.setAnswer(this.elementList[i].type, this.elementList[i].pivot.id, this.elementList[i].customId, null);
								if (this.elementList[i].type == "multiple_answer") {
									Vue.set(this.elementList[i], "multiple_answer", []);
								}
							}
						}
					}
				} else {
					this.task = {};
					this.elementList = [];
				}

				if (this.odt.task != null) {
					this.showDialogTaskOdt();
				}
			}
		},
		showDialogTaskOdt() {
			this.timerOdt = this.task.time_limit - ((Date.now() - this.odt.start)/1000);
			if(this.task.time_limit != null && this.timerOdt <= 0 && this.task.time_limit != 0) {
				this.submitOdt();
			} else {
				if (this.odt.task != null && this.task.time_limit > 0) {
					this.intervalOdt = setInterval(() => {
						if (this.timerOdt <= 0) { 
							clearInterval(this.intervalOdt);
							this.intervalOdt = 0;
						} else {
							this.timerOdt += -1;
						}
					}, 1000);
				}

				if (this.odt.task != null) {
					this.showTaskOdt = true;
				}
			}
		},
		closeDialogTaskOdt() {
			clearInterval(this.intervalOdt);
			this.intervalOdt = 0;

			// reset task form
			if (this.showTaskOdt) {
				this.$refs.taskForm.reset();
			}
			this.photoTemp = null;
			this.attachment = null;
			this.videoTemp = null;
			this.attachmentVideo = null;
			this.recordingVideoTemp = null;
			this.attachmentRecordingVideo = null;
			this.showTaskOdt = false;
		},
		showDialogPasswordPersonal() {
			this.passwordPersonal = null;
			this.showPasswordPersonal = true;
		},
		closeDialogPasswordPersonal() {
			this.showPasswordPersonal = false;
		},
		showDialogPlayerAnswer() {
			this.playerAnswer = null;
			this.showPlayerAnswer = true;
		},
		closeDialogPlayerAnswer() {
			this.showPlayerAnswer = false;
		},
		showDialogMessage() {
			this.showMessage.show = true;
		},
		closeDialogMessage() {
			this.showMessage = { show: false, title: "", icon: "", message: "" };
			if (this.$store.state.principal.activity.on_demand_tasks.length == this.$store.state.doneOdt.length) {
				this.$router.push("/games/map/"+this.$store.state.principal.activityId);
			}
		},
		setAnswer(type, elementId, customId, answer) {
			let taskAnswer = {
				id: customId,
				elementId: elementId,
				type: type,
				answer: answer
			};

			let index = this.taskAnswerList.findIndex((x) => x.id === customId);
			if (this.taskAnswerList.length > 0 && index != -1) {
				this.taskAnswerList[index].answer = answer;
			} else {
				this.taskAnswerList.push(taskAnswer);
			}
		},
		submitOdt() {
			let isConfirm = true;
			if (this.taskAnswerList.filter((x) => this.$root.hasFileAnswer(x.type) && x.answer == null).length > 0) {
				if (this.taskAnswerList.find((x) => x.type == "photo_answer" && x.answer == null)) {
					isConfirm = confirm(this.$t('warning_no_photo'));
				} else {
					isConfirm = confirm(this.$t('warning_no_video'));
				}
			}

			if (isConfirm) {
				this.odt.end = Date.now();

				// stop countdown time if exist
				if (this.odt.max_attempts) {
					this.odt.max_attempts += -1;
				} else {
					this.odt.max_attempts = 0;
				}
				let point = 0;
				this.correctAnswerOdt = 0;

				// if (this.odt.type == 3) {
				// 	if (this.playerAnswer >= 0 && this.playerAnswer <= this.task.point) {
				// 		point = this.playerAnswer;
				// 		this.$store.commit("setScore", this.playerAnswer);
				// 		let type = "answer_odt";
				// 		this.$store.commit("setScoreHistory", { id: Date.now(), type: type, data: this.odt.id, score: this.playerAnswer, activityDbId: this.odt.id });
				// 		this.$store.commit("doneOdt", this.odt.id);
				// 		this.closeDialogPlayerAnswer();
				// 		this.closeDialogPasswordPersonal();
				// 		this.closeDialogTaskOdt();
				// 	} else {
				// 		confirm(this.$t('invalid_score'));
				// 	}
				// } else
				if (this.odt.type == 2) {
					// need to be reviewed by the controller
					this.showMessage.title = this.$t('notification');
					this.showMessage.icon = this.$store.state.principal.activity.image;
					this.showMessage.message = this.$t('submit_answer_alert');
					this.closeDialogTaskOdt;
					this.showDialogMessage();
				} else {
					for (let i in this.taskAnswerList) {
						if (this.$root.isAnswerElement(this.taskAnswerList[i].type)) {
							let task = this.elementList.find((x) => x.customId == this.taskAnswerList[i].id);
							if (task != null) {
								let answer = task.pivot.content.startsWith("{") || task.pivot.content.startsWith("[") ? JSON.parse(task.pivot.content) : task.pivot.content;
								if (task.type === "text_answer") {
									var patt = new RegExp(answer ? answer.toLowerCase() : answer);
									if (patt.test(this.taskAnswerList[i].answer ? this.taskAnswerList[i].answer.toLowerCase() : this.taskAnswerList[i].answer) || answer == "") {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "number_answer") {
									if (this.taskAnswerList[i].answer == answer.content) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "photo_answer") {
									if (this.taskAnswerList[i].answer != null) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "video_answer") {
									if (this.taskAnswerList[i].answer != null) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "multiple_answer") {
									if (this.taskAnswerList[i].answer.length > 0) {
										let totalCorrect = answer.filter((x) => x.is_answer);
										let correct = 0;
										for (let j = 0; j < this.taskAnswerList[i].answer.length; j++) {
											if (answer.find((x) => x.question == this.taskAnswerList[i].answer[j] && x.is_answer)) {
												this.correctAnswer += 1;
												correct += 1;
											}
										}

										point += this.applyTimerDecreaseStep(Math.round(this.task.point * (correct / totalCorrect.length - (this.taskAnswerList[i].answer.length - correct) / answer.length)));
									}
								} else if (task.type === "multiple_choice") {
									if (answer.find((x) => x.question == this.taskAnswerList[i].answer && x.is_answer)) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "number_range") {
									let start = answer.content.split(",")[0];
									let end = answer.content.split(",")[1];
									if (start <= this.taskAnswerList[i].answer && this.taskAnswerList[i].answer <= end) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								} else if (task.type === "video_recording") {
									if (this.taskAnswerList[i].answer != null) {
										point += this.applyTimerDecreaseStep(this.task.point);
										this.correctAnswer += 1;
									}
								}
							}
						}
					}

					if (this.correctAnswer > 0 && ((this.task.time_limit > 0 && this.timerOdt > 0) || this.task.time_limit == 0 || this.task.time_limit == null)) {
						let totalTask = this.elementList.filter((element) => this.$root.isAnswerElement(element.type)).length;
						point = Math.round(point / totalTask);

						if (point == this.task.point) {
							this.showMessage.title = this.$t('correct_answer');
							this.showMessage.icon = this.task.image_correct_answer ? this.task.image_correct_answer.rand_name : "feedback_ok.png";
							this.showMessage.color = "success";
							this.showMessage.message =
								"<p class='mb-4'>" + (this.task.feedback_correct_answer ? this.task.feedback_correct_answer : this.$t('correct_feedback')) + "</p><p>" + this.$root.setValue(this.$t('you_score_point'), point) + "</p>";
						} else {
							this.showMessage.title = this.$t('partial_correct_answer');
							this.showMessage.icon = this.task.image_partial_answer ? this.task.image_partial_answer.rand_name : "feedback_neutral.png";
							this.showMessage.color = "success";
							this.showMessage.message =
								"<p class='mb-4'>" + (this.task.feedback_partial_answer ? this.task.feedback_partial_answer : this.$t('partial_correct_feedback')) + "</p><p>" + this.$root.setValue(this.$t('you_score_point'), point) + "</p>";
						}

						if (this.$store.state.principal.activity.hide_all_answer_feedback) {
							// play correct answer sound
							this.$root.correctAnswerSound();
						}

						this.$store.commit("setScore", point);
						let type = "answer_odt";
						this.$store.commit("setScoreHistory", { id: Date.now(), type: type, data: this.odt.id, score: point, activityDbId: this.odt.id });
					} else {
						if (this.$store.state.principal.activity.hide_all_answer_feedback) {
							// play wrong answer sound
							this.$root.wrongAnswerSound();
						}

						if (this.odt.max_attempts > 0) {
							this.showMessage.title = this.$t('notification');
							this.showMessage.icon = null;
							this.showMessage.color = null;
							let msg = "";
							if (this.odt.max_attempts > 1) {
								msg = this.$root.setValue(this.$t('max_attempts_try_other'), this.odt.max_attempts);
							} else {
								msg = this.$root.setValue(this.$t('max_attempts_try_one'), this.odt.max_attempts);
							}
							this.showMessage.message = "<p class='mb-4'>" + msg + "</p>";
						} else {
							this.showMessage.title = this.$t('incorrect_answer');
							this.showMessage.icon = this.task.image_wrong_answer ? this.task.image_wrong_answer.rand_name : "feedback_wrong.png";
							this.showMessage.color = "error";
							this.showMessage.message = "<p class='mb-4'>" + this.task.feedback_wrong_answer + "</p>";
						}
					}
					this.closeDialogTaskOdt();
					this.showDialogMessage();
				}

				this.closeDialogTaskOdt();
				if (this.odt.max_attempts <= 0 || this.correctAnswer > 0) {
					let formData = new FormData();
					formData.append("player_id", this.$store.state.principal.player_id);
					formData.append("activity_id", this.odt.activity_id);
					formData.append("task_id", this.odt.task_id);
					formData.append("score", point);
					formData.append("start_time", this.odt.start);
					formData.append("time", (Date.now() - this.odt.start)/1000);
					formData.append("playing_time", this.$store.state.timer.h * 3600 + this.$store.state.timer.m * 60 + this.$store.state.timer.s);
					formData.append("latitude", this.odt.position ? this.odt.position.latitude : "");
					formData.append("longitude", this.odt.position ? this.odt.position.longitude : "");

					for (let i in this.taskAnswerList) {
						if (this.taskAnswerList[i].type == "multiple_answer") {
							formData.append(this.taskAnswerList[i].elementId, JSON.stringify(this.taskAnswerList[i].answer));
						} else {
							formData.append(this.taskAnswerList[i].elementId, this.taskAnswerList[i].answer);
						}
					}

					// pause time scheduler
					this.$root.pause = true;

					axios
						.post("/api/v2/submit_answer_odt", formData, { headers: { "Content-Type": "multipart/form-data" } })
						.then(() => {
							// reset variable answer holder
							this.taskAnswerList = [];

							this.$store.commit("doneOdt", this.odt.id);
							this.odt = {};
							this.task = {};
							this.elementList = [];

							// run time scheduler
							this.$root.pause = false;
						})
						.catch(() => {
							// run time scheduler
							this.$root.pause = false;
						});
				}
			}
		},
		getSourceFromContent(content) {
			let result = content != "" ? JSON.parse(content) : content;
			return result.rand_name ? result.rand_name : result.real_name;
		},
		secondsToHms(d) {
			var sec_num = parseInt(d);
			var hours = Math.floor(sec_num / 3600);
			var minutes = Math.floor((sec_num - hours * 3600) / 60);
			var seconds = sec_num - hours * 3600 - minutes * 60;

			if (hours < 10) {
				hours = "0" + hours;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}
			return (hours != "00" ? hours + ":" : "") + (minutes != "00" ? minutes + ":" : "") + seconds;
		},
		applyTimerDecreaseStep(points) {
			if (points == 0) {
				return 0;
			}
			if (this.task.point_decrease_step <= 0 || this.task.time_limit <= 0) {
				return points;
			}
			let time = this.task.time_limit - (this.odt.end - this.odt.start) / 1000;
			if (time < 0) {
				return points;
			}
			let step = this.task.point_decrease_step + 1;
			let pointsPerStep = (points * 1) / step;
			return Math.round((Math.floor((time * 1) / ((this.task.time_limit * 1) / step)) + 1) * pointsPerStep);
		},
		openFileDialog() {
			if (document.getElementById("file")) {
				document.getElementById("file").click();
			}
		},
		openFileVideoDialog() {
			if (document.getElementById("fileVideo")) {
				document.getElementById("fileVideo").click();
			}
		},
		openFileRecordingVideoDialog() {
			if (document.getElementById("fileRecordingVideo")) {
				document.getElementById("fileRecordingVideo").click();
			}
		},
		showPhoto(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let imageName = files[0].name;
				if (imageName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.photoTemp = fr.result;
					this.attachment = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachment = null;
			}
		},
		showVideo(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let videoName = files[0].name;
				if (videoName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.videoTemp = URL.createObjectURL(files[0]);
					this.attachmentVideo = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachmentVideo = null;
			}
		},
		showRecordingVideo(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let recordingVideoName = files[0].name;
				if (recordingVideoName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.recordingVideoTemp = URL.createObjectURL(files[0]);
					this.attachmentRecordingVideo = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachmentRecordingVideo = null;
			}
		},
		getUrlEmbed(url) {
			if (url.includes("youtu")) {
				let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
				let match = url.match(regExp);
				let urlEmbed = "https://www.youtube.com/embed/" + match[2] + "?autoplay=1&showinfo=0&controls=1";
				return urlEmbed;
			}
			return url;
		},
		async pasteClipboard() {
			try {
				const clipboardItems = await navigator.clipboard.read();
				for (const clipboardItem of clipboardItems) {
					for (const type of clipboardItem.types) {
						if (type.startsWith("image")) {
							const blob = await clipboardItem.getType(type);
							var file = new File([blob], this.task.id, { lastModified: new Date().getTime(), type: blob.type });
							if (file) {
								const fr = new FileReader();
								fr.readAsDataURL(file);
								fr.addEventListener("load", () => {
									this.photoTemp = fr.result;
									this.attachment = file; // this is an image file that can be sent to server...
								});
							}
						}
					}
				}
			} catch (err) {
				console.error(err.name, err.message);
			}
		}
	}
}
</script>