<template>
	<v-container>
		<v-simple-table>
			<template v-slot:default>
				<thead class="orange">
					<tr>
						<th class="text-left">{{ $t('rank') }}</th>
						<th class="text-left">{{ $t('name') }}</th>
						<th class="text-left">{{ $t('score') }}</th>
						<th class="text-left">{{ $t('time') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in playerRankList" :key="item.player_id" :class="item.player_id == $store.state.principal.player_id ? 'red' : ''">
						<td>{{ index + 1 }}</td>
						<td>{{ item.name }}</td>
						<td>{{ item.score }}</td>
						<td>{{ item.time }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</v-container>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			intervalRealTimeRanking: 0,
			playerRankList: []
		};
	},
	mounted() {
		clearInterval(this.intervalRealTimeRanking);
		this.intervalRealTimeRanking = 0;
		
		this.getRanking();

		// Real Time Ranking
		if (this.$store.state.principal.activity.hide_real_time_ranking && this.intervalRealTimeRanking == 0) {
			this.intervalRealTimeRanking = setInterval(() => {
				if (this.$store.state.principal.activityId !== undefined) {
					this.getRanking();
				}
			}, 5000);
		}
	},
	methods: {
		getRanking() {
			this.$root.loader.isShow = false;
			axios.get("/api/v1/get_ranking?activity_id=" + this.$store.state.principal.activityId).then((response) => {
				this.playerRankList = response.data.results;
			});
		}
	},
	beforeRouteLeave(to, from, next) {
		clearInterval(this.intervalRealTimeRanking);
		this.intervalRealTimeRanking = 0;
		this.$root.loader.isShow = true;
		next()
	},
};
</script>