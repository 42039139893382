<template>
	<v-main>
		<v-container>
			<v-form ref="form" @submit.prevent="startActivity">
				<v-card max-width="600" class="mt-4 mx-auto">
					<v-card-text style="padding-top: 20px">
						<p style="font-size: 1rem; color: black">{{ $t("enter_you_name") }}<br />{{ $t("ask_name_description") }}</p>
						<v-text-field v-model="username" :rules="[(v) => !!v || $t('please_input_your_name')]" :label="$t('enter_name_hint')" outlined small dense></v-text-field>
						<v-img v-if="avatar && (avatar.startsWith('data') || avatar.startsWith('http'))" :src="avatar" :max-height="$vuetify.breakpoint.smAndDown ? 200 : 300" class="mb-4" contain></v-img>
						<input type="file" id="avatar" accept="image/*" style="display: none" @change="showAvatar" required />
						<v-row dense>
							<v-col cols="12" sm="6">
								<v-menu>
									<template v-slot:activator="{ on, attrs }">
										<v-btn color="primary" block v-bind="attrs" v-on="on" @click="invalid = false"> Select an Avatar </v-btn>
									</template>
									<v-card :max-width="$vuetify.breakpoint.smAndUp ? '280' : ''">
										<v-card-text>
											<v-row no-gutters>
												<v-col cols="3" v-for="(item, index) in avatars" :key="index">
													<v-hover v-slot="{ hover }">
														<v-avatar @click="selectAvatar(item)" size="60" class="ma-1">
															<v-img :src="item" :style="{ opacity: hover || $vuetify.breakpoint.smAndDown ? '1' : '0.8' }"></v-img>
														</v-avatar>
													</v-hover>
												</v-col>
											</v-row>
										</v-card-text>
									</v-card>
								</v-menu>
							</v-col>
							<v-col cols="12" sm="6">
								<v-btn color="primary" block @click="openFileDialog(), invalid = false">{{ $t("take_photo") }}</v-btn>
							</v-col>
						</v-row>
						<v-alert v-model="invalid" class="my-2" dense outlined dismissible color="error" transition="scroll-y-transition">Please select an avatar or upload an image</v-alert>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="secondary lighten-2" class="white--text" @click="logout" min-width="100">{{ $t("cancel") }}</v-btn>
						<v-btn color="primary" type="submit" min-width="100">{{ $t("submit") }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-container>
	</v-main>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
export default {
	props: ["qrCode"],
	data: function () {
		return {
			invalid: false,
			username: null,
			avatar: null,
			baseLocation: axios.defaults.baseURL,
			attachment: null,
			dbRoot: firebase.database().ref().child("messaging").child(this.$store.state.principal.activity.licensee_id).child(this.$store.state.principal.activityId),
			avatars: [
				"avataaars-1.png",
				"avataaars-2.png",
				"avataaars-3.png",
				"avataaars-4.png",
				"avataaars-5.png",
				"avataaars-6.png",
				"avataaars-7.png",
				"avataaars-8.png",
				"avataaars-9.png",
				"avataaars-10.png",
				"avataaars-11.png",
				"avataaars-12.png",
				"avataaars-13.png",
				"avataaars-14.png",
				"avataaars-15.png",
				"avataaars-16.png",
				"avataaars-17.png",
				"avataaars-18.png"
			]
		};
	},
	methods: {
		logout() {
			this.$store.dispatch("logout").then(() => {
				this.$router.push("/");
			});
		},
		startActivity() {
			if (this.$refs.form.validate() && this.attachment != null) {
				let formData = new FormData();
				formData.append("activity_id", this.$store.state.principal.activityId);
				formData.append("player_id", this.$store.state.principal.player_id);
				formData.append("name", this.username);
				formData.append("start_time", Date.now());
				formData.append("playing_time", "0");
				if (this.attachment) {
					formData.append("avatar", this.attachment);
				}
				formData.append("device_type", "web");
				formData.append("device_token", "webclient");
				formData.append("fcm_token", "");
				formData.append("is_local", window.location.origin.includes("localhost"));

				axios.post("/api/v1/start_activity", formData).then((response) => {
					let res = response.data;
					if (res.code == 200) {
						let player = res.result;
						this.$store.commit("setPlayer", player);
						if (this.$store.state.principal.activity.team != 1) {
							// create player in firebase
							this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("name").set(player.name);
							this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("last_login").set(firebase.database.ServerValue.TIMESTAMP);
							this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("avatar").set(player.avatar);
							this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("is_online").set(true);
						}

						var navigate = this.$router;
						if (this.$store.state.principal.activity.team == 1) {
							navigate.push("/games/start/waitingroom");
						} else if (this.$store.state.principal.activity.team == 2) {
							navigate.push("/games/start/addteam");
						} else if (this.$store.state.principal.activity.team != null) {
							navigate.push("/games/start/lobby");
						} else {
							this.$store.commit("setStarted", true);
							navigate.push("/games/map/" + this.$store.state.principal.activityId);
						}
					} else {
						let notification = { title: "Unknown error", content: "" };
						if (res) {
							if (res.status == 400) {
								notification.title = "Bad request";
							} else if (res.status == 500) {
								notification.title = "Server error";
							} else if (res.status == 401) {
								notification.title = "Unauthorized";
							} else if (res.status == 403) {
								notification.title = "Forbidden";
							} else if (res.code == 404) {
								notification.title = "Not found";
							} else {
								notification.title = "Notification (" + res.code + ")";
							}
							notification.content = res.message;
						}
						this.$store.commit("showNotification", notification);
					}
				});
			} else {
				if (this.attachment == null) {
					this.invalid = true;
				}
			}
		},
		selectAvatar(avatar) {
			this.avatar = location.origin + "/" + avatar;
			let img = new Image();
			img.src = location.origin + "/" + avatar;
			fetch(img.src)
				.then((res) => res.blob())
				.then((blob) => {
					this.attachment = new File([blob], avatar, blob);
				});
		},
		openFileDialog() {
			if (document.getElementById("avatar")) {
				document.getElementById("avatar").click();
			}
		},
		showAvatar(e) {
			const files = e.target.files;
			if (files[0] !== undefined) {
				let imageName = files[0].name;
				if (imageName.lastIndexOf(".") <= 0) {
					return;
				}
				const fr = new FileReader();
				fr.readAsDataURL(files[0]);
				fr.addEventListener("load", () => {
					this.avatar = fr.result;
					this.attachment = files[0]; // this is an image file that can be sent to server...
				});
			} else {
				this.attachment = null;
			}
		}
	}
};
</script>
