<template>
	<v-app style="background-color: #f8f8f8">
		<v-app-bar v-if="$store.state.isStarted == true" app dense dark color="#1c262b" height="114" clipped-left>
			<v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<img v-if="!$vuetify.breakpoint.xsOnly" src="logo.png" height="50" class="ml-1" />
			<v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
			<span style="line-height: 1;" class="title">{{ $store.state.principal.activity.name }}</span>
			<v-spacer></v-spacer>
			<div class="d-flex flex-row align-center">
				<v-list-item two-line dark>
					<v-list-item-content>
						<v-list-item-title class="text-right">{{ $store.state.player.name }} <v-icon small>mdi-account-circle</v-icon></v-list-item-title>
						<v-list-item-subtitle class="text-right">{{ $store.state.principal.time ? $store.state.principal.time : "00:00:00" }} <v-icon small>mdi-clock-time-nine</v-icon></v-list-item-subtitle>
						<v-list-item-subtitle v-if="$store.state.principal.activity.hide_real_time_scores" class="text-right">{{ score }} <v-icon small>mdi-star</v-icon></v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-avatar>
					<v-img v-if="$store.state.player.avatar" :src="$store.state.player.avatar"></v-img>
					<v-icon v-else color="white" size="50">mdi-account-circle</v-icon>
				</v-avatar>
			</div>
		</v-app-bar>
		<v-navigation-drawer v-if="$store.state.isStarted == true" app v-model="drawer" clipped absolute height="100%" :permanent="!$vuetify.breakpoint.smAndDown" :temporary="$vuetify.breakpoint.smAndDown">
			<v-list>
				<v-list-item-group
					color="primary"
				>
				<v-list-item :to="'/games/map/' + this.$store.state.principal.activityId">
					<v-list-item-icon>
						<v-icon>mdi-home</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t("home") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="!$store.state.isFinished && $store.state.principal.activity && $store.state.principal.activity.on_demand_tasks.length > $store.state.doneOdt.length" to="/games/odt">
					<v-list-item-icon>
						<v-icon>mdi-clipboard-check</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('on_demand_task') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link to="/games/chat">
					<v-list-item-icon>
						<v-icon>mdi-chat-processing</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t("chat") }}</v-list-item-title>
					</v-list-item-content>
					<v-list-item-icon v-if="$root.totalUnreadMessages > 0">
						<v-icon color="primary">{{ "mdi-numeric-" + ($root.totalUnreadMessages > 9 ? "9-plus" : $root.totalUnreadMessages) + "-circle" }}</v-icon>
					</v-list-item-icon>
				</v-list-item>
				<v-list-item v-if="$store.state.principal.activity.show_token" @click="getToken(), (showToken = true)">
					<v-list-item-icon>
						<v-icon>mdi-check-decagram</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('tokens') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item v-if="$store.state.principal.activity.hide_real_time_ranking" to="/games/ranking">
					<v-list-item-icon>
						<v-icon>mdi-crown</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('ranking') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @keydown.enter.prevent @click="$router.go()">
					<v-list-item-icon>
						<v-icon>mdi-refresh-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('refresh_page') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<!-- <v-list-item @click="logout">
					<v-list-item-icon>
						<v-icon>mdi-logout-variant</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item-content>
				</v-list-item> -->
				</v-list-item-group>
			</v-list>

			<template v-slot:append>
				<v-list nav>
					<v-list-item v-if="!$store.state.isFinished" @keydown.enter.prevent @click="showConfirmFinishActivity = true" class="v-item--active v-list-item--active">
						<v-list-item-icon>
							<v-icon color="#0000008a">mdi-flag-checkered</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ $t('finish_activity') }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list-item @click="showSetting = !showSetting">
					<v-list-item-icon>
						<v-icon>mdi-cog</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t("settings") }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-navigation-drawer>

		<v-main>
			<router-view></router-view>
		</v-main>

		<v-dialog v-model="showToken" v-if="showToken" max-width="500">
			<v-card>
				<v-card-title>{{ $t('tokens') }}</v-card-title>
				<v-card-text>
					<h3 v-if="dataToken && dataToken.length < 1">Token is empty</h3>
					<template v-else>
						<v-simple-table dense>
							<template v-slot:default>
								<thead class="orange">
									<tr>
										<th class="text-left">{{ $t('name') }}</th>
										<th class="text-left">Total</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in dataToken" :key="item.id">
										<td>
											<div class="overline mb-n3">{{ item.name }}</div>
										</td>
										<td>{{ item.count }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="showToken = false" text color="primary" min-width="100">{{ $t("done") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Settings -->
		<v-dialog v-model="showSetting" max-width="400" persistent>
			<v-card>
				<v-card-title class="gtheader justify-center white--text">{{ $t("settings") }}</v-card-title>
				<v-card-text class="my-4">
					<v-row>
						<v-col cols="12">
							<v-row no-gutters>
								<v-col cols="12" sm="6" class="d-flex align-center body">{{ $t("language") }}</v-col>
								<v-col cols="12" sm="6" class="d-flex justify-end">
									<v-select v-model="locale" :items="languages" item-value="language" @change="changeLocale(locale)" class="elevation-0" hide-details single-line solo dense>
										<template v-slot:item="{ item }"> <flag class="rounded-circle elevation-4 mr-2" :iso="item.flag" style="font-size:32px" /> {{ item.title }} </template>
										<template v-slot:selection="{ item }"> <flag class="rounded-circle elevation-4 mr-2" :iso="item.flag" style="font-size:32px" /> {{ $root.ellipsify(item.title, 10) }} </template>
									</v-select>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12">
							<v-row no-gutters>
								<v-col class="d-flex align-center body">{{ $t("show_other_players") }}</v-col>
								<v-col class="d-flex justify-end">
									<v-switch v-model="showOtherPlayers" @change="$store.commit('setShowOtherPlayers', showOtherPlayers)"></v-switch>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn class="mx-auto" text color="primary" tile @click="showSetting = !showSetting" min-width="100">{{ $t("done") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="$store.state.notification.show" max-width="500">
			<v-card style="padding: 20px">
				<div style="display: flex; justify-content: space-between">
					<h2 style="margin-bottom: 10px">{{ $store.state.notification.title }}</h2>
					<v-btn icon color="secondary" @click="closeNotification">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>

				<template v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
					<div style="color: #c42126; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
					<div style="color: #ed5025; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
					<div style="color: #e26026; font-size: 14px" :key="item" v-for="item in $store.state.notification.content.noticeList">{{ item }}</div>
				</template>
				<template v-else>
					<div style="color: #e26026; font-size: 14px">{{ $store.state.notification.content }}</div>
				</template>
				<div style="text-align: center; margin-top: 20px">
					<v-btn color="primary" @click="closeNotification" min-width="100">{{ $t('close') }}</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!-- show message confirm finished activity -->
		<v-dialog v-model="showConfirmFinishActivity" max-width="400" persistent>
			<v-card>
				<v-card-title class="gtheader justify-center white--text">{{ $t('warning') }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-icon color="orange" style="font-size: 100px;">mdi-alert-circle-outline</v-icon>
				</v-card-text>
				<v-card-text class="text-center" v-html="$t('finish_activity_confirm')"></v-card-text>
				<v-card-actions class="d-flex justify-center">
					<v-btn color="red" tile dark @click="endActivity()" min-width="100">{{ $t('end') }}</v-btn>
					<v-btn color="success" tile @click="showConfirmFinishActivity = false" min-width="100">{{ $t('return_to_activity') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- show message finished activity -->
		<v-dialog v-model="$root.showFinish" max-width="400" persistent>
			<v-card>
				<v-card-title class="gtheader justify-center white--text">{{ $t('finish_activity') }}</v-card-title>
				<v-card-text class="d-flex justify-center mt-4">
					<v-img src="feedback_ok.png" max-width="150"></v-img>
				</v-card-text>
				<v-card-actions class="d-flex flex-column justify-center">{{ $t('finish_activity_alert') }}</v-card-actions>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="primary" tile @click="$root.showFinish = false" min-width="100">{{ $t('cont') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- show message notification -->
		<v-dialog v-model="showMessage.show" max-width="400" persistent>
			<v-card>
				<v-card-title :class="showMessage.color ? showMessage.color : 'gtheader'" class="justify-center white--text">{{ showMessage.title }}</v-card-title>
				<v-card-text v-if="showMessage.icon" class="d-flex justify-center mt-4">
					<v-img :src="showMessage.icon" max-width="150"></v-img>
				</v-card-text>
				<v-card-actions class="d-flex flex-column justify-center text-center wrap-text" v-html="showMessage.message"></v-card-actions>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="primary" tile @click="closeDialogMessage()" min-width="100">{{ $t('cont') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar
			v-model="$root.snackbar.show"
			:timeout="10000"
			absolute
			top
			centered
			color="orange"
			width="100vw"
			style="z-index: 999;"
		>
			<v-row class="align-center">
				<v-col cols="auto">
					<v-icon>mdi-message</v-icon>
				</v-col>
				<v-col>
					<span class="font-weight-bold">{{ $t('enter_message') }}</span>
				<p>{{ $root.snackbar.sender + ": " + $root.snackbar.message }}</p>
				</v-col>
			</v-row>
			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="$root.snackbar.show = false">Close</v-btn>
			</template>
		</v-snackbar>

		<img v-show="$root.loader.value && $root.loader.isShow" src="loader.svg" class="loader" />
	</v-app>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import i18n from "@/plugins/i18n";
export default {
	data() {
		return {
			drawer: true,
			mini: true,
			showToken: false,
			showMessage: { show: false, title: "", icon: "", message: "" },
			dataToken: [],
			tokenLifetime: [],
			intervalChecker: 0,
			intervalTimer: 0,
			timer: this.$store.state.timer,
			score: this.$store.state.principal.score ? this.$store.state.principal.score : 0,
			showSetting: false,
			locale: this.$store.state.locale,
			showOtherPlayers: this.$store.state.showOtherPlayers,
			showConfirmFinishActivity: false,
			languages: [
				{ flag: "ae", language: "ae", title: "Arabic" },
				{ flag: "bg", language: "bg", title: "Bulgarian" },
				{ flag: "cn", language: "cn", title: "Chinese (Simplified)" },
				{ flag: "hk", language: "cnt", title: "Chinese (Traditional)" },
				{ flag: "cz", language: "cz", title: "Czech" },
				{ flag: "dk", language: "dk", title: "Danish" },
				{ flag: "be", language: "be", title: "Dutch" },
				{ flag: "gb-eng", language: "en", title: "English" },
				{ flag: "ee", language: "ee", title: "Estonian" },
				{ flag: "fi", language: "fi", title: "Finnish" },
				{ flag: "fr", language: "fr", title: "French" },
				{ flag: "de", language: "de", title: "German" },
				{ flag: "gr", language: "gr", title: "Greek" },
				{ flag: "il", language: "il", title: "Hebrew" },
				{ flag: "id", language: "id", title: "Indonesian" },
				{ flag: "it", language: "it", title: "Italian" },
				{ flag: "jp", language: "jp", title: "Japanese" },
				{ flag: "lv", language: "lv", title: "Latvian" },
				{ flag: "lt", language: "lt", title: "Lithuanian" },
				{ flag: "pl", language: "pl", title: "Polish" },
				{ flag: "br", language: "br", title: "Portuguese" },
				{ flag: "pt", language: "pt", title: "Portuguese" },
				{ flag: "ro", language: "ro", title: "Romanian" },
				{ flag: "ru", language: "ru", title: "Russian" },
				{ flag: "sk", language: "sk", title: "Slovak" },
				{ flag: "si", language: "si", title: "Slovenian" },
				{ flag: "es", language: "es", title: "Spanish" },
				{ flag: "es", language: "es-sa", title: "Spanish (South America)" },
				{ flag: "se", language: "se", title: "Swedish" },
				{ flag: "tr", language: "tr", title: "Turkish" },
				{ flag: "vn", language: "vn", title: "Vietnamese" },
			],
			dbRoot: firebase.database().ref().child("messaging").child(this.$store.state.principal.activity.licensee_id).child(this.$store.state.principal.activityId)
		};
	},
	watch: {
		"$store.state.isStarted": function () {
			if (this.$store.state.isStarted) {
				this.scheduler();

				// Initiate chat feature
				if (!this.$store.state.isInitChat) {
					this.enablePublicChat();
					this.enableControllerChat();
					this.enableTeamChat();
					this.$store.commit("setInitChat", true);
				}
			}
		},
		"$store.state.principal.score": function () {
			this.score = this.$store.state.principal.score;
		},
		"$store.state.isFinished": function () {
			if (this.$store.state.isFinished) {
				clearInterval(this.intervalTimer);
				this.intervalTimer = 0;
				clearInterval(this.intervalChecker);
				this.intervalChecker = 0;
				this.$root.showFinish = true;
			}
		},
		"$root.pause": function () {
			if (!this.$root.pause) {
				this.scheduler();
			} else {
				clearInterval(this.intervalTimer);
				this.intervalTimer = 0;
			}
		},
	},
	mounted() {
		// initiate language
		i18n.locale = this.locale;

		clearInterval(this.intervalTimer);
		this.intervalTimer = 0;
		clearInterval(this.intervalChecker);
		this.intervalChecker = 0;

		if (this.$store.state.isStarted == true && !this.$store.state.isFinished) {
			this.scheduler();
		}

		// conversations listener (notify unread message)
		let vm = this;
		var con = this.dbRoot.child("conversations").orderByChild('sent_at');
		con.on("value", function (snapshot) {
			let totalUnreadMessages = 0;
			let sender = null;
			let message = null;
			snapshot.forEach(function (childSnapshot) {
				let unreadMessages = childSnapshot.child("unread").child(vm.$store.state.principal.player_id).val();
				sender = childSnapshot.child("name").child(vm.$store.state.principal.player_id).val();
				message = childSnapshot.child("last_message").val();

				if (unreadMessages != null && childSnapshot.child("sender_id").val() != null) {
					let message = {
						unreadMessages: parseInt(unreadMessages),
						key: childSnapshot.key,
						sender_id: childSnapshot.child("sender_id").val()
					};
					if (unreadMessages > 0) {
						totalUnreadMessages += parseInt(unreadMessages);
					}
					vm.$root.messages.push(message);
				}
			});
			if (totalUnreadMessages > vm.$root.totalUnreadMessages) {
				vm.$root.notificationSound();
				vm.$root.totalUnreadMessages = totalUnreadMessages;

				// show snackbar for new mesage
				vm.$root.snackbar.sender = sender == null || sender === vm.$store.state.player.name ? "Controller" : sender;
				vm.$root.snackbar.message = message;
				vm.$root.snackbar.show = true;
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		clearInterval(this.intervalTimer);
		this.intervalTimer = 0;
		clearInterval(this.intervalChecker);
		this.intervalChecker = 0;
		next();
	},
	methods: {
		scheduler() {
			// Timer
			if (this.intervalTimer == 0) {
				this.intervalTimer = setInterval(() => {
					this.setTimer();
					this.checkTokenTimer();
				}, 1000);
			}

			//call API per 10 seconds for check token
			if (this.intervalChecker == 0) {
				this.intervalChecker = setInterval(() => {
					if (this.$store.state.principal.activityId !== undefined) {
						this.addPosition();
						this.playerPosition();
					}
				}, 10000);
			}
		},
		setTimer() {
			this.timer.s += 1;
			if (this.timer.s == 60) {
				this.timer.s = 0;
				this.timer.m += 1;
			}

			if (this.timer.m == 60) {
				this.timer.m = 0;
				this.timer.h += 1;
			}
			let time = this.timer.h.toString().padStart(2, "0") + ":" + this.timer.m.toString().padStart(2, "0") + ":" + this.timer.s.toString().padStart(2, "0");
			this.$store.commit("setTime", { timer: this.timer, time: time });
		},
		getToken() {
			this.dataToken = JSON.stringify(this.$store.state.principal.dataToken) == "[]" ? [] : this.$root.clone(this.$store.state.principal.dataToken);
		},
		checkTokenTimer() {
			if (this.$store.state.principal.dataToken && JSON.stringify(this.$store.state.principal.dataToken.length) != "[]") {
				// Token lifetime
				this.tokenLifetime = this.$store.state.principal.dataToken.filter((token) => this.tokenLifetime.find((x) => x.id == token.id) == null && token.lifetime > 0);
				let now = Date.now();
				for (let token of this.tokenLifetime) {
					let gap = (now - token.started) / 1000;
					if (token.lifetime <= gap) {
						// decrease token
						let decreaseToken = [];
						decreaseToken.push(token);
						this.$store.commit("decreaseToken", decreaseToken);
					}
				}
			}
		},
		playerPosition() {
			axios.get("api/v1/players_position", {
					params: {
						activity_id: this.$store.state.principal.activity.id,
						player_id: this.$store.state.principal.player_id
					}
				})
				.then((res) => {
					if (res.data.code == 200) {
						let playersPosition = res.data.results
						let temp = [];
						for (let player of playersPosition) {
							let idx = temp.findIndex((x) => x.latitude == player.latitude && x.longitude == player.longitude);
							if (idx != -1) {
								temp[idx].name = temp[idx].name + "\n" + player.name;
							} else {
								temp.push(player);
							}
						}
						this.$store.commit("setPlayersPosition", temp);
					}
				});
		},
		addPosition() {
			// hidden loader
			this.$root.loader.isShow = false;

			let data = {};
			data.activity_id = this.$store.state.principal.activity.id;
			data.player_id = this.$store.state.principal.player_id;
			data.latitude = this.$store.state.player.position.latitude;
			data.longitude = this.$store.state.player.position.longitude;
			data.score = this.score;
			data.score_histories = this.$store.state.scoreHistories;
			axios.post("/api/v2/add_position", data).then((response) => {
				if (response.data.code == 200) {
					let res = response.data.result;
					// remove by controller
					if (res.is_removed) {
						this.logout();
					}

					// finish by controller
					if (res.is_activity_finished == 1) {
						this.$store.commit("setFinished", true);
					}

					// fetch score from controller
					this.fetchScore(res.scores);

					// fetch received token from controller
					this.checkForControllerKeys(res.keys_hidden, res.keys_show);

					// update total score
					this.updateTotalScore(res.total_score);

					// fetch score odt from controller
					this.fetchScoreODT(res.scores_odt);

					// handle modify score from controller
					this.checkForModificationScore(res.modify_scores);

					this.$store.commit("resetScoreHistory");
				}

				// Show loader
				this.$root.loader.isShow = true;
			});
		},
		closeNotification() {
			if (this.$store.state.notification.title === "Unauthorized") {
				this.$store.commit("hideNotification");
				this.$router.push("/");
			} else {
				this.$store.commit("hideNotification");
			}
		},
		logout() {
			this.$store.dispatch("logout").then(() => {
				const intervals = window.setInterval(function () {}, Number.MAX_SAFE_INTEGER);
				// Clear any timeout/interval up to that id
				for (let i = 1; i < intervals; i++) {
					window.clearInterval(i);
				}
				this.intervalTimer = 0;
				this.intervalChecker = 0;
				this.$router.push("/");

				// remove event paste listener
				var allowPaste = function(e){
					e.stopImmediatePropagation();
					return true;
				};
				document.addEventListener("paste", allowPaste, true);
			});
		},
		fetchScore(scores) {
			let checkPointIds = [];
			let answerIds = [];
			for (let score of scores) {
				this.$store.commit("setScore", score.score);
				checkPointIds.push(score.checkpoint_id);
				answerIds.push(score.answer_id);
			}
			if (answerIds.length > 0) {
				axios.post("api/v1/score_update", {
					player_id: this.$store.state.principal.player_id,
					answers: answerIds,
					checkpoints: checkPointIds
				});
			}
		},
		updateTotalScore(totalScore) {
			this.$store.commit("setScore", parseInt(totalScore) - this.score);
		},
		fetchScoreODT(scores) {
			if (scores) {
				let task_ids = [];
				for (let score of scores) {
					this.$store.commit("setScore", score.score);
					task_ids.push(score.task_id);
				}
				if (task_ids.length > 0) {
					axios.post("api/v1/score_update_odt", {
						activity_id: this.$store.state.principal.activity.id,
						player_id: this.$store.state.principal.player_id,
						task_ids: task_ids
					});
				}
			}
		},
		checkForModificationScore(modifyScores) {
			if (modifyScores) {
				let toConfirm = [];
				for (let modify of modifyScores) {
					toConfirm.push(modify.id);
				}
				if (toConfirm.length > 0) {
					axios.post("api/v1/modify_score_confirm", { id: toConfirm })
						.then((res) => {
							if (res.data.code == 200) {
								for (let data of modifyScores) {
									this.$store.commit("setScore", data.score);
									this.$store.commit("setScoreHistory", { id: Date.now(), type: "modify", data: data.id, score: data.score, activityDbId: data.id });
								}
							}
						});
				}
			}
		},
		checkForControllerKeys(keysHidden, keysShow) {
			let receivedKeys = [];
			for (let i in keysHidden) {
				this.$store.commit("setToken", keysHidden[i]);
				receivedKeys.push(keysHidden[i].table_id);
			}
			let tokenMessage = "";
			for (let i in keysShow) {
				this.$store.commit("setToken", keysShow[i]);
				receivedKeys.push(keysShow[i].table_id);
				let token = this.$store.state.principal.keys.length > 0 ? this.$store.state.principal.keys.find((x) => x.id == keysShow[i].id) : null;
				tokenMessage = tokenMessage + (token ? token.name : "") + " x " + keysShow[i].count + "" + (i < keysShow.length - 1 ? ", " : " ");
			}
			if (receivedKeys.length > 0) {
				axios.post("/api/v1/key_confirm", { table_ids: receivedKeys }).then((response) => {
					if (response.data.code == 200 && keysShow.length > 0) {
						this.showMessage.title = this.$t('notification');
						this.showMessage.message = "<p class='mb-4'>"+ this.$root.setValue(this.$t('controller_key'), tokenMessage) +"</p>";
						this.showDialogMessage();
					}
				});
			}
		},
		checkReceivedTokens(tokens) {
			let ids = [];
			for (let i in tokens) {
				ids.push(tokens[i].table_id);
			}
			if (ids.length > 0) {
				axios.post("/api/v1/key_confirm", { table_ids: ids }).then((response) => {
					if (response.data.code == 200) {
						let tokenMessage = "";
						for (let i in tokens) {
							this.$store.commit("setToken", tokens[i]);
							let token = this.$store.state.principal.keys.length > 0 ? this.$store.state.principal.keys.find((x) => x.id == tokens[i].id) : null;
							tokenMessage = tokenMessage + (token ? token.name : "") + " x " + tokens[i].count + "" + (i < tokens.length - 1 ? ", " : " ");
						}

						this.showMessage.title = this.$t('notification');
						this.showMessage.message = "<p class='mb-4'>" + this.$root.setValue(this.$t('controller_key'), tokenMessage) + "</p>";
						this.showDialogMessage();
					}
				});
			}
		},
		enablePublicChat() {
			if (this.$store.state.principal.activity && this.$store.state.principal.activity.public_chat) {
				// set name of public conversation
				this.dbRoot.child("conversations").child("public").child("name").child(this.$store.state.principal.player_id).set(this.$store.state.principal.activityName);

				// update unread
				var unread = this.dbRoot.child("conversations").child("public").child("unread").child(this.$store.state.principal.player_id);
				unread.on("value", function (snapshot) {
					if (snapshot.val() == null || snapshot.val() == 0) {
						unread.set(0);
					}
				});
			}
		},
		enableControllerChat() {
			this.dbRoot.child("users").child(this.$store.state.principal.player_id).child("conversations").child(this.$store.state.principal.player_id).set(true);
			this.dbRoot.child("users").child("controller").child("conversations").child(this.$store.state.principal.player_id).set(true);
			// set name of controller conversation
			this.dbRoot.child("conversations").child(this.$store.state.principal.player_id).child("name").child(this.$store.state.principal.player_id).set("Controller");
			this.dbRoot.child("conversations").child(this.$store.state.principal.player_id).child("name").child("controller").set(this.$store.state.player.name);

			// update unread
			var unread = this.dbRoot.child("conversations").child(this.$store.state.principal.player_id).child("unread");
			let vm = this;
			unread.on("value", function (snapshot) {
				if (!snapshot.hasChild(vm.$store.state.principal.player_id)) {
					unread.child(vm.$store.state.principal.player_id).set(0);
				}
				if (!snapshot.hasChild("controller")) {
					unread.child("controller").set(0);
				}
			});
		},
		enableTeamChat() {
			if (this.$store.state.principal.activity && this.$store.state.principal.activity.team == 1) {
				// set name of public conversation
				this.dbRoot.child("conversations").child(this.$store.state.player.team_id).child("name").child(this.$store.state.principal.player_id).set(this.$store.state.player.team_name);

				// update unread
				var unread = this.dbRoot.child("conversations").child(this.$store.state.player.team_id).child("unread").child(this.$store.state.principal.player_id);
				unread.on("value", function (snapshot) {
					if (snapshot.val() == null || snapshot.val() == 0) {
						unread.set(0);
					}
				});
			}
		},
		changeLocale(locale) {
			i18n.locale = locale;
			this.$store.commit("setLocale", locale);
		},
		endActivity() {
			this.$store.commit("setFinished", true);
			this.showConfirmFinishActivity = false;

			let formData = new FormData();
			formData.append("player_id", this.$store.state.principal.player_id);
			formData.append("playing_time", this.$store.state.timer.h * 3600 + this.$store.state.timer.m * 60 + this.$store.state.timer.s);
			formData.append("score", this.$store.state.principal.score);
			axios.post("/api/v1/end_activity", formData);
		},
		showDialogMessage() {
			this.showMessage.show = true;
		},
		closeDialogMessage() {
			this.showMessage = { show: false, title: "", icon: "", message: "" };
		}
	}
};
</script>